import React from "react";
import { Button } from "react-bootstrap";
import { LinkContainer } from "react-router-bootstrap";
import { motion } from "framer-motion";

// import './style.css';

const Masthead = () => (
  <header className="masthead">
    <motion.div
      style={{
        position: "absolute",
        right: "2em",
        bottom: "2em",
        opacity: 0,
        scale: 1,
        y: "-1em",
      }}
      animate={{ y: "0em", opacity: 0.6 }}
      transition={{
        delay: 1.3,
        // duration: .3
      }}
      whileHover={{ opacity: 1, x: "0.2em", transition: { delay: 0 } }}
    >
      <LinkContainer
        to={"#howitworks"}
        style={{
          cursor: "pointer",
          fontStyle: "italic",
          color: "rgba(255, 255, 255, 0.7)",
        }}
      >
        <div>
          <small>
            more info{" "}
            <svg
              width="1em"
              height="1em"
              viewBox="0 0 16 16"
              className="bi bi-chevron-down"
              fill="currentColor"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z"
              />
            </svg>
          </small>
        </div>
      </LinkContainer>
    </motion.div>
    <div className="header-content">
      <div className="header-content-inner">
        <motion.div
          style={{
            opacity: 0,
            // y: "3em",
            scale: 1.5,
          }}
          animate={{
            opacity: 1,
            // y: "0em",
            scale: 1.2,
          }}
          transition={{
            // type: 'spring',
            duration: 0.24,
          }}
        >
          <h1 id="homeHeading">Reveal Yourself!</h1>
        </motion.div>
        <hr />
        <h2>Got your DNA data?</h2>
        <p>
          You are invited to participate in a research study looking for genetic
          variants correlated with personality type.
          <br />
          To participate you will need to register and upload your genotyping
          data to Open Humans. You must be 18 years or older to participate.
        </p>
        <motion.div
          style={{
            display: "inline-block",
            opacity: 0,
            y: "-.5em",
          }}
          animate={{
            y: "0em",
            opacity: 1,
          }}
          transition={{
            delay: 0.5,
          }}
          whileHover={{ scale: 1.1, transition: { delay: 0 } }}
          whileTap={{ scale: 1.1, transition: { delay: 0 } }}
        >
          <LinkContainer to="/consent">
            <Button bsStyle="primary" className="btn-xl mr-3 mt-2">
              I'd Like to Participate
            </Button>
          </LinkContainer>
        </motion.div>
      </div>
    </div>
  </header>
);

export default Masthead;
