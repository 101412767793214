/* eslint-disable no-irregular-whitespace */
const typeSummary = {
  ISTP:
  `<p><small>ISTP is an acronym used to describe one of the sixteen personality types created by <strong>Katharine
  Briggs</strong> and <strong>Isabel Myers.</strong> It stands for Introverted, Sensing, Thinking, Perceiving. ISTP
  indicates a person who is energized by time spent alone (Introverted), who focuses on facts and details rather than
  ideas and concepts (Sensing), who makes decisions based on logic and reason (Thinking) and who prefers to be
  spontaneous and flexible rather than planned and organized (Perceiving). ISTPs are sometimes referred to as
  Craftsperson personalities because they typically have an innate mechanical ability and facility with
  tools.</small></p>`,

  ISFP:
  `<p><small>ISFP is an acronym used to describe one of the sixteen personality types created by <strong>Katharine
  Briggs</strong> and <strong>Isabel Myers.</strong> It stands for Introverted, Sensing, Feeling, Perceiving. ISFP
  indicates a person who is energized by time spent alone (Introverted), who focuses on facts and details rather than
  ideas and concepts (Sensing), who makes decisions based on feelings and values (Feeling) and who prefers to be
  spontaneous and flexible rather than planned and organized (Perceiving). ISFPs are sometimes referred to as Composer
  personalities because of their innate sensibility for creating aesthetically pleasing experiences.</small></p>`,

  ISTJ:
  `<p><small>ISTJ is an acronym used to describe one of the sixteen personality types created by <strong>Katharine
  Briggs</strong> and <strong>Isabel Myers.</strong> It stands for Introverted, Sensing, Thinking, Judging. ISTJ
  indicates a person who is energized by time spent alone (Introverted), who focuses on facts and details rather than
  ideas and concepts (Sensing), who makes decisions based on logic and reason (Thinking) and who prefers to be planned
  and organized rather than spontaneous and flexible (Judging). ISTJs are sometimes referred to as Inspector
  personalities because of their focus on details and interest in doing things correctly.</small></p>`,

  ISFJ:
  `<p><small>ISFJ is an acronym used to describe one of the sixteen personality types created by <strong>Katharine
  Briggs</strong> and <strong>Isabel Myers.</strong> It stands for Introverted, Sensing, Feeling, Judging. ISFJ
  indicates a person who is energized by time spent alone (Introverted), who focuses on facts and details rather than
  ideas and concepts (Sensing), who makes decisions based on feelings and values (Feeling) and who prefers to be
  planned and organized rather than spontaneous and flexible (Judging). ISFJs are sometimes referred to as Protector
  personalities because of their interest in keeping people safe and well cared for.</small></p>`,

  ESTP:
  `<p><small>ESTP is an acronym used to describe one of the sixteen personality types created by <strong>Katharine
  Briggs</strong> and <strong>Isabel Myers.</strong> It stands for Extraverted, Sensing, Thinking, Perceiving. ESTP
  indicates a person who is energized by time spent with others (Extraverted), who focuses on facts and details rather
  than ideas and concepts (Sensing), who makes decisions based on logic and reason (Thinking) and who prefers to be
  spontaneous and flexible rather than planned and organized (Perceiving). ESTPs are sometimes referred to as Dynamo
  personalities because of their high-energy, active approach to life.</small></p>`,

  ESFP:
  `<p><small>ESFP is an acronym used to describe one of the sixteen personality types created by <strong>Katharine
  Briggs</strong> and <strong>Isabel Myers.</strong> It stands for Extraverted, Sensing, Feeling, Perceiving. ESFP
  indicates a person who is energized by time spent with others (Extraverted), who focuses on facts and details rather
  than ideas and concepts (Sensing), who makes decisions based on feelings and values (Feeling) and who prefers to be
  spontaneous and flexible rather than planned and organized (Perceiving). ESFPs are sometimes referred to as Performer
  personalities because of their playful, energetic nature.</small></p>`,

  ESTJ:
  `<p><small>ESTJ is an acronym used to describe one of the sixteen personality types created by <strong>Katharine
  Briggs</strong> and <strong>Isabel Myers.</strong> It stands for Extraverted, Sensing, Thinking, Judging. ESTJ
  indicates a person who is energized by time spent with others (Extraverted), who focuses on facts and details rather
  than ideas and concepts (Sensing), who makes decisions based on logic and reason (Thinking) and who prefers to be
  planned and organized rather than spontaneous and flexible (Judging). ESTJs are sometimes referred to as Supervisor
  personalities because they tend to take charge and make sure things are done correctly.</small></p>`,

  ESFJ:
  `<p><small>ESFJ is an acronym used to describe one of the sixteen personality types created by <strong>Katharine
  Briggs</strong> and <strong>Isabel Myers.</strong> It stands for Extraverted, Sensing, Feeling, Judging. ESFJ
  indicates a person who is energized by time spent with others (Extraverted), who focuses on facts and details rather
  than ideas and concepts (Sensing), who makes decisions based on feelings and values (Feeling) and who prefers to be
  planned and organized rather than spontaneous and flexible (Judging). ESFJs are sometimes referred to as Provider
  personalities because of their interest in taking care of others in practical ways.</small></p>`,

  INTP:
  `<p><small>INTP is an acronym used to describe one of the sixteen personality types created by <strong>Katharine
  Briggs</strong> and <strong>Isabel Myers.</strong> It stands for Introverted, iNtuitive, Thinking, Perceiving. INTP
  indicates a person who is energized by time alone (Introverted), who focuses on ideas and concepts rather than facts
  and details (iNtuitive), who makes decisions based on logic and reason (Thinking) and who prefers to be spontaneous
  and flexible rather than planned and organized (Perceiving). INTPs are sometimes referred to as Architect 
  personalities because of their intuitive understanding of complex systems.</small></p>`,

  INFP:
  `<p><small>INFP is an acronym used to describe one of the sixteen personality types created by <strong>Katharine
  Briggs</strong> and <strong>Isabel Myers.</strong> It stands for Introverted, iNtuitive, Feeling, Perceiving. INFP
  indicates a person who is energized by time alone (Introverted), who focuses on ideas and concepts rather than facts
  and details (iNtuitive), who makes decisions based on feelings and values (Feeling) and who prefers to be spontaneous
  and flexible rather than planned and organized (Perceiving). INFPs are sometimes referred to as Healer personalities
  due to their sympathetic idealism and gentle compassion for other people.</small></p>`,

  INTJ:
  `<p><small>INTJ is an acronym used to describe one of the sixteen personality types created by <strong>Katharine
  Briggs</strong> and <strong>Isabel Myers.</strong> It stands for Introverted, iNtuitive, Thinking, Judging. INTJ
  indicates a person who is energized by time alone (Introverted), who focuses on ideas and concepts rather than facts
  and details (iNtuitive), who makes decisions based on logic and reason (Thinking) and who prefers to be planned and
  organized rather than spontaneous and flexible (Judging). INTJs are sometimes referred to as Mastermind personalities
  because of their strategic, logical way of thinking.</small></p>`,

  INFJ:
  `<p><small>INFJ is an acronym used to describe one of the sixteen personality types created by <strong>Katharine
  Briggs</strong> and <strong>Isabel Myers.</strong> It stands for Introverted, iNtuitive, Feeling, Judging. INFJ 
  indicates a person who is energized by time alone (Introverted), who focuses on ideas and concepts rather than facts
  and details (iNtuitive), who makes decisions based on feelings and values (Feeling) and who prefers to be planned
  and organized rather than spontaneous and flexible (Judging). INFJs are sometimes referred to as Counselor
  personalities.</small></p>`,

  ENTP:
  `<p><small>ENTP is an acronym used to describe one of the sixteen personality types created by <strong>Katharine
  Briggs</strong> and <strong>Isabel Myers.</strong> It stands for Extraverted, iNtuitive, Thinking, Perceiving. ENTP
  indicates a person who is energized by time spent with others (Extraverted), who focuses on ideas and concepts rather
  than facts and details (iNtuitive), who makes decisions based on logic and reason (Thinking) and who prefers to be
  spontaneous and flexible rather than planned and organized (Perceiving). ENTPs are sometimes referred to as Visionary
  personalities because of their passion for new, innovative ideas.</small></p>`,

  ENFP:
  `<p><small>ENFP is an acronym used to describe one of the sixteen personality types created by <strong>Katharine
  Briggs</strong> and <strong>Isabel Myers.</strong> It stands for Extraverted, iNtuitive, Feeling, Perceiving. ENFP
  indicates a person who is energized by time spent with others (Extraverted), who focuses on ideas and concepts rather
  than facts and details (iNtuitive), who makes decisions based on feelings and values (Feeling) and who prefers to be
  spontaneous and flexible rather than planned and organized (Perceiving). ENFPs are sometimes referred to as Champion
  personalities because of their enthusiasm for helping others realize their dreams.</small></p>`,

  ENTJ:
  `<p><small>ENTJ is an acronym used to describe one of the sixteen personality types created by <strong>Katharine
  Briggs</strong> and <strong>Isabel Myers.</strong> It stands for Extraverted, iNtuitive, Thinking, Judging. ENTJ
  indicates a person who is energized by time spent with others (Extraverted), who focuses on ideas and concepts rather
  than facts and details (iNtuitive), who makes decisions based on logic and reason (Thinking) and who prefers to be
  planned and organized rather than spontaneous and flexible (Judging). ENTJs are sometimes referred to as Commander
  personalities because of their innate drive to lead others.</small></p>`,

  ENFJ:
  `<p><small>ENFJ is an acronym used to describe one of the sixteen personality types created by <strong>Katharine
  Briggs</strong> and <strong>Isabel Myers.</strong> It stands for Extraverted, iNtuitive, Feeling, Judging. ENFJ
  indicates a person who is energized by time spent with others (Extraverted), who focuses on ideas and concepts rather
  than facts and details (iNtuitive), who makes decisions based on feelings and values (Feeling) and who prefers to be
  planned and organized rather than spontaneous and flexible (Judging). ENFJs are sometimes referred to as Teacher
  personalities because of their interest in helping others develop and grow.</small></p>`,

  Inconclusive:
  `<p><small>A result of inconclusive is only displayed if a self-assessment is completed, none of the survey results
  match up and you are less than 80% sure of your self-assessed choice. If this result bothers you and you’d like to
  find out more, here are some additional references to aid you in your exploration of personality type:</small></p>
  <ul><li><small>Carl Jung: <em>Psychological Types</em></small></li><li><small>Isabel Briggs Myers and Peter Myers:
  <em>Gifts Differing: Understanding Personality Type</em></small></li> <li><small>Naomi Quenk: <em>Was That Really Me?:
  How Everyday Stress Brings Out Our Hidden Personality</em></small></li> <li><small>David Keirsey and Marilyn Bates:
  <em>Please Understand Me/Please Understand Me II</em></small></li> <li><small>Renee Baron: <em>What Type Am I?
  Discover Who You Really Are</em></small></li> <li><small>Lenore Thomson: <em>Personality Type</em></small></li></ul>`,
};

export default typeSummary;
