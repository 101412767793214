const userLoadReducer = (loadState = { status: '', loadMessage: '' }, action) => {
  switch (action.type) {
    case 'REQUEST_USER_STATE':
    case 'REQUEST_NARDI_SCORES':
      return {
        status: 'loading',
        loadMessage: 'Loading user data...',
      };
    case 'RECEIVE_USER_STATE':
    case 'RECEIVE_NARDI_SCORES':
      return {
        status: 'loaded',
        loadMessage: 'User data loaded successfully',
      };
    case 'USER_STATE_FAILED':
    case 'NARDI_SCORES_FAILED':
      return {
        status: 'failed',
        loadMessage: action.message,
      };
    case 'REQUEST_SET_SURVEY':
    case 'REQUEST_MBTI_SAVE':
      return {
        status: 'saving',
        loadMessage: 'Saving survey',
      };
    case 'CONFIRM_SET_SURVEY':
    case 'CONFIRM_MBTI_SAVE':
      return {
        status: 'saved',
        loadMessage: 'Survey saved successfully',
      };
    case 'SURVEY_SET_FAILED':
    case 'MBTI_SAVE_FAILED':
      return {
        status: 'save-failed',
        loadMessage: action.message,
      };
    default:
      return loadState;
  }
};

export default userLoadReducer;

export const getLoadStatus = loadState => loadState.status;

export const getLoadMessage = loadState => loadState.loadMessage;
