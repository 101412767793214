import axios from 'axios';

import { showNotificationWithTimeout } from './notifications';

// User actions

export const generateUserId = () => ({
  type: 'GEN_USER_ID',
});

// User state loading

export const requestUserState = pgId => ({
  type: 'REQUEST_USER_STATE',
  pgId,
});

export const receiveUserState =
  (pgId, consent, dataReady, snpFileUploaded, selfAssessment, nardi, mbti, typeFinder, finalScore) => ({
    type: 'RECEIVE_USER_STATE',
    pgId,
    consent,
    snpFileUploaded,
    dataReady,
    selfAssessment,
    nardi,
    typeFinder,
    mbti,
    finalScore,
  });

export const userStateFailed = (pgId, message) => ({
  type: 'USER_STATE_FAILED',
  pgId,
  message,
});

export function fetchUserSummaryState(pgId) {
  return (dispatch) => {
    dispatch(requestUserState(pgId));

    return axios.post('/api/user', { pgId })
      .then((json) => {
        const {
          access: { ohAccessToken, dataReady, snpFileUploaded }, selfAssessment, nardi, mbti, typeFinder, finalScore,
        } = json.data;
        /* eslint-disable function-paren-newline */
        dispatch(receiveUserState(
          pgId, ohAccessToken !== undefined, dataReady, snpFileUploaded, selfAssessment, nardi, mbti, typeFinder, finalScore,
        ));
        /* eslint-enable */
      })
      .catch((err) => {
        dispatch(userStateFailed(pgId, err.message));
      });
  };
}

export function fetchGencoveConsent() {
  return (dispatch) => {
    dispatch(showNotificationWithTimeout('Redirecting to Gencove...'));
    axios
      .post('/api/consent')
      .then((resp) => {
        if (resp.data.status === 200) {
          /* eslint-disable camelcase */
          const { app_url, external_id } = resp.data;
          const { href } = window.location;
          const bits = href.split('/', 3);
          const gencoveConsentPath = `${app_url}?continue_url=${bits[0]}//${bits[2]}/survey/${external_id}`;
          window.location.href = gencoveConsentPath;
        }
      })
      .catch((err) => {
        console.log('err', err); // eslint-disable-line no-console
      });
  };
}
