import React from 'react';
import { Link } from 'react-router-dom';
import { Grid, Row, Col, Image } from 'react-bootstrap';

import Page from '../components/Page';

import logo from '../images/logos/pg-logo-combined.svg';

const PrivacyPage = () => (
  <Page
    title="Privacy policy"
    description={'Description of what personal information we collect and the security measures we take to '
    + 'safeguard your private information.'}
  >
    <Grid>
      <Row componentClass="section">
        <Col sm={12}>
          <Image responsive src={logo} style={{ marginLeft: 'auto', marginRight: 'auto' }} />
          <h1 className="text-center mb-4">Privacy Policy</h1>

          <p className="lead">
            Personality Genie operates the <a href="http://www.personalitygenie.com">www.personalitygenie.com</a> web
            application, which is an app designed to collect personality and genetic data for a specific research
            project as outlined in our <Link to="/consent">Consent form</Link>.
          </p>

          <p>
            This page is used to inform website visitors regarding our policies with the collection, use, and
            disclosure of Personal Information if you decide to participate in this research project.
          </p>
          <p>
            If you choose to participate, then you agree to the collection and use of information in relation with
            this policy. The Personal Information that we collect are used for research purposes only. We will not use
            or share your information with anyone except as described in this Privacy Policy.
          </p>
          <p>
            The terms used in this Privacy Policy have the same meanings as in our Terms and Conditions, which is
            accessible at www.personalitygenie.com, unless otherwise defined in this Privacy Policy.
          </p>
          <h2>
            Information Collection and Use
          </h2>
          <p>
            We will not collect or have access to any personally identifiable information, either provided by you, or
            through our use of Gencove’s services, as detailed in our Consent form.
          </p>
          <h2>Log Data</h2>
          <p>
            We want to inform you that whenever you visit our app, we collect information that your browser sends to
            us that is called Log Data. This Log Data may include information such as your computer’s Internet
            Protocol (“IP”) address, browser version, pages of our app that you visit, the time and date of your
            visit, the time spent on those pages, and other statistics.
          </p>
          <h2>Personality survey data</h2>
          <p>
            To ensure a better user experience, we have opted to use your browser’s local storage to save your
            responses to surveys that are pending. This means that if you accidentally navigate away from the page
            or choose to complete the survey at a later time, you will be able to return to the survey with your
            responses saved. All survey data will be erased locally once each survey is successfully submitted and our
            database updated. This means that if you have concerns about the privacy of your responses and your
            personality type results, you should only complete the surveys on a private computer in a private location
            and/or complete each survey in a single sitting and/or make sure to clear the browser’s cache if you
            decide to stop part-way through a survey.
          </p>
          <p>
            To facilitate re-entry into the Personality Genie participant dashboard, you will be provided with a
            unique ID. Since this ID gives you access to your pending surveys with saved data, to protect your
            privacy you should make sure to keep this ID private.
          </p>

          <h2>Cookies</h2>
          <p>
            Cookies are files with small amount of data that is commonly used an anonymous unique identifier. These
            are sent to your browser from the website that you visit and are stored on your computer’s hard drive. Our
            website uses these “cookies” to collection information and to improve our app. You have the option to
            either accept or refuse these cookies, and know when a cookie is being sent to your computer. If you
            choose to refuse our cookies, you may not be able to use some portions of our app.
          </p>

          <h2>Service Providers</h2>
          <p>
            We may employ third-party companies and individuals due to the following reasons:
          </p>
          <ul>
            <li>To facilitate our App;</li>
            <li>To provide the App on our behalf;</li>
            <li>To perform App-related services; or</li>
            <li>To assist us in analyzing how our App is used.</li>
          </ul>
          <p>
            We want to inform our participants that these third parties have access to your Personal Information. The
            reason is to perform the tasks assigned to them on our behalf. However, they are obligated not to disclose
            or use the information for any other purpose.
          </p>
          <h2>Security</h2>
          <p>
            We value your trust in providing us your Personal Information, thus we are striving to use commercially
            acceptable means of protecting it. But remember that no method of transmission over the internet, or
            method of electronic storage is 100% secure and reliable, and we cannot guarantee its absolute security.
          </p>

          <h2>Links to Other Sites</h2>
          <p>
            Our Service may contain links to other sites. If you click on a third-party link, you will be directed to
            that site. Note that these external sites are not operated by us. Therefore, we strongly advise you to
            review the Privacy Policy of these websites. We have no control over, and assume no responsibility for the
            content, privacy policies, or practices of any third-party sites or services.
          </p>

          <h2>Children’s Privacy</h2>
          <p>
            Our Services do not address anyone under the age of 18. We do not knowingly collect personal identifiable
            information from children under 18. In the case we discover that a child under 18 has provided us with
            personal information, we immediately delete this from our servers. If you are a parent or guardian and you
            are aware that your child has provided us with personal information, please contact us so that we will be
            able take the necessary actions.
          </p>

          <h2>Changes to This Privacy Policy</h2>
          <p>
            We may update our Privacy Policy from time to time. Thus, we advise you to review this page periodically
            for any changes. We will notify you of any changes by posting the new Privacy Policy on this page. These
            changes are effective immediately, after they are posted on this page.
          </p>

          <h2>Contact Us</h2>
          <p>
            If you have any questions or suggestions about our Privacy Policy, do not hesitate to contact us.
          </p>
          <p style={{ fontStyle: 'italic' }}>This document was last updated on February 7, 2018<br />
            This Privacy Policy page was created
            at <a href="http://privacypolicytemplate.net">privacypolicytemplate.net</a>.
          </p>
        </Col>
      </Row>
    </Grid>
  </Page>
);

export default PrivacyPage;
