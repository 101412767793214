import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Grid, Row, Col } from 'react-bootstrap';

import * as util from './report-helpers';
import './report.css';

const typeRowLabels = ['1st Ranked Type', '2nd Ranked Type', '3rd Ranked Type'];

const typeRow = (type, index) => (
  <tr key={index}>
    <td><strong>{typeRowLabels[index]}</strong></td>
    <td>{util.typeName(type.code)}</td>
    <td>{type.code}</td>
    <td className="text-center">{type.score}/25 </td>
  </tr>
);

const temperamentRow = (temperament, index) => (
  <tr key={index}>
    <td>{index + 1}</td>
    <td>{util.temperamentName(temperament.code)}</td>
    <td>{temperament.code}</td>
    <td>{temperament.score}</td>
  </tr>
);

const interactionRow = (interaction, index) => (
  <tr key={index}>
    <td>{index + 1}</td>
    <td>{util.interactionName(interaction.code)}</td>
    <td>{interaction.score}</td>
  </tr>
);

const fpRow = (fp, selectedFP, index) => (
  <tr key={index}>
    <td>
      {fp.code === selectedFP &&
        <i className="fa fa-check" />
      }
    </td>
    <td>{fp.code}&mdash;{util.functionalPairName(fp.code)}<br />
      <span className="text-muted">{util.functionalPairDescriptor(fp.code)}</span>
    </td>
  </tr>
);

const calcDichotPercent = val => Math.round(val / 2);

const dichotTableBody = (dichotPref) => {
  const {
    E, I, S, N, T, F, J, P,
  } = dichotPref;
  Object.keys(dichotPref).forEach((k) => { if (dichotPref[k] === 0) { throw Error(`dichotPref.${k} is 0`); } });
  return (
    <tbody>
      <tr>
        <td>E = {E}/200</td>
        <td>I = {I}/200</td>
        <td>E = {calcDichotPercent(E)}%</td>
        <td>I = {calcDichotPercent(I)}%</td>
      </tr>
      <tr>
        <td>S = {S}/200</td>
        <td>N = {N}/200</td>
        <td>S = {calcDichotPercent(S)}%</td>
        <td>N = {calcDichotPercent(N)}%</td>
      </tr>
      <tr>
        <td>T = {T}/200</td>
        <td>F = {F}/200</td>
        <td>T = {calcDichotPercent(T)}%</td>
        <td>F = {calcDichotPercent(F)}%</td>
      </tr>
      <tr>
        <td>J = {J}/200</td>
        <td>P = {P}/200</td>
        <td>J = {calcDichotPercent(J)}%</td>
        <td>P = {calcDichotPercent(P)}%</td>
      </tr>
    </tbody>
  );
};

/* eslint-disable import/no-mutable-exports */
const NardiReport = ({
  consistency, typeCodes, temperaments, interactionStyles, typeCode, selectedTemperament, functionalPairs, selectedFP,
  dichotPref,
}) => (
  <Grid className="nardi-report">
    <Row componentClass="section">
      <Col sm={12} md={8} mdOffset={2}>

        <h1 className="text-center mb-5">
          Nardi Personality Indicator<br />
          <small>Presented by Radiance House&mdash;Dario Nardi</small>
        </h1>
        <h3 id="top-ranked-types">Top Ranked Personality Types</h3>
        <div className="table-responsive">
          <table className="table table-bordered table-compact">
            <thead>
              <tr>
                <th />
                <th>Type Name</th>
                <th>Type Code</th>
                <th className="text-center">Score</th>
              </tr>
            </thead>
            <tbody>
              {typeCodes.slice(0, 3).map((type, index) => typeRow(type, index))}
            </tbody>
          </table>
        </div>
        <h3 id="dichot-pref">Dichotomous Preferences Scores</h3>
        <div className="table-responsive">
          <table className="table table-bordered table-compact">
            {dichotTableBody(dichotPref)}
          </table>
        </div>
        <h3>Cognitive Dynamics</h3>
        <div className="table-responsive">
          <table className="table table-bordered table-compact">
            <tbody>
              {functionalPairs.slice(0, 2).map((fp, index) => fpRow(fp, selectedFP, index))}
            </tbody>
          </table>
        </div>
        <h3 id="temperaments">Temperament</h3>
        <div className="table-responsive">
          <table className="table table-bordered table-compact">
            <thead>
              <tr>
                <th />
                <th>Temperament</th>
                <th>Code</th>
                <th>Score</th>
              </tr>
            </thead>
            <tbody>
              {temperaments.map((t, index) => temperamentRow(t, index))}
            </tbody>
          </table>
        </div>
        <h3 id="interaction">Interaction/Social Styles</h3>
        <div className="table-responsive">
          <table className="table table-bordered table-compact">
            <thead>
              <tr>
                <th />
                <th>Style</th>
                <th>Score</th>
              </tr>
            </thead>
            <tbody>
              {
                Object.keys(interactionStyles)
                .map(key => ({ code: key, score: interactionStyles[key] }))
                .sort((a, b) => b.score - a.score)
                .map((style, index) => interactionRow(style, index))
              }
            </tbody>
          </table>
        </div>
        <h3 id="fav-snapshot">Favorite Personality Snapshot</h3>
        <div className="table-responsive">
          <table className="table table-bordered table-compact">
            <tbody>
              <tr>
                <th>Type Code</th>
                <td>{typeCode}</td>
              </tr>
              <tr>
                <th>Type Name</th>
                <td>{util.typeName(typeCode)}</td>
              </tr>
              <tr>
                <th>Temperament Code</th>
                <td>{selectedTemperament}</td>
              </tr>
              <tr>
                <th>Temperament Name</th>
                <td>{util.temperamentName(selectedTemperament)}</td>
              </tr>
              <tr>
                <th>Interaction Style</th>
                <td>
                  {
                    util.interactionName(Object.keys(interactionStyles)
                      .map(key => ({ code: key, score: interactionStyles[key] }))
                      .sort((a, b) => b.score - a.score)[0].code)
                  }
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <br />
        <div className="table-responsive">
          <table className="table table-bordered table-compact">
            <tbody>
              <tr>
                <th>Consistency Score</th>
                <td>{consistency}/10</td>
              </tr>
            </tbody>
          </table>
        </div>
      </Col>
    </Row>
    <Row id="explanation">
      <Col sm={12}>
        <p>Hello!</p>
        <p>
          This report highlights key aspects of your personality based on how you responded to the questionnaire you
          took earlier.
        </p>
        <h3>Preferences</h3>
        <p>
          Are you right or left handed? You &ldquo;prefer&rdquo; one hand over the other, in most situations, while
          still relying a lot on both hands. Similarly, you tend to uphold certain values, communicate in a particular
          way, find certain activities more energizing, and so forth. Notably, when under stress or by habit, people
          tend to respond according to preference. Other times, we feel freer to flex.
        </p>
        <p>
          The good news: the more aware you are of your preferences and options, the more deliberate and adaptable you
          may be. This report offers you ranked results, to reveal ways to grow.
        </p>
        <h3>Personality</h3>
        <p>
          Personality means: &ldquo;a pattern of preferences.&rdquo; That is, people don&#39;t fit into neat boxes. Nor
          is a person an arbitrary collection of traits. Rather, each facet of who you are influences the other facets.
          Altogether, you&#39;ve learned to function in a way that is sustainable.
        </p>
        <p>
          While the report touches on various aspects of personality, it focuses on the 16 Myers-Briggs personality
          types. Each type is presented by a 4-letter code such as ESTJ or INFP. Each of the 16 &ldquo;types&rdquo;
          points to a rich, sustainable pattern.
        </p>
        <h3>Your Personality Type Pattern</h3>
        <p>
          What&#39;s your personality type? This report can&#39;t decide for you! Instead, it provides data points to
          help you locate your best-fit type.
        </p>
        <p>
          Ideally, you will enjoy a face-to-face self-discovery process with a professional facilitator to sort through
          the data points and &ldquo;triangulate&rdquo; a best fit. As you explore, look at top-ranked results and also
          consider lower-ranked results. Your career, upbringing, culture, and such have influenced you and likely
          explain the ranking.
        </p>
        <p>
          For example, a person who ranks ESTJ first and ISTJ second likely has a quite a different background than a
          person who ranks ESTJ first and ENTP second!
        </p>
      </Col>
    </Row>
    <Row id="expl-2">
      <Col sm={12}>
        <h3 id="rich-facets">Rich Facets</h3>
        <p>In addition to the 16 types, this report describes your likely personality preferences in several ways.</p>
        <ul>
          <li><strong>Temperament:</strong> Your core needs, values, and related talents.</li>
          <li>
            <strong>Interaction/Social Style:</strong> Your energy, communication, and responsiveness while interacting
            with others.
          </li>
          <li>
            <strong>Cognitive Dynamics:</strong> Your favorite mental processes for where you focus your attention,
            gather information, organize, and make decisions.
          </li>
        </ul>
        <p>
          The questionnaire you took earlier tapped all of these models in some way. Each facet has different
          applications. For example, temperament is useful to find a good career fit while interaction/social styles is
          a window into better teamwork.
        </p>
        <p>
          You may be unfamiliar with some of these models. That&#39;s okay. Look for the references at the end of this
          report. Your facilitator can also likely answer your questions.
        </p>
        <h3>Consistency</h3>
        <p>
          You will find a consistency score. This score varies from 0 to 10, with 10 as perfectly consistent. A low
          score is atypical. It means this report is not a good pointer to your personality, for whatever reason, and
          you should take extra time to explore. In fact, facilitation and exploration are helpful even for medium and
          high scores. Note: The score isn&#39;t about you! Instead, it suggests how much you can trust the results.
        </p>
        <h3>Validity and Reliability</h3>
        <p>
          Research is ongoing to improve the questionnaire and report. Presently, 85% of people select the snapshot that
          corresponds to their best-fit 4-letter personality type code or settle with one of the three types offered to
          them. Please contact us for more information about this topic.
        </p>
      </Col>
    </Row>
    <Row id="references">
      <Col sm={12}>
        <h3>References</h3>
        <p>
          The following books and workbooks combine deep insights with fun exercises to help you and your clients
          explore personality in depth with. You can learn more and order
          at <a href="http://www.radiancehouse.com">www.radiancehouse.com</a>.
        </p>
        <h4>16 Types</h4>
        <p>
          Linda Berens and Dario Nardi. <em>16 Personality Types: Descriptions For Self-Discovery.</em><br />
          Linda Berens, et. al.{' '}
          <em>
            Quick Guide to the 16 Personality Types in Organizations: Understanding Personality
            Differences in the Workplace.
          </em>
        </p>
        <h4>Cognitive Dynamics and 16 Types</h4>
        <p>Linda Berens and Dario Nardi.{' '}
          <em>Understanding Yourself and Others: An Introduction to the Personality Type Code.</em>
          <br />Dario Nardi. <em>8 Keys to Self-Leadership: From Awareness to Action.</em>
          <br />Dario Nardi. <em>Neuroscience of Personality: Brain Savvy Insights for All Types of People.</em>
        </p>
        <h4>Temperament</h4>
        <p>Linda Berens. <em>Understanding Yourself and Others: An Introduction to the 4 Temperaments - 4.0.</em></p>
        <h3>Interaction/Social Styles</h3>
        <p>Linda Berens. <em>Understanding Yourself and Others: An Introduction to Interaction Styles - 2.0.</em></p>
        <p>
          <small>
            Nardi Personality Indicator copyright &copy; Dr. Dario Nardi and Radiance House. For more information,
            contact service@radiancehouse.com.
          </small>
        </p>
      </Col>
    </Row>
  </Grid>
);
/* eslint-enable */

NardiReport.propTypes = {
  typeCode: PropTypes.string.isRequired,
  typeCodes: PropTypes.arrayOf(PropTypes.shape({
    code: PropTypes.string,
    score: PropTypes.number,
  })).isRequired,
  dichotPref: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
  functionalPairs: PropTypes.arrayOf(PropTypes.shape({
    code: PropTypes.string,
    score: PropTypes.number,
  })).isRequired,
  temperaments: PropTypes.arrayOf(PropTypes.shape({
    code: PropTypes.string,
    score: PropTypes.number,
  })).isRequired,
  interactionStyles: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
  selectedTemperament: PropTypes.string.isRequired,
  selectedFP: PropTypes.string.isRequired,
  consistency: PropTypes.number.isRequired,
};

const mapStateToProps = ({
  user: {
    survey: {
      nardi: {
        typeCode, typeCodes, dichotPref, functionalPairs, temperaments, interactionStyles, selectedTypeCode,
        selectedTemperament, consistency, selectedFP,
      },
    },
  },
}) => ({
  typeCode,
  typeCodes,
  dichotPref,
  functionalPairs,
  temperaments,
  interactionStyles,
  selectedTypeCode,
  selectedTemperament,
  selectedFP,
  consistency,
});

export default connect(mapStateToProps)(NardiReport);
