import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import SurveyRunner from '../../components/SurveyRunner';
import { calcFinalScore } from '../../actions';
import { getPgId } from '../../reducers';
import Page from '../../components/Page';

const SelfAssessmentRunner = ({ pgId }) => (
  <Page
    title="What type are you?"
    description={'A self-assessment questionnaire that allows you to choose your best-fit type and certainty '
    + 'surrounding that choice.'}
  >
    <SurveyRunner
      title="Self Assessment Survey"
      surveyId="selfAssessment"
      beforeSave={survey => ({ ...survey, typeCode: survey.answers.find(a => a.id === 'personalityType').value })}
      onComplete={dispatch => dispatch(calcFinalScore(pgId))}
      questions={[
        {
          id: 'personalityType',
          text: 'What is your 4 letter Personality Type based on self-assessment:',
          questionType: 'text',
          values: [
            'ENTJ', 'INTJ', 'ISFP', 'ESFP',
            'ENFJ', 'INFJ', 'ISTP', 'ESTP',
            'INFP', 'ENFP', 'ESTJ', 'ISTJ',
            'INTP', 'ENTP', 'ESFJ', 'ISFJ',
          ],
          transform: value => value.toUpperCase(),
        },
        {
          id: 'typeSource',
          text: 'How did you determine your type (check all that apply)',
          questionType: 'multi',
          options: [
          { key: 'MBTI', prompt: 'Took the official Myers Briggs® Type Indicator (MBTI®)' },
          { key: 'Alternate', prompt: 'Took other Personality Type Tests' },
          { key: 'Read', prompt: 'Read descriptions of the 16 Types' },
          { key: 'Other', prompt: 'Other method', extValue: true },
          ],
        },
        {
          id: 'confidence',
          text: 'What is your confidence level that you have the right type:',
          questionType: 'single',
          options: [
          { key: '< 80%', prompt: '< 80%' },
          { key: '80%', prompt: '80%' },
          { key: '85%', prompt: '85%' },
          { key: '90%', prompt: '90%' },
          { key: '95%', prompt: '95%' },
          { key: '100%', prompt: '100%' },
          ],
        },
        {
          id: 'experience',
          text: 'What is your level of knowledge and experience regarding "Personality Type":',
          questionType: 'single',
          options: [
          { key: 'Beginner', prompt: 'Beginner' },
          { key: '> 5yrs', prompt: '> 5yrs' },
          { key: 'MBTI Cert', prompt: 'MBTI Cert' },
          { key: 'MBTI > 5yrs', prompt: 'MBTI Cert > 5yrs' },
          { key: 'MBTI > 10yrs', prompt: 'MBTI Cert > 10yrs' },
          { key: 'Published', prompt: 'Published Type Author' },
          ],
        },
      ]}
    />
  </Page>
);

SelfAssessmentRunner.propTypes = {
  pgId: PropTypes.string.isRequired,
};

const mapStateToProps = state => ({
  pgId: getPgId(state),
});

export default connect(mapStateToProps)(SelfAssessmentRunner);
