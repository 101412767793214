import React from 'react';
import PropTypes from 'prop-types';
import { Grid, Row, Col, Button, ButtonToolbar } from 'react-bootstrap';
import { connect } from 'react-redux';
import { LinkContainer } from 'react-router-bootstrap';

import { getPgId } from '../reducers';

const TestHost = ({ pgId }) => (
  <Grid style={{ marginTop: '70px' }}>
    <Row>
      <Col sm={12}>
        <ButtonToolbar>
          <Button
            onClick={() => {
              window.location.href = `${window.location.protocol}//${window.location.host}/api/user/report/${pgId}`;
            }}
          >
            Push Me First
          </Button>
          <LinkContainer to="/survey">
            <Button bsStyle="primary">Then go to surveys</Button>
          </LinkContainer>
        </ButtonToolbar>
      </Col>
    </Row>
  </Grid>
);

TestHost.propTypes = {
  pgId: PropTypes.string.isRequired,
};

const mapStateToProps = state => ({
  pgId: getPgId(state),
});

export default connect(mapStateToProps)(TestHost);
