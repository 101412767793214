import React from 'react';
import PropTypes from 'prop-types';
import { FormGroup, FormControl, ControlLabel, HelpBlock } from 'react-bootstrap';

const FieldGroup = ({
  id, label, help, validationState, ...props
}) => (
  <FormGroup controlId={id} validationState={validationState} >
    <ControlLabel>{label}</ControlLabel>
    <FormControl {...props} />
    <FormControl.Feedback />
    {help && <HelpBlock>{help}</HelpBlock>}
  </FormGroup>
);

FieldGroup.propTypes = {
  id: PropTypes.string.isRequired,
  label: PropTypes.string,
  help: PropTypes.string,
  validationState: PropTypes.string,
};

FieldGroup.defaultProps = {
  help: undefined,
  validationState: undefined,
  label: '',
};

export default FieldGroup;
