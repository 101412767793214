import React from "react";
import PropTypes from "prop-types";
import Helmet from "react-helmet";
import { motion } from "framer-motion";

const pageVariants = {
  initial: {
    opacity: 0,
  },
  in: {
    opacity: 1,
  },
  out: {
    opacity: 0,
  },
};

class AnimatedComponent extends React.Component {
  render() {
    return (
      <motion.div
        initial="initial"
        animate="in"
        exit="out"
        variants={pageVariants}
      >
        {this.props.children}
      </motion.div>
    );
  }
}

const Page = ({ children, title, description }) => (
  <div id="main">
    <Helmet
      title={`${title} | Personality Genie`}
      meta={[{ name: "description", content: description }]}
    />
    <div id="content">
      <AnimatedComponent>{children}</AnimatedComponent>
    </div>
  </div>
);

Page.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  children: PropTypes.any.isRequired, // eslint-disable-line react/forbid-prop-types
};

export default Page;
