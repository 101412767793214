import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Grid, Row, Col, Well, FormGroup, Radio, Button } from 'react-bootstrap';

import { getPgId, getSurvey } from '../../reducers';
import { setNardiTypecode, updateSurvey, fetchUserNardiFinalScores } from '../../actions';
import Page from '../../components/Page';

const typecodes = [
  {
    code: 'ESTP',
    title: 'Promoter Executor™',
    description: 'Theme is promoting. Their talents lie in persuading others and expediting to make things happen. '
    + 'They have an engaging, winning style that others are drawn to. Adept at picking up on minimal nonverbal cues, '
    + 'they anticipate the actions and reactions of others and thus win their confidence. They like the excitement and '
    + 'challenge of negotiating, selling, making deals, arbitrating, and in general, achieving the impossible. They '
    + 'thrive on action and the freedom to use all the resources at hand to get the desired outcomes.',
  },
  {
    code: 'ISTP',
    title: 'Analyzer Operator™',
    description: 'Theme is action-driven problem solving. Their talents lie in using frameworks for solving problems. '
    + 'They often excel at operating all kinds of tools and instruments. Keen observers of the environment, they are a '
    + 'storehouse of data and facts relevant to analyzing and solving problems. They thrive on challenging situations '
    + 'and having the freedom to craft clever solutions and do whatever it takes to fix things and make them work. '
    + 'They take pride in their skill and virtuosity, which they seem to effortlessly acquire.',
  },
  {
    code: 'ESFP',
    title: 'Motivator Presenter™',
    description: 'Theme is performance. They are warm, charming, and witty. They want to impact and help others, to '
    + 'evoke their enjoyment, and to stimulate them to act. They want to make a difference and do something '
    + 'meaningful. Often masterful at showmanship, entertaining, motivating, and presenting, they thrive on social '
    + 'interaction, joyful living, and the challenge of the unknown. They like helping people get what they want and '
    + 'need, facilitating them to get results.',
  },
  {
    code: 'ISFP',
    title: 'Composer Producer™',
    description: 'Theme is composing, using whatever is at hand to get a harmonious, aesthetic result. Their talents '
    + 'lie in combining, varying, and improvising, frequently in the arts but also in business and elsewhere. With '
    + 'their senses keenly tuned in, they become totally absorbed in the action of the moment, finding just what fits '
    + 'the situation or the composition. They thrive on having the freedom to vary what they do until they get just '
    + 'the right effect. They take action to help others and demonstrate values and are kind and sensitive to the '
    + 'suffering of others.',
  },
  {
    code: 'ESTJ',
    title: 'Implementor Supervisor™',
    description: 'Theme is supervising, with an eye to the traditions and regulations of the group. They are '
    + 'responsible, hardworking, efficient and interested in ensuring that standards are met, resources conserved, and '
    + 'consequences delivered. Their talents lie in bringing order, structure, and completion. They want to keep order '
    + 'so the organization, group, family, or culture will be preserved. They thrive on organizing and following '
    + 'through with commitments and teaching others how to be successful.',
  },
  {
    code: 'ISTJ',
    title: 'Planner Inspector™',
    description: 'Theme is planning and monitoring, ensuring predictable quality. Thorough, systematic, and careful, '
    + 'they see discrepancies, omissions, and pitfalls. Their talents lie in administrating and regulating. '
    + 'Dependable, realistic, and sensible, they want to conserve the resources of the organization, group, family, or '
    + 'culture and persevere toward that goal. They thrive on planning ahead and being prepared. They like helping '
    + 'others through their roles as parent, supervisor, teammate, and community volunteer.',
  },
  {
    code: 'ESFJ',
    title: 'Facilitator Caretaker™',
    description: 'Theme is providing, ensuring that physical needs are met. Their talents lie in supporting others and '
    + 'supplying them with what they need. They are genuinely concerned about the welfare of others, making sure they '
    + 'are comfortable and involved. Use their sociability to nurture established institutions. Warm, considerate, '
    + 'thoughtful, friendly, they want to please and maintain harmonious relationships. They thrive on helping others '
    + 'and bringing people together.',
  },
  {
    code: 'ISFJ',
    title: 'Protector Supporter™',
    description: 'Theme is protecting and care-taking, ensuring their charges are safe from harm. Their talents lie in '
    + 'making sure everything is taken care of so others can succeed and accomplish their goals. Desiring to serve the '
    + 'needs of individuals, they often work long hours. Friendly, respectful, unassuming, they thrive on serving '
    + 'quietly without fanfare. They are devoted to doing whatever is necessary to ensure shelter and safety, warning '
    + 'about pitfalls and dangers and supporting along the way.',
  },
  {
    code: 'ENTJ',
    title: 'Strategist Mobilizer™',
    description: 'Theme is directing and mobilizing. Their talents lie in developing policy, establishing plans, '
    + 'coordinating and sequencing events, and implementing strategy. They excel at directing others in reaching the '
    + 'goals dictated by their strong vision of the organization and thrive on marshaling forces to get plans put into '
    + 'action. Natural organization builders, they almost always find themselves taking charge in ineffective '
    + 'situations. They enjoy creating efficiently structured systems and setting priorities to achieve goals.',
  },
  {
    code: 'INTJ',
    title: 'Conceptualizer Director™',
    description: 'Theme is strategizing, envisioning, and masterminding. Their talents lie in defining goals, creating '
    + 'detailed plans, and outlining contingencies. They devise strategy, give structure, establish complex plans to '
    + 'reach distant goals dictated by a strong vision of what is needed in the long run. Thrive on putting theories '
    + 'to work and are open to any and all ideas that can be integrated into the complex systems they seek to '
    + 'understand. They drive themselves hard to master what is needed to make progress toward goals.',
  },
  {
    code: 'ENTP',
    title: 'Explorer Inventor™',
    description: 'Theme is inventing, finding ingenious solutions to people and technical problems. Their talents lie '
    + 'in developing ideas into functional and innovative applications that are the first of their kind. They thrive '
    + 'on finding new ways to use theories to make systems more efficient and people better off. Hungry for new '
    + 'projects, they have faith in their ability to instantly come up with new approaches that will work. They are '
    + 'engineers of human relationships and systems as well as in the more scientific and technological domains.',
  },
  {
    code: 'INTP',
    title: 'Designer Theorizer™',
    description: 'Theme is designing and configuring. Their talents lie in grasping the underlying principles of '
    + 'something and defining its essential qualities. They seek to define precisely and bring coherence to systems '
    + 'based on the pattern of organization that is naturally there. They easily notice inconsistencies. They enjoy '
    + 'elegant theories and models for their own sake and for use in solving technical and human problems. Interested '
    + 'in theorizing, analyzing, and learning, they thrive on exploring, understanding, and explaining how the world '
    + 'works.',
  },
  {
    code: 'ENFJ',
    title: 'Envisioner Mentor™',
    description: 'Theme is mentoring, leading people to achieve their potential and become more of who they are. Their '
    + 'talents lie in empathizing with profound interpersonal insight and in influencing others to learn, grow, and '
    + 'develop. They lead using their exceptional communication skills, enthusiasm, and warmth to gain cooperation '
    + 'toward meeting the ideals they hold for the individual or the organization. Catalysts who draw out the best in '
    + 'others, they thrive on empathic connections. Frequently, they are called on to help others with personal '
    + 'problems.',
  },
  {
    code: 'INFJ',
    title: 'Foreseer Developer™',
    description: 'Theme is foresight. They use their insights to deal with complexity in issues and people, often with '
    + 'a strong sense of “knowing” before others know themselves. Their talents lie in developing and guiding people. '
    + 'They trust their inspirations and visions, using them to help others. They thrive on helping others resolve '
    + 'deep personal and ethical dilemmas. Private and complex, they bring a quiet enthusiasm and industry to projects '
    + 'that are part of their vision.',
  },
  {
    code: 'ENFP',
    title: 'Discoverer Advocate™',
    description: 'Theme is inspiration, both of themselves and others. Their talents lie in grasping profound '
    + 'significance, revealing truths, and motivating others. Very perceptive of others’ hidden motives and purposes, '
    + 'they are interested in everything about individuals and their stories as long as they are genuine. They have a '
    + 'contagious enthusiasm for “causes” that further good and develop latent potential and have the same zeal for '
    + 'revealing dishonesty and inauthenticity. Frequently, they are moved to enthusiastically communicate their '
    + '“message.”',
  },
  {
    code: 'INFP',
    title: 'Harmonizer Clarifier™',
    description: 'Theme is advocacy and integrity. Their talents lie in helping people clarify issues, values, and '
    + 'identity. They support anything that allows the unfolding of the person. They encourage growth and development '
    + 'with quiet enthusiasm. Loyal advocates and champions, they care deeply about their causes and a few special '
    + 'people. They are interested in contemplating life’s mysteries, virtues, and vices in their search for '
    + 'wholeness. They thrive on healing conflicts, within and between, and taking people to the center of themselves.',
  },
];

class RawNardiPartFourRunner extends Component {
  constructor(props) {
    super(props);
    this.handleChange = this.handleChange.bind(this);
    this.nextDisabled = this.nextDisabled.bind(this);
    this.next = this.next.bind(this);
  }

  handleChange(event) {
    const { target } = event;
    const { id } = target;
    this.props.setNardiTypecode(id);
  }

  nextDisabled() {
    return this.props.survey.selectedTypecode === undefined;
  }

  next() {
    const { survey: { selectedTypecode }, pgId } = this.props;
    this.props.updateSurvey(
      pgId,
      'nardi',
      { $set: { 'nardi.selectedTypecode': selectedTypecode, 'nardi.status': 'completed' } },
      '/survey',
      dispatch => dispatch(fetchUserNardiFinalScores(pgId)),
    );
  }

  render() {
    const codes = this.props.survey.typeCodes.map(f => f.code);
    const toDisplay = typecodes.filter(fp => codes.indexOf(fp.code) !== -1);
    if (toDisplay.length !== 3) {
      throw Error('Not able to locate three type codes');
    }
    return (
      <Page
        title="Nardi Personality Indicator"
        description={'A self-discovery questionnaire that helps determine your best-fit type, '
        + 'created by Dr. Dario Nardi.'}
      >
        <Grid>
          <Row componentClass="section">
            <Col sm={12}>
              <div className="survey-question">
                <h1 className="my-5 text-center">Nardi Personality Indicator</h1>
                <h2>Finding a Best-Fit: Part III</h2>
                <p className="lead text-muted">
                  Please read the personality Type snapshots below and select one as a best fit.
                </p>
                <p>
                  As you did on the previous page, consider each snapshot &ldquo;as a whole.&rdquo; That is, try them
                  all on looking for the best fit. Evaluate them in terms of overall comfort and accuracy. Then indicate
                  which one fits you the best.
                </p>
                <Well bsSize="large">
                  <div className="survey-options">
                    <FormGroup controlId="fpChoice" >
                      {
                        toDisplay.map(({ code, title, description }) => (
                          <Radio
                            key={code}
                            name="answer"
                            id={code}
                            onClick={this.handleChange}
                          >
                            {title.length > 0 && <h4>{title}</h4>}
                            <p className="text-muted">{description}</p>
                          </Radio>
                        ))
                      }
                    </FormGroup>
                  </div>
                </Well>
                <Button
                  bsStyle="primary"
                  disabled={this.nextDisabled()}
                  onClick={this.next}
                >
                  Next
                </Button>
              </div>
            </Col>
          </Row>
          <Row>
            <Col sm={12}>
              <p className="copy text-muted">
                Type names: Copyright &copy; Dario Nardi, 1998, and Radiance House.
              </p>
            </Col>
          </Row>
        </Grid>
      </Page>
    );
  }
}

RawNardiPartFourRunner.propTypes = {
  pgId: PropTypes.string.isRequired,
  setNardiTypecode: PropTypes.func.isRequired,
  updateSurvey: PropTypes.func.isRequired,
  survey: PropTypes.shape({
    selectedTypecode: PropTypes.string,
    typeCodes: PropTypes.arrayOf(PropTypes.shape({
      code: PropTypes.string,
      score: PropTypes.number,
    })),
  }),
};

RawNardiPartFourRunner.defaultProps = {
  survey: undefined,
};

const mapStateToProps = state => ({
  pgId: getPgId(state),
  survey: getSurvey(state, 'nardi'),
});

const NardiPartFourRunner = connect(
  mapStateToProps,
  { setNardiTypecode, updateSurvey },
)(RawNardiPartFourRunner);

export default NardiPartFourRunner;
