import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { ButtonToolbar, Button, Grid, Row, Col, Image } from 'react-bootstrap';
import { LinkContainer } from 'react-router-bootstrap';
import { connect } from 'react-redux';

import { getPgId, getConsent, getDataReady, getSurvey } from '../../reducers';
import { updateSurvey, calcFinalScore } from '../../actions';

import Page from '../../components/Page';

import logo from '../../images/logos/pg-logo-combined.svg';

class SelfAssessmentPage extends Component {
  constructor(props) {
    super(props);
    this.reject = this.reject.bind(this);
  }
  reject() {
    const { pgId } = this.props;
    this.props.updateSurvey(
      pgId,
      'nardi',
      { $set: { 'selfAssessment.status': 'rejected' } },
      '/survey',
      dispatch => dispatch(calcFinalScore(pgId)),
    );
  }
  render() {
    return (
      <Page
        title="What type are you?"
        description={'A self-assessment questionnaire that allows you to choose your best-fit type and certainty '
        + 'surrounding that choice.'}
      >
        <Grid>
          <Row componentClass="section">
            <Col sm={12}>
              <Image responsive src={logo} style={{ marginLeft: 'auto', marginRight: 'auto' }} />
              <h1 className="text-center mb-4">Self-Assessment Survey</h1>
              <p>
                Let’s face it: personality questionnaires for Personality Type, whether official or free online, often
                fail to pinpoint our true type. Sometimes the difference is only one letter, but that one letter can
                make a HUGE difference: I always test as an INTJ when in fact I am a clear INTP. This is why the MBTI is
                supposed to be offered in conjunction with a guided assessment with a MBTI certified practitioner. Since
                we can’t offer this type of detailed guided assessment to our participants, we invite those of you that
                are interested, and those of you that already know your type and have some knowledge about personality
                type, to provide your self-assessment below.
              </p>
              <p>
                To help guide your self-assessment, we’ve found the following websites provide the most complete and
                accurate information:
              </p>
              <ul>
                <li>
                  <Button bsStyle="link" href="http://www.personalityjunkie.com" target="_blank">
                    www.personalityjunkie.com
                  </Button>
                </li>
                <li>
                  <Button bsStyle="link" href="http://www.16personalities.com" target="_blank">
                    www.16personalities.com
                  </Button>
                </li>
                <li>
                  <Button bsStyle="link" href="http://www.humanmetrics.com/personality" target="_blank">
                    http://www.humanmetrics.com/personality
                  </Button>
                </li>
              </ul>
              <p>
                Each of these websites provides excellent profiles of each personality type as well as their own free
                version of a personality typing questionnaire.
              </p>
              <p>
                You can choose to skip this self-assessment. Your results from the previous two questionnaires will
                still be used in this study.
              </p>
              <ButtonToolbar style={{ display: 'flex', justifyContent: 'center' }}>
                <LinkContainer to="/self-assessment/survey">
                  <Button bsStyle="success">I am ready</Button>
                </LinkContainer>
                <Button
                  bsStyle="danger"
                  onClick={this.reject}
                >
                  No thank you
                </Button>
              </ButtonToolbar>
            </Col>
          </Row>
        </Grid>
      </Page>
    );
  }
}

SelfAssessmentPage.propTypes = {
  pgId: PropTypes.string.isRequired,
  updateSurvey: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  pgId: getPgId(state),
  consent: getConsent(state),
  dataReady: getDataReady(state),
  survey: getSurvey(state, 'selfAssessment'),
});

export default connect(
  mapStateToProps,
  { updateSurvey },
)(SelfAssessmentPage);
