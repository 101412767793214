import React from 'react';
import { Grid, Row, Col, Button, Alert } from 'react-bootstrap';
import { LinkContainer } from 'react-router-bootstrap';
import Helmet from 'react-helmet';

import Masthead from '../components/Masthead';
import HashScroller from '../components/HashScroller';

import oh from '../images/logos/oh.jpg';
import pg from '../images/logos/pg.png';

import '../styles/interop.less';

const IndexPage = () => (
  <HashScroller offset={50}>
    <Helmet
      title="Personality and our genes | Personality Genie"
      meta={[{
        name: 'description',
        content:
          'Participant recruitment page for a research study looking for the genes influencing our personalities',
      }]}
    />
    <Masthead />
    <div id="howitworks">
      <section>
        <Grid>
          <Row>
            <Col sm={12} className="text-center">
              <h2 className="section-heading">How it works</h2>
              <hr className="my-4" />
            </Col>
          </Row>
          <Row>
            <Col xs={12} sm={5} className="text-center">
              <div className="service-box fixed mt-5" >
                <img src={oh} className="mb-1" alt="" style={{ maxWidth: "300px" }} />
                <h3 className="mb-3" >Open Humans</h3>
                <p className="text-muted mb-0">
                  A not-for-profit platform that empowers individuals to explore their personal data, as well as
                  providing opportunities for sharing and connecting data with various research projects and apps.
                </p>
              </div>
            </Col>
            <Col xs={12} sm={2} className="text-center">
              <div className="service-box fixed mt-5 mx-auto">
                <i className="fa arrow text-primary" aria-hidden="true" />
              </div>
            </Col>
            <Col xs={12} sm={5} className="text-center">
              <div className="service-box fixed mt-5" >
                <img src={pg} alt="" className="mb-1" />
                <h3 className="mb-3">Genetics of Personality Type</h3>
                <p className="text-muted mb-0">
                  An independent research project looking at the correlation between personality type and genetic variants.
                </p>
                <p className="text-muted mb-0 small" >
                  <br /><em>Project led by Dr. Denise Cook, personalitygenes@gmail.com</em>
                </p>
              </div>
            </Col>
          </Row>
          <Row>
            <Col lg={8} lgOffset={2} className="text-center mt-5" >
              <p className="text-muted mb-5" >
                Transfer of genotyping data with a randomly-assigned Project Member ID via secure SSL communications.
              </p>
            </Col>
          </Row>
        </Grid>
      </section>
    </div>

    <section className="bg-dark text-white">
      <Grid>
        <Row>
          <Col xs={10} xsOffset={1} className="mt-5" >
            <h5>To participate:</h5>
            <br />
            <ul className="text-muted">
              <li>Click “Yes I'd Like to Participate” below</li>
              <li>Read the consent form, certify that you are 18 years or older and click “Agree”</li>
              <li>Sign-in to your Open Humans account/create a new Open Humans account.</li>
              <li>
                If not already uploaded, upload your genetic data using the uploader specific to your data
                type (i.e., 23andMe, AncestryDNA or FamilyTreeDNA).
              </li>
              <li>Click Authorize Project.</li>
            </ul>
          </Col>
        </Row>
        <Row>
          <Col xs={10} xsOffset={1} className="mt-3" >
            <h5>Once you provide consent to participate in this research project you will be asked to:</h5>
            <br />

            <ul className="text-muted">
              <li>complete the official TypeFinder Personality Test</li>
              <li>complete the Nardi Personality Indicator</li>
              <li>provide your self-assessed choice for best-fit personality type</li>
            </ul>

            <p className="text-muted">This should take you approximately 1 hour to complete.</p>

            <p className="text-muted">You will be provided with:</p>

            <ul className="text-muted">
              <li>several reports based on your best-fit personality type (TypeFinder report, Nardi report, final report)</li>
              <li>continued access to the study website to follow our progress and download any future publications</li>
              <li>a link to share your results to Facebook, if you choose.</li>
            </ul>

            <p className="text-muted">
              You will not be compensated for participating in this project.
            </p>

            <p className="text-muted">
              Your data will be linked using a randomly-assigned Project Member ID provided by Open Humans. We will not
              have access to your username, email address, or any other unique identifiers that Open Humans collects.
            </p>

            <p className="text-muted">
              We will store and analyze your genotyping and personality type data securely using DNANexus
              (<a href="http://www.dnanexus.com">www.dnanexus.com</a>).
            </p>

            <Alert bsStyle="info" className="mt-5">
              This study needs access to both your genetic data and the results of the personality questionnaires.
              Please make sure to upload your genetic data to Open Humans to participate fully in this study.
            </Alert>
          </Col>
        </Row>
        <Row>
          <Col sm={12} md={4} mdOffset={2} className="my-5 text-center">
            <LinkContainer to="/consent">
              <Button bsStyle="primary" className="btn-xl sr-button">
                Yes, I&#39;d like to participate!
              </Button>
            </LinkContainer>
          </Col>
          <Col sm={12} md={4} className="my-5 text-center">
            <LinkContainer to="/survey">
              <Button className="btn btn-light text-dark btn-xl sr-button">
                Already Signed Up
              </Button>
            </LinkContainer>
          </Col>
        </Row>
      </Grid>
    </section>
    <section id="contact">
      <Grid>
        <Row>
          <Col lg={8} lgOffset={2} className="text-center" >
            <h2 className="section-heading">Contact</h2>
            <hr className="my-4" />
          </Col>
        </Row>
        <Row>
          <Col lg={12} className="text-center" >
            <i className="fa fa-envelope-o fa-3x mb-3 sr-contact" />
            <p>
              <a href="mailto:personalitygenes@gmail.com">personalitygenes@gmail.com</a>
            </p>
          </Col>
        </Row>
      </Grid>
    </section>
  </HashScroller>
);

export default IndexPage;
