import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { FormGroup, FormControl } from 'react-bootstrap';
import { connect } from 'react-redux';

import { getSurveyAnswer } from '../../reducers';

import { logAnswer } from '../../actions';
import { isValidText } from './validators';

class RawTextField extends Component {
  constructor(props) {
    super(props);
    const { question, surveyId } = props;
    if (!question) {
      throw Error('Must specify a question');
    }
    if (!surveyId) {
      throw Error('Must specify a surveyId');
    }
    this.handleChange = this.handleChange.bind(this);
    this.getValidationState = this.getValidationState.bind(this);
    this.validate = this.validate.bind(this);
  }

  componentDidMount() {
    const { question, answer } = this.props;
    this.validate(question, answer.value);
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.answer !== nextProps.answer) {
      const { question, answer } = nextProps;
      this.validate(question, answer.value);
    }
  }

  getValidationState() {
    const { question, answer } = this.props;
    return isValidText(question, answer.value) ? 'success' : 'error';
  }

  handleChange(event) {
    const { target } = event;
    const { value } = target;
    const { surveyId, question } = this.props;
    const { id, transform } = question;

    const xform = transform || (v => v);

    this.props.logAnswer(surveyId, 'text', id, xform(value));
  }

  validate(question, value) {
    const { valid } = this.props;
    if (valid) {
      valid(isValidText(question, value));
    }
  }

  render() {
    return (
      <FormGroup controlId={this.props.question.id} validationState={this.getValidationState()} >
        <FormControl type="text" onChange={this.handleChange} value={this.props.answer.value} />
        <FormControl.Feedback />
      </FormGroup>
    );
  }
}

RawTextField.propTypes = {
  valid: PropTypes.func,
  answer: PropTypes.shape({
    value: PropTypes.string,
    id: PropTypes.string,
  }),
  surveyId: PropTypes.string.isRequired,
  logAnswer: PropTypes.func.isRequired,
  question: PropTypes.shape({
    id: PropTypes.string,
    text: PropTypes.string,
    transform: PropTypes.func,
    values: PropTypes.arrayOf(PropTypes.string),
  }).isRequired,
};

RawTextField.defaultProps = {
  answer: { value: '' },
  valid: undefined,
};

// It is important to return a valid default value here, in this case ''. If one does not,
// for example returning {} instead, the contents of answer.value will become undefined and React
// will consider the FormControl holding the text value to be uncontrolled. It will then complain when
// a value is inserted.
const mapStateToProps = (state, ownProps) => ({
  answer: getSurveyAnswer(state, ownProps.surveyId, ownProps.question.id) || '',
});

const mapDispatchToProps = {
  logAnswer,
};

const TextField = connect(
  mapStateToProps,
  mapDispatchToProps,
)(RawTextField);

export default TextField;
