import React from 'react';
import PropTypes from 'prop-types';
import { Row, Col } from 'react-bootstrap';

const multiPrompt = (q, answer) => {
  const ak = answer.value.map(k => k.value || k);
  const ae = answer.value.filter(k => k.extValue !== undefined);
  const qq = q.options.filter(o => ak.indexOf(o.key) !== -1);
  const qqq = qq.map((o) => {
    if (o.extValue) {
      return `${o.prompt}: ${ae.find(k => o.key === k.value).extValue}`;
    }
    return o.prompt;
  });
  return qqq;
};

// Return an array of prompts, no matter what the question type.
// This makes it simpler to present the prompts later
const prompt = (q, ans) => {
  switch (q.questionType) {
    case 'text':
      return [ans.value];

    case 'single':
      try {
        return [q.options.find(({ key }) => key === ans.value).prompt];
      } catch (err) {
        throw err;
      }

    case 'multi':
      return multiPrompt(q, ans);

    default:
      throw Error('Unknown question type');
  }
};

const Review = ({ questions, survey }) => (
  <Row>
    <Col sm={12}>
      <p>Please review your answers before saving.</p>
      <Row>
        <Col sm={8}>
          <p><strong>Question</strong></p>
        </Col>
        <Col sm={4}>
          <p><strong>Answer</strong></p>
        </Col>
      </Row>
      {questions.map(q => (
        <Row key={q.id}>
          <Col sm={8}>
            <p><strong>{q.text}</strong></p>
          </Col>
          <Col sm={4}>
            <ul className="list-unstyled">
              {prompt(q, survey.answers.find(a => a.id === q.id)).map(p => (<li key={p}>{p}</li>))}
            </ul>
          </Col>
        </Row>
      ))}
    </Col>
  </Row>
);

Review.propTypes = {
  questions: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string,
    text: PropTypes.string,
    validator: PropTypes.func,
    options: PropTypes.arrayOf(PropTypes.shape({
      key: PropTypes.string,
      prompt: PropTypes.string,
      extValue: PropTypes.bool,
    })),
  })).isRequired,
  survey: PropTypes.any.isRequired, // eslint-disable-line react/forbid-prop-types
};

export default Review;
