import React from 'react';
import PropTypes from 'prop-types';

import LinkedListGroupItem from '../LinkedListGroupItem';
import Notice from '../Notice';

const getSummary = (survey, scoresViewable) => {
  const {
    typeCode, selectedTypecode, consistency,
  } = survey;
  const finalType = selectedTypecode || typeCode;

  if (scoresViewable && finalType) {
    return (
      <strong className="pull-right text-right">
        {finalType}
        {
          consistency && (<span><br /><small>Consistency:</small> {consistency}/10</span>)
        }
      </strong>
    );
  }
  return (<strong className="pull-right">Pending</strong>);
};

// The following is somewhat in a grey area since it is part state
// and part view logic. I am sticking it here because it is essentially
// part of the business logic in terms of overall flow of the application.
export const getCurrentPath = (survey) => {
  if ((survey || {}).status === 'completed') {
    return undefined;
  }
  switch ((survey || {}).id) {
    case 'selfAssessment':
      return survey.answers ? '/self-assessment/survey' : '/self-assessment';
    case 'nardi':
      if (survey.answers) {
        switch (survey.status) {
          case 'pending':
            return '/nardi/part-one';
          case 'part-one':
            return '/nardi/part-two';
          case 'part-two':
            return '/nardi/part-three';
          default:
            return '/nardi/part-four';
        }
      }
      return '/nardi';
    case 'typeFinder':
      return '/typefinder';
    default:
    case 'mbti':
      return '/mbti';
  }
};

const getSegmentStyle = (survey) => {
  switch ((survey || {}).status) {
    case 'rejected':
      return 'warning';
    default:
      return 'success';
  }
};

const SurveySegment = ({
  title, survey, scoresViewable, nextSurvey, children, topic,
}) => (
  <LinkedListGroupItem
    to={getCurrentPath(survey)}
    bsStyle={getSegmentStyle(survey)}
    disabled={survey.status !== 'completed' && nextSurvey !== survey.id}
    active={nextSurvey === survey.id}
    title={title}
  >
    <h3>{title}</h3>
    <Notice topic={topic} className="my-4" />
    {survey.status === 'completed' &&
      <div>
        <p className="clearfix"><strong>Score:</strong> {getSummary(survey, scoresViewable)}</p>
        <div style={{ clear: 'both' }}>
          {children}
        </div>
      </div>
    }
  </LinkedListGroupItem>
);

SurveySegment.propTypes = {
  title: PropTypes.string.isRequired,
  survey: PropTypes.shape({
    status: PropTypes.string,
    typeCode: PropTypes.string,
    selectedTypecode: PropTypes.string,
    consistency: PropTypes.number,
  }).isRequired,
  scoresViewable: PropTypes.bool.isRequired,
  nextSurvey: PropTypes.string,
  children: PropTypes.any.isRequired, // eslint-disable-line react/forbid-prop-types
  topic: PropTypes.string,
};

SurveySegment.defaultProps = {
  nextSurvey: undefined,
  topic: undefined,
};

export default SurveySegment;
