import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Grid, Row, Col, Well, FormGroup, Radio, Button } from 'react-bootstrap';

import { getPgId, getSurvey } from '../../reducers';
import { setNardiTemperament, updateSurvey } from '../../actions';
import Page from '../../components/Page';

const temperaments = [
  {
    code: 'SP',
    title: 'Improviser',
    description: 'Want the freedom to choose the next act. Seek to have impact, to get results. Want to be graceful, '
    + 'bold, and impressive. Generally are excited and optimistic. Are absorbed in the action of the moment. Are '
    + 'oriented toward the present. Seek adventure and stimulation. Hunger for spontaneity. Trust impulses, luck, and '
    + 'their ability to solve any problem they run into. Think in terms of variation. Have the ability to notice and '
    + 'describe rich detail, constantly seeking relevant information. Like freedom to move, festivities, and games. '
    + 'Are natural negotiators. Seize opportunities. Are gifted tacticians, deciding the best move to make in the '
    + 'moment, the expedient action to take. Are frequently drawn to all kinds of work that requires variation on a '
    + 'theme.',
  },
  {
    code: 'SJ',
    title: 'Stabilizer',
    description: 'Want to fit in, to have membership. Hunger for responsibility, accountability, and predictability. '
    + 'Tend to be generous, to serve and to do their duty. Establish and maintain institutions and standard operating '
    + 'procedures. Tend to protect and preserve, to stand guard and warn. Look to the past and tradition. Foster '
    + 'enculturation with ceremonies and rules. Trust contracts and authority. Want security and stability. Think in '
    + 'terms of what is conventional, comparisons, associations, and discrete elements. Generally are serious and '
    + 'concerned, fatalistic. Are skilled at ensuring that things, information, and people are in the right place, in '
    + 'the right amounts, in the right quality, at the right time. Frequently gravitate toward business and commerce.',
  },
  {
    code: 'NT',
    title: 'Theorist',
    description: 'Want knowledge and to be competent, to achieve. Seek to understand how the world and things in it '
    + 'work. Are theory oriented. See everything as conditional and relative. Are oriented to the infinite. Trust '
    + 'logic and reason. Want to have a rationale for everything. Are skeptical. Think in terms of differences, '
    + 'delineating categories, definitions, structures, and functions. Hunger for precision, especially in thought and '
    + 'language. Are skilled at long-range planning, inventing, designing, and defining. Generally are calm. Foster '
    + 'individualism. Frequently gravitate toward technology and the sciences. Well suited for engineering and '
    + 'devising strategy, whether in the social or physical sciences.',
  },
  {
    code: 'NF',
    title: 'Catalyst',
    description: 'Want to be authentic, benevolent and empathic. Search for identity, meaning and significance. Are '
    + 'relationship oriented, particularly valuing meaningful relationships. Are romantic and idealistic, wanting to '
    + 'make the world a better place. Look to the future. Trust their intuition, imagination, impressions. Focus on '
    + 'developing potential, fostering and facilitating growth through coaching, teaching, counseling, communicating. '
    + 'Generally are enthusiastic. Think in terms of integration and similarities and look for universals. Are gifted '
    + 'in the use of metaphors to bridge different perspectives. Are diplomatic. Frequently are drawn to work that '
    + 'inspires and develops people and relationships.',
  },
];

class RawNardiPartTwoRunner extends Component {
  constructor(props) {
    super(props);
    this.handleChange = this.handleChange.bind(this);
    this.nextDisabled = this.nextDisabled.bind(this);
    this.next = this.next.bind(this);
  }

  handleChange(event) {
    const { target } = event;
    const { id } = target;
    this.props.setNardiTemperament(id);
  }

  nextDisabled() {
    return this.props.survey.selectedTemperament === undefined;
  }

  next() {
    const { survey: { selectedTemperament }, pgId } = this.props;
    this.props.updateSurvey(
      pgId,
      'nardi',
      { $set: { 'nardi.selectedTemperament': selectedTemperament, 'nardi.status': 'part-two' } },
      '/nardi/part-three',
    );
  }

  render() {
    const toDisplay = this.props.survey.temperaments.map(t => temperaments.find(f => f.code === t.code));
    // const toDisplay = temperaments.filter(fp => codes.indexOf(fp.code) !== -1);
    return (
      <Page
        title="Nardi Personality Indicator"
        description={'A self-discovery questionnaire that helps determine your best-fit type, '
        + 'created by Dr. Dario Nardi.'}
      >
        <Grid>
          <Row componentClass="section">
            <Col sm={12}>
              <div className="survey-question">
                <h1 className="my-5 text-center">Nardi Personality Indicator</h1>
                <h2>Finding a Best-Fit: Part I</h2>
                <p className="lead text-muted">
                  Please read the temperament snapshots below and select one as a best fit. Consider each &ldquo;as a
                  whole.&rdquo; That is, try them all on and evaluate them in terms of overall comfort and accuracy.
                  Then indicate which one fits you the best.
                </p>
                <Well bsSize="large">
                  <div className="survey-options">
                    <FormGroup controlId="fpChoice" >
                      {
                        toDisplay.map(({ code, title, description }) => (
                          <Radio
                            key={code}
                            name="answer"
                            id={code}
                            onClick={this.handleChange}
                          >
                            {title.length > 0 && <h4>{title}</h4>}
                            <p className="text-muted">{description}</p>
                          </Radio>
                        ))
                      }
                    </FormGroup>
                  </div>
                </Well>
                <Button
                  bsStyle="primary"
                  disabled={this.nextDisabled()}
                  onClick={this.next}
                >
                  Next
                </Button>
              </div>
            </Col>
          </Row>
          <Row>
            <Col sm={12}>
              <p className="copy text-muted">
                Temperament and type snapshots: Copyright &copy; Linda Berens, 1995, 1998, and Radiance House
              </p>
            </Col>
          </Row>
        </Grid>
      </Page>
    );
  }
}

RawNardiPartTwoRunner.propTypes = {
  pgId: PropTypes.string.isRequired,
  setNardiTemperament: PropTypes.func.isRequired,
  updateSurvey: PropTypes.func.isRequired,
  survey: PropTypes.shape({
    selectedTemperament: PropTypes.string,
    temperaments: PropTypes.arrayOf(PropTypes.shape({
      code: PropTypes.string,
      score: PropTypes.number,
    })),
  }),
};

RawNardiPartTwoRunner.defaultProps = {
  survey: undefined,
};

const mapStateToProps = state => ({
  pgId: getPgId(state),
  survey: getSurvey(state, 'nardi'),
});

const NardiPartTwoRunner = connect(
  mapStateToProps,
  { setNardiTemperament, updateSurvey },
)(RawNardiPartTwoRunner);

export default NardiPartTwoRunner;
