import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Alert, Button } from 'react-bootstrap';

class DismissableAlert extends Component {
  constructor(props) {
    super(props);
    if (props.showInitial === false && props.showButton === false) {
      throw Error('Cannot set both showInitial and showButton to false');
    }
    this.handleShow = this.handleShow.bind(this);
    this.handleDismiss = this.handleDismiss.bind(this);
    this.state = { show: props.showInitial };
  }
  handleShow() {
    this.setState({ show: true });
  }
  handleDismiss() {
    this.setState({ show: false });
  }
  render() {
    const {
      buttonStyle, buttonText, buttonTextSize, showInitial, showButton, children, ...rest
    } = this.props;

    if (this.state.show) {
      return (
        <Alert {...rest} onDismiss={this.handleDismiss}>
          {children}
        </Alert>
      );
    }
    if (showButton) {
      return (
        <Button bsStyle={buttonStyle} onClick={this.handleShow}>
          {buttonTextSize === 'small'
            ? <small>{buttonText}</small>
            : buttonText
          }
        </Button>
      );
    }
    return null; // Show nothing if not showing.
  }
}

/* eslint-disable react/forbid-prop-types */
DismissableAlert.propTypes = {
  children: PropTypes.any.isRequired,
  showInitial: PropTypes.bool,
  showButton: PropTypes.bool,
  buttonText: PropTypes.string,
  buttonStyle: PropTypes.string,
  buttonTextSize: PropTypes.string,
};
/* eslint-enable */

DismissableAlert.defaultProps = {
  showInitial: true,
  showButton: false,
  buttonText: '',
  buttonStyle: 'primary',
  buttonTextSize: 'normal',
};

export default DismissableAlert;
