import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Grid, Row, Col, Form, FormGroup, FormControl, ControlLabel, Button } from 'react-bootstrap';
import csv from 'fast-csv';
import { connect } from 'react-redux';

import { saveMbtiScores } from '../actions';
import DismissableAlert from '../components/DismissableAlert';

class MbtiUploadPage extends Component {
  constructor(props) {
    super(props);
    this.onChange = this.onChange.bind(this);
    this.state = {};
  }
  /* eslint-disable class-methods-use-this, no-console */
  onChange(event) {
    event.preventDefault();
    const file = event.target.files[0];
    const reader = new FileReader();
    const items = [];
    reader.onloadend = (ev) => {
      csv
        .fromString(ev.target.result, { headers: true, delimiter: ',', trim: true })
        .transform(row => ({
          pgId: row.EMAIL.split('@')[0],
          wholeType: row.FormM_WholeType,
          pci1: +row.FormM_pci1,
          pci2: +row.FormM_pci2,
          pci3: +row.FormM_pci3,
          pci4: +row.FormM_pci4,
          dpref1: +row.FormM_dpref1,
          dpref2: +row.FormM_dpref2,
          dpref3: +row.FormM_dpref3,
          dpref4: +row.FormM_dpref4,
        }))
        .on('data', (data) => {
          items.push(data);
        })
        .on('end', () => {
          const toSubmit = items.reduce((arr, val) => ({
            ...arr,
            [val.pgId]: {
              wholeType: val.wholeType,
              pci1: val.pci1,
              pci2: val.pci2,
              pci3: val.pci3,
              pci4: val.pci4,
              dpref1: val.dpref1,
              dpref2: val.dpref2,
              dpref3: val.dpref3,
              dpref4: val.dpref4,
            },
          }), {});
          this.props.saveMbtiScores(toSubmit)
            .then(counts => this.setState({ counts }));
        });
    };
    reader.onerror = (err) => {
      console.log('Error reading CSV file', err); // keep
    };
    reader.readAsText(file);
  }
  /* eslint-enable */

  /* eslint-disable class-methods-use-this */
  download() {
    window.location.href =
      `${window.location.protocol}//${window.location.host}/api/stats`;
  }
  /* eslint-enable */

  render() {
    return (
      <Grid>
        <Row componentClass="section">
          <Col sm={12}>
            <Form>
              <FormGroup controlId="upload" >
                <ControlLabel>Upload File</ControlLabel>
                <FormControl type="file" accept=".csv" onChange={this.onChange} />
              </FormGroup>
              <FormGroup controlId="download" >
                <ControlLabel>Download Stats</ControlLabel>
                <Button bsStyle="primary" onClick={this.download}>
                  Download CSV
                </Button>
              </FormGroup>
            </Form>
          </Col>
        </Row>
        {this.state && this.state.counts &&
          <Row>
            <Col sm={12}>
              <DismissableAlert bsStyle="info">
                <h3>Upload Results</h3>
                <p>
                  Inserted: {this.state.counts.insertedCount}
                </p>
                <p>
                  Matched: {this.state.counts.matchedCount}
                </p>
                <p>
                  Modified: {this.state.counts.modifiedCount}
                </p>
                <p>
                  Deleted: {this.state.counts.deletedCount}
                </p>
                <p>
                  Upserted: {this.state.counts.upsertedCount}
                </p>
              </DismissableAlert>
            </Col>
          </Row>
        }
      </Grid>
    );
  }
}

MbtiUploadPage.propTypes = {
  saveMbtiScores: PropTypes.func.isRequired,
};

const mapStateToProps = () => ({});

export default connect(
  mapStateToProps,
  { saveMbtiScores },
)(MbtiUploadPage);
