export const typeName = (code) => {
  switch (code) {
    case 'ESTP': return 'Promoter Executor™';
    case 'ISTP': return 'Analyzer Operator™';
    case 'ESFP': return 'Motivator Presenter™';
    case 'ISFP': return 'Composer Producer™';
    case 'ESTJ': return 'Implementor Supervisor™';
    case 'ISTJ': return 'Planner Inspector™';
    case 'ESFJ': return 'Facilitator Caretaker™';
    case 'ISFJ': return 'Protector Supporter™';
    case 'ENTJ': return 'Strategist Mobilizer™';
    case 'INTJ': return 'Conceptualizer Director™';
    case 'ENTP': return 'Explorer Inventor™';
    case 'INTP': return 'Designer Theorizer™';
    case 'ENFJ': return 'Envisioner Mentor™';
    case 'INFJ': return 'Foreseer Developer™';
    case 'ENFP': return 'Discoverer Advocate™';
    case 'INFP': return 'Harmonizer Clarifier™';
    default:
      throw Error(`Invalid type code: ${code}`);
  }
};

export const functionalPairName = (code) => {
  switch (code) {
    case 'SFP': return 'The Experiential Improviser';
    case 'STP': return 'The Practical Expeditor';
    case 'STJ': return 'The Managerial Organizer';
    case 'SFJ': return 'The Conscientious Facilitator';
    case 'NTJ': return 'The Visionary Coordinator';
    case 'NTP': return 'The Inquisitive Engineer';
    case 'NFJ': return 'The Intuitive Guide';
    case 'NFP': return 'The Inspirational Mediator';
    default:
      throw Error(`Invalid functional pair: ${code}`);
  }
};

export const temperamentName = (code) => {
  switch (code) {
    case 'SP': return 'Improviser';
    case 'SJ': return 'Stabilizer';
    case 'NT': return 'Theorist';
    case 'NF': return 'Catalyst';
    default:
      throw Error(`Invalid temperament name: ${code}`);
  }
};

export const interactionName = (code) => {
  switch (code) {
    case 'behindScenes': return 'Behind-the-Scenes';
    case 'inCharge': return 'In-Charge';
    case 'chartCourse': return 'Chart-the-Course';
    case 'getGoing': return 'Get-Things-Going';
    default:
      throw Error(`Invalid interaction style name: ${code}`);
  }
};

export const functionalPairDescriptor = (code) => {
  switch (code) {
    case 'NTJ': return 'Extraverted thinking, introverted iNtuiting';
    case 'NTP': return 'Extraverted iNtuiting introverted thinking';
    case 'NFJ': return 'Extraverted feeling, introverted iNtuiting';
    case 'NFP': return 'Extraverted iNtuiting, introverted feeling';
    case 'STJ': return 'Extraverted thinking, introverted sensing';
    case 'STP': return 'Extraverted sensing, introverted thinking';
    case 'SFJ': return 'Extraverted feeling, introverted sensing';
    case 'SFP': return 'Extraverted sensing, introverted feeling';
    default:
      throw Error(`Invalid functional pair: ${code}`);
  }
};
