import React from 'react';
import { Grid, Row, Col, Navbar, Nav, NavItem } from 'react-bootstrap';
import { LinkContainer } from 'react-router-bootstrap';

import './style.css';

const Footer = () => (
  <div id="footer">
    <Navbar fluid>
      <Nav className="social">
        <NavItem href="https://www.facebook.com/personalitygenie/" target="_blank">
          <i className="fa fa-facebook-square fa-2x" />
        </NavItem>
        <NavItem href="https://twitter.com/personalitygeni" target="_blank">
          <i className="fa fa-twitter-square fa-2x" />
        </NavItem>
        <NavItem href="https://www.linkedin.com/in/dencook" target="_blank">
          <i className="fa fa-linkedin-square fa-2x" />
        </NavItem>
      </Nav>
      <Nav className="footer-nav">
        <LinkContainer to="/privacy">
          <NavItem>Privacy</NavItem>
        </LinkContainer>
        <LinkContainer to="/terms">
          <NavItem>Terms &amp; Conditions</NavItem>
        </LinkContainer>
        <LinkContainer to="/about">
          <NavItem>About Us</NavItem>
        </LinkContainer>
      </Nav>
    </Navbar>
    <Grid fluid>
      <Row className="mb-3">
        <Col xs={12} sm={6}>
          <div className="copy text-muted">
            Copyright <span role="img" aria-label="Copyright symbol">&copy;</span> 2018, Personality Genie.
            All rights reserved.<br />
          </div>
        </Col>
        <Col xs={12} sm={6}>
          <div className="design-by text-right text-muted">
            Website developed by <a href="https://www.cataluma.com">Cataluma LLC</a>
          </div>
        </Col>
      </Row>
      <Row className="mb-3">
        <Col sm={12}>
          <div className="copy text-muted">
            The TypeFinder Personality Test and the Truity/Talent Insights logos are trademarks of Truity Psychometrics LLC.
            Nardi Personality Indicator copyright <span role="img" aria-label="Copyright symbol">&copy;</span> Dr. Dario
            Nardi and Radiance House.
          </div>
        </Col>
      </Row>
    </Grid>
  </div>
);

export default Footer;
