// Notifications

export const showNotification = (id, text) => ({
  type: 'SHOW_NOTIFICATION',
  id,
  text,
});

export const hideNotification = id => ({
  type: 'HIDE_NOTIFICATION',
  id,
});

let notificationId = 0;
export function showNotificationWithTimeout(text, delay = 5000) {
  return (dispatch) => {
    const id = notificationId;
    notificationId += 1;
    dispatch(showNotification(id, text));
    setTimeout(() => {
      dispatch(hideNotification(id));
    }, delay);
  };
}

export const removeNotifications = () => ({
  type: 'REMOVE_NOTIFICATIONS',
});
