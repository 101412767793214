import React, { Component } from "react";
import { Router } from "react-router";
import blankshield from "blankshield";
import ReactGA from "react-ga";
import * as Sentry from "@sentry/react";
import ReactPixel from "react-facebook-pixel";

import AppRouter from "./AppRouter";
import { browserHistory } from "./history";

import "./App.css";

require("typeface-merriweather");
require("typeface-open-sans");

const pixelId = process.env.REACT_APP_FB_PIXEL_ID;

ReactGA.initialize(
  process.env.NODE_ENV === "development" ? null : "UA-73032444-1"
);

if (pixelId) {
  ReactPixel.init(pixelId);
}

browserHistory.listen((location) => {
  console.log(`Navigated to ${location.pathname}`);
  ReactGA.pageview(location.pathname);
  ReactPixel.pageView();
});

// Force push initial url to be listened by Google Analytics
browserHistory.push(window.location);

// Ensures that all unsafe window.opens are properly protected. We do this once here in order to avoid complex coding
// to incorporate within the React/React-Router space.
//
// See documentation at https://www.npmjs.com/package/blankshield
blankshield.patch();

class App extends Component {
  /* eslint-disable padded-blocks, func-names, key-spacing, one-var, no-var, one-var-declaration-per-line */
  componentDidMount() {
    const fbAppId = process.env.REACT_APP_FB_APP_ID;
    if (!fbAppId) {
      console.log("Warning: FaceBook App ID is not found");
    }
    window.fbAsyncInit = function () {
      window.FB.init({
        appId: fbAppId,
        autoLogAppEvents: true,
        xfbml: true,
        version: "v8.0",
      });
    };

    (function (d, s, id) {
      var js,
        fjs = d.getElementsByTagName(s)[0];
      if (d.getElementById(id)) {
        return;
      }
      js = d.createElement(s);
      js.id = id;
      js.src = "https://connect.facebook.net/en_US/sdk.js";
      fjs.parentNode.insertBefore(js, fjs);
    })(document, "script", "facebook-jssdk");
  }
  /* eslint-enable */

  render() {
    return (
      <Router history={browserHistory}>
        <AppRouter />
      </Router>
    );
  }
}

export default Sentry.withProfiler(App);
