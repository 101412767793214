import { combineReducers } from 'redux';

import user from './user';
import notifications, * as fromNotifications from './notifications';
import loadStatus, * as fromLoadState from './load-status';
import * as fromAccess from './access';
import * as fromSurvey from './survey';
import notice, * as fromNotice from './notice';

const pgApp = combineReducers({
  user,
  notifications,
  loadStatus,
  notice,
});

export default pgApp;


// NOTIFICATIONS


export const getNotifications = state =>
  fromNotifications.getNotifications(state.notifications);


export const getNotice = (state, topic) =>
  fromNotice.getNotice(state.notice, topic);

// LOAD STATUS


export const getLoadStatus = state =>
  fromLoadState.getLoadStatus(state.loadStatus);

export const getLoadMessage = state =>
  fromLoadState.getLoadMessage(state.loadStatus);


// USER ACCESS


export const getPgId = state =>
  fromAccess.getPgId(state.user.access);

export const getConsent = state =>
  fromAccess.getConsent(state.user.access);

export const getDataReady = state =>
  fromAccess.getDataReady(state.user.access);

export const getSnpFileUploaded = state =>
  fromAccess.getSnpFileUploaded(state.user.access);

// Does the user have access to a particular survey?
export const getAuthStatus = (state, surveyId) => (
  fromAccess.getAuthStatus(state.user.access) &&
  fromSurvey.getAuthStatus(state.user.survey, surveyId)
);

// Does the user have access at all?
export const getUserAuthStatus = state =>
  fromAccess.getAuthStatus(state.user.access);

// SURVEY ACCESS

export const getAllDone = state =>
  fromSurvey.getAllDone(state.user.survey);

export const getSurvey = (state, surveyId) =>
  fromSurvey.getSurvey(state.user.survey, surveyId);

export const getSurveys = state =>
  fromSurvey.getSurveys(state.user.survey);

export const getSurveyAnswer = (state, surveyId, questionId) =>
  fromSurvey.getSurveyAnswer(state.user.survey, surveyId, questionId);

export const getNextSurvey = state =>
  fromSurvey.getNextSurvey(state.user.survey);

export const getFinalScore = state =>
  fromSurvey.getFinalScore(state.user.survey);

export const getSurveyScoreViewable = (state, surveyId) =>
  fromAccess.getSurveyScoreViewable(state.user.access, surveyId) &&
  fromSurvey.getSurveyScoreViewable(state.user.survey, surveyId);
