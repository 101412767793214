import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Grid, Row, Col } from 'react-bootstrap';

import { getPgId, getConsent, getDataReady } from '../../reducers';

import SurveyRunner from '../../components/SurveyRunner';
import { fetchUserNardiScores } from '../../actions';
import Page from '../../components/Page';

// {
//   (!pgId ||
//     gencoveConsent !== true ||
//     dataReady !== true ||
//     !isPart(survey, 'pending')
//   ) &&
//   <Redirect to="/survey" />
// }

const RawNardiPartOneRunner = ({ pgId }) => (
  <Page
    title="Nardi Personality Indicator"
    description="A self-discovery questionnaire that helps determine your best-fit type, created by Dr. Dario Nardi."
  >
    <SurveyRunner
      title="Nardi Personality Indicator"
      subtitle="Part I: Your Preferences"
      surveyId="nardi"
      nextPath="/nardi/part-two"
      nextStatus="part-one"
      onComplete={dispatch => dispatch(fetchUserNardiScores(pgId))}
      questions={[
        {
          id: '1',
          text: 'You are more:',
          questionType: 'single',
          options: [
          { key: 'A', prompt: 'outgoing and talkative' },
          { key: 'B', prompt: 'solitary and quiet' },
          ],
        },
        {
          id: '2',
          text: 'You prefer to motivate someone using:',
          questionType: 'single',
          options: [
          { key: 'A', prompt: 'subtle encouragement' },
          { key: 'B', prompt: 'clear explanation' },
          ],
        },
        {
          id: '3',
          text: 'You are known more as:',
          questionType: 'single',
          options: [
          { key: 'A', prompt: 'sensible' },
          { key: 'B', prompt: 'innovative' },
          ],
        },
        {
          id: '4',
          text: 'You prefer to make decisions according to:',
          questionType: 'single',
          options: [
          { key: 'A', prompt: 'objective criteria' },
          { key: 'B', prompt: 'personal values' },
          ],
        },
        {
          id: '5',
          text: 'You find it easier to:',
          questionType: 'single',
          options: [
          { key: 'A', prompt: 'improvise and adapt' },
          { key: 'B', prompt: 'persist with a plan' },
          ],
        },
        {
          id: '6',
          text: 'You often notice:',
          questionType: 'single',
          options: [
          { key: 'A', prompt: 'how things are organized' },
          { key: 'B', prompt: 'what is motivating someone' },
          ],
        },
        {
          id: '7',
          text: 'You are more comfortable:',
          questionType: 'single',
          options: [
          { key: 'A', prompt: 'initiating interactions with others' },
          { key: 'B', prompt: 'responding after others have initiated interaction with you' },
          ],
        },
        {
          id: '8',
          text: 'You prefer to:',
          questionType: 'single',
          options: [
          { key: 'A', prompt: 'act in a way that gets results' },
          { key: 'B', prompt: 'maintain harmonious relations' },
          ],
        },
        {
          id: '9',
          text: 'You are more comfortable with:',
          questionType: 'single',
          options: [
          { key: 'A', prompt: 'conjecture, conceptualizing, envisioning, and figurative language' },
          {
            key: 'B',
            prompt: 'firsthand experience, practical ideas, and descriptions of actual events or situations',
          },
          ],
        },
        {
          id: '10',
          text: 'You are known more as someone who is:',
          questionType: 'single',
          options: [
          { key: 'A', prompt: 'warm hearted' },
          { key: 'B', prompt: 'clear headed' },
          ],
        },
        {
          id: '11',
          text: 'To complete a task, you usually:',
          questionType: 'single',
          options: [
          { key: 'A', prompt: 'plan what to do, gather the resources, and do it' },
          { key: 'B', prompt: 'start, see what happens, and adjust accordingly' },
          ],
        },
        {
          id: '12',
          text: 'When you wish for someone to do something for you, you prefer to:',
          questionType: 'single',
          options: [
          { key: 'A', prompt: 'just be direct and state what you need' },
          { key: 'B', prompt: 'comment on the situation to suggest what you might need' },
          ],
        },
        {
          id: '13',
          text: 'You are known more as someone who is:',
          questionType: 'single',
          options: [
          { key: 'A', prompt: 'compassionate' },
          { key: 'B', prompt: 'reasonable' },
          ],
        },
        {
          id: '14',
          text: 'You often:',
          questionType: 'single',
          options: [
          { key: 'A', prompt: 'want to independently choose actions for expedience' },
          { key: 'B', prompt: 'check in with roles, values, and norms' },
          ],
        },
        {
          id: '15',
          text: 'You are more skilled at:',
          questionType: 'single',
          options: [
          { key: 'A', prompt: 'making and sticking to plans' },
          { key: 'B', prompt: 'noticing and pursuing options' },
          ],
        },
        {
          id: '16',
          text: 'Meeting new people usually leaves you:',
          questionType: 'single',
          options: [
          { key: 'A', prompt: 'energized' },
          { key: 'B', prompt: 'drained' },
          ],
        },
        {
          id: '17',
          text: 'When talking with someone, you prefer to:',
          questionType: 'single',
          options: [
          { key: 'A', prompt: 'get to the point' },
          { key: 'B', prompt: 'suggest several points' },
          ],
        },
        {
          id: '18',
          text: 'You trust:',
          questionType: 'single',
          options: [
          { key: 'A', prompt: 'commonsense experience' },
          { key: 'B', prompt: 'abstract understanding' },
          ],
        },
        {
          id: '19',
          text: 'When someone talks, you attend more to:',
          questionType: 'single',
          options: [
          { key: 'A', prompt: 'why they are talking' },
          { key: 'B', prompt: 'what they are saying' },
          ],
        },
        {
          id: '20',
          text: 'When making a decision, you prefer to:',
          questionType: 'single',
          options: [
          { key: 'A', prompt: 'separate out personal feelings and rely on impersonal principles' },
          { key: 'B', prompt: 'add weight of personal preferences and what is most important' },
          ],
        },
        {
          id: '21',
          text: 'You are known as someone who can:',
          questionType: 'single',
          options: [
          { key: 'A', prompt: 'maintain affiliations' },
          { key: 'B', prompt: 'get the job done' },
          ],
        },
        {
          id: '22',
          text: 'When solving a problem, you prefer to:',
          questionType: 'single',
          options: [
          { key: 'A', prompt: 'get down to details' },
          { key: 'B', prompt: 'dream up possibilities' },
          ],
        },
        {
          id: '23',
          text: 'You are known as someone who:',
          questionType: 'single',
          options: [
          { key: 'A', prompt: 'notices relevant details' },
          { key: 'B', prompt: 'perceives unseen patterns' },
          ],
        },
        {
          id: '24',
          text: 'You are more skilled at:',
          questionType: 'single',
          options: [
          { key: 'A', prompt: 'measuring or defining' },
          { key: 'B', prompt: 'being empathic' },
          ],
        },
        {
          id: '25',
          text: 'You are more comfortable:',
          questionType: 'single',
          options: [
          { key: 'A', prompt: 'providing input and options' },
          { key: 'B', prompt: 'telling people what to do' },
          ],
        },
      ]}
    />
    <Grid>
      <Row>
        <Col sm={12}>
          <p className="copy text-muted">
            Copyright &copy; Dario Nardi, 1998, and Radiance House.
          </p>
        </Col>
      </Row>
    </Grid>
  </Page>
);

RawNardiPartOneRunner.propTypes = {
  pgId: PropTypes.string.isRequired,
};

const mapStateToProps = state => ({
  pgId: getPgId(state),
  consent: getConsent(state),
  dataReady: getDataReady(state),
  // survey: getSurvey(state, 'nardi'),
});

const NardiPartOneRunner = connect(mapStateToProps)(RawNardiPartOneRunner);

export default NardiPartOneRunner;
