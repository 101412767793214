import axios from 'axios';
import { browserHistory } from '../history';

import { fetchUserSummaryState } from './user';

import { showNotificationWithTimeout } from './notifications';
import { setNotice } from '../actions';

// Survey actions

export const updateCurrentQuestion = (surveyKey, index, status) => ({
  type: 'UPDATE_CURRENT_QUESTION',
  surveyId: surveyKey,
  index,
  status,
});

export const logAnswer = (surveyKey, questionType, questionId, value, extValue, uncheck) => ({
  type: 'LOG_ANSWER',
  surveyId: surveyKey,
  questionType,
  questionId,
  value,
  ...extValue && { extValue },
  ...uncheck && { uncheck },
});

// Survey persistence

export const requestSetSurvey = (pgId, survey) => ({
  type: 'REQUEST_SET_SURVEY',
  pgId,
  surveyId: survey.id,
  survey,
});

export const confirmSetSurvey = (pgId, survey) => ({
  type: 'CONFIRM_SET_SURVEY',
  pgId,
  surveyId: survey.id,
  status: survey.status,
});

export const setSurveyFailed = (pgId, err) => ({
  type: 'SURVEY_SET_FAILED',
  pgId,
  status: err.status,
  message: err.message,
});

export function setSurvey(pgId, survey, nextPath = '/survey', afterSave, beforeSave = s => s) {
  return (dispatch) => {
    dispatch(requestSetSurvey(pgId, survey));

    const toPersist = beforeSave(survey); // Last chance to manipulate survey data prior to saving...
    return axios.post('/api/user/survey', {
      pgId,
      survey: toPersist,
    })
      .then((res) => {
        if (afterSave) {
          return afterSave(dispatch);
        }
        return res;
      })
      .then((res) => {
        dispatch(confirmSetSurvey(pgId, survey));
        browserHistory.push(nextPath);
        return res;
      })
      .catch((err) => {
        dispatch(setSurveyFailed(pgId, err));
      });
  };
}

export const requestUpdateSurvey = (pgId, surveyId) => ({
  type: 'REQUEST_UPDATE_SURVEY',
  surveyId,
});

export const confirmUpdateSurvey = (pgId, surveyId) => ({
  type: 'CONFIRM_UPDATE_SURVEY',
  pgId,
  surveyId,
});

export const updateSurveyFailed = (pgId, err) => ({
  type: 'SURVEY_UPDATE_FAILED',
  pgId,
  status: err.status,
  message: err.message,
});

export function updateSurvey(pgId, surveyId, update, nextPath = '/survey', afterSave, beforeSave = s => s) {
  return (dispatch) => {
    dispatch(requestSetSurvey(pgId, surveyId));

    const toPersist = beforeSave(update); // Last chance to manipulate survey data prior to saving...
    return axios.post('/api/user/survey/upd', {
      pgId,
      update: toPersist,
    })
      .then((res) => {
        if (afterSave) {
          return afterSave(dispatch);
        }
        return res;
      })
      .then((res) => {
        dispatch(confirmSetSurvey(pgId, surveyId));
        browserHistory.push(nextPath);
        return res;
      })
      .catch((err) => {
        dispatch(setSurveyFailed(pgId, err));
      });
  };
}

export const setSurveyStatus = (surveyId, status) => ({
  type: 'SET_SURVEY_STATUS',
  surveyId,
  status,
});

export function resetSurveyStatus(pgId) {
  return (dispatch) => {
    dispatch(showNotificationWithTimeout('Resetting surveys for current user'));
    return axios.post('/api/user/reset', { pgId })
      .then(() => {
        dispatch(showNotificationWithTimeout('Surveys reset for current user'));
        dispatch(fetchUserSummaryState(pgId));
        dispatch(updateCurrentQuestion('selfAssessment', 0, 'pending'));
        dispatch(updateCurrentQuestion('nardi', 0, 'pending'));
        dispatch(updateCurrentQuestion('typeFinder', 0, 'pending'));
        dispatch(updateCurrentQuestion('mbti', 0, 'pending'));
      })
      .catch(() => {
        dispatch(showNotificationWithTimeout('Failed to update surveys for current user'));
      });
  };
}

// NARDI SCORES - INITIAL

export const requestNardiScores = pgId => ({
  type: 'REQUEST_NARDI_SCORES',
  pgId,
});

export const receiveNardiScores = (pgId, typeCodes, functionalPairs, temperaments) => ({
  type: 'RECEIVE_NARDI_SCORES',
  pgId,
  surveyId: 'nardi',
  typeCodes,
  functionalPairs,
  temperaments,
});

export const userNardiScoresFailed = (pgId, message) => ({
  type: 'NARDI_SCORES_FAILED',
  pgId,
  message,
});

export function fetchUserNardiScores(pgId) {
  return (dispatch) => {
    dispatch(requestNardiScores(pgId));

    return axios.post('/api/user/nardi', { pgId })
      .then((json) => {
        const { typeCodes, functionalPairs, temperaments } = json.data;
        dispatch(receiveNardiScores(pgId, typeCodes, functionalPairs, temperaments));
      })
      .catch((err) => {
        dispatch(userNardiScoresFailed(pgId, err.message));
      });
  };
}

// NARDI SCORES - FINAL SCORES

export const requestNardiFinalScores = pgId => ({
  type: 'REQUEST_NARDI_FINAL_SCORES',
  pgId,
});

export const receiveNardiFinalScores = (pgId, typeCode, consistency) => ({
  type: 'RECEIVE_NARDI_FINAL_SCORES',
  pgId,
  surveyId: 'nardi',
  typeCode,
  consistency,
});

export const userNardiFinalScoresFailed = (pgId, message) => ({
  type: 'NARDI_FINAL_SCORES_FAILED',
  pgId,
  message,
});

export function fetchUserNardiFinalScores(pgId) {
  return (dispatch) => {
    dispatch(requestNardiFinalScores(pgId));

    return axios.post('/api/user/nardi/final', { pgId })
      .then((json) => {
        const { typeCode, consistency } = json.data;
        dispatch(receiveNardiFinalScores(pgId, typeCode, consistency));
      })
      .catch((err) => {
        dispatch(userNardiFinalScoresFailed(pgId, err.message));
      });
  };
}

// NARDI - RECORD SELECTIONS

export const setNardiFP = code => ({
  type: 'SET_NARDI_FP',
  surveyId: 'nardi',
  code,
});

export const setNardiTemperament = code => ({
  type: 'SET_NARDI_TEMPERAMENT',
  surveyId: 'nardi',
  code,
});

export const setNardiTypecode = code => ({
  type: 'SET_NARDI_TYPECODE',
  surveyId: 'nardi',
  code,
});


// NARDI REPORT DATA

export const requestNardiReportData = pgId => ({
  type: 'REQUEST_NARDI_REPORT_DATA',
  pgId,
});

export const receiveNardiReportData = (pgId, reportData) => ({
  type: 'RECEIVE_NARDI_REPORT_DATA',
  pgId,
  surveyId: 'nardi',
  reportData,
});

export const nardiReportDataFailed = (pgId, message) => ({
  type: 'NARDI_REPORT_DATA_FAILED',
  pgId,
  message,
});

export function fetchUserNardiReportData(pgId) {
  return (dispatch) => {
    dispatch(requestNardiReportData(pgId));

    return axios.post('/api/user/nardi/report', { pgId })
      .then((json) => {
        const { pgId: ignore, status, ...rest } = json.data;
        dispatch(receiveNardiReportData(pgId, { ...rest }));
        browserHistory.push('/nardi-report');
      })
      .catch((err) => {
        dispatch(nardiReportDataFailed(pgId, err.message));
      });
  };
}

// TypeFinder

export const requestUpdateTypeFinderResult = pgId => ({
  type: 'REQUEST_UPDATE_TYPEFINDER_RESULT',
  pgId,
});

export const receiveUpdateTypeFinderResult = (pgId, typeCode) => ({
  type: 'RECEIVE_UPDATE_TYPEFINDER_RESULT',
  pgId,
  typeCode,
});

export const updateTypeFinderResultFailed = pgId => ({
  type: 'UPDATE_TYPEFINDER_RESULT_FAILED',
  pgId,
});

export function updateTypeFinderResult(pgId) {
  return (dispatch) => {
    dispatch(requestUpdateTypeFinderResult(pgId));

    return axios.post('/api/user/tfresult', { pgId })
      .then((res) => {
        const { typeCode } = res.data;
        dispatch(setSurvey(pgId, { id: 'typeFinder', status: 'completed', typeCode }));
      })
      .catch((err) => {
        dispatch(updateTypeFinderResultFailed(err.data));
        dispatch(setNotice('typefinder', `We failed to match your Study ID in our database.
        Please make sure you enter your Study ID email address and retake the survey.`, 'danger'));
        browserHistory.push('/survey');
      });
  };
}

// MBTI

export const requestMbtiSave = () => ({
  type: 'REQUEST_MBTI_SAVE',
});

export const confirmMbtiSave = (results) => {
  const {
    insertedCount, matchedCount, modifiedCount, deletedCount, upsertedCount,
  } = results;
  return ({
    type: 'CONFIRM_MBTI_SAVE',
    insertedCount,
    matchedCount,
    modifiedCount,
    deletedCount,
    upsertedCount,
  });
};

export const mbtiSaveFailed = message => ({
  type: 'MBTI_SAVE_FAILED',
  message,
});

export function saveMbtiScores(scores) {
  return (dispatch) => {
    // dispatch(showNotificationWithTimeout('Uploading MBTI scores'));
    dispatch(requestMbtiSave());

    return axios.post('/api/user/mbti', scores)
      .then((resp) => {
        // dispatch(showNotificationWithTimeout('MBTI scores uploaded successfully'));
        dispatch(confirmMbtiSave(resp.data));
        return resp.data;
      })
      .catch((err) => {
        // dispatch(showNotificationWithTimeout('MBTI score upload failed'));
        dispatch(mbtiSaveFailed(err.message));
      });
  };
}

const requestRejectSurvey = surveyId => ({
  type: 'REQUEST_REJECT_SURVEY',
  surveyId,
});

const confirmRejectSurvey = surveyId => ({
  type: 'CONFIRM_REJECT_SURVEY',
  surveyId,
});

const rejectSurveyFailed = (surveyId, message) => ({
  type: 'REJECT_SURVEY_FAILED',
  surveyId,
  message,
});

export function rejectSurvey(pgId, surveyId) {
  return (dispatch) => {
    dispatch(requestRejectSurvey(surveyId));
    return axios.post('/api/user/survey/reject', {
      pgId,
      surveyId,
    })
      .then((resp) => {
        dispatch(confirmRejectSurvey(surveyId));
        return resp.data;
      })
      .catch((err) => {
        dispatch(rejectSurveyFailed(surveyId, err.message));
      });
  };
}

const requestCalFinalScore = () => ({
  type: 'REQUEST_CALC_FINAL_SCORE',
});

const receiveFinalScore = score => ({
  type: 'RECEIVE_FINAL_SCORE',
  finalScore: score,
});

const finalScoreFailed = message => ({
  type: 'FINAL_SCORE_FAILED',
  message,
});

export function calcFinalScore(pgId) {
  return (dispatch) => {
    dispatch(requestCalFinalScore());
    return axios.post('/api/user/survey/final', {
      pgId,
    })
      .then((resp) => {
        dispatch(receiveFinalScore(resp.data));
        return resp.data;
      })
      .catch((err) => {
        dispatch(finalScoreFailed(err.message));
      });
  };
}

export const clearSurveyState = () => ({
  type: 'CLEAR_SURVEY_STATE',
});
