import React from 'react';
import PropTypes from 'prop-types';
import { Route, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';

import { getAuthStatus } from '../../reducers';

const RawProtectedRoute = ({ component: Component, isAuthorized, ...rest }) => (
  <Route
    {...rest}
    render={props => ((isAuthorized) ? <Component {...props} /> : <Redirect to="/survey" />)}
  />
);

RawProtectedRoute.propTypes = {
  path: PropTypes.string.isRequired,
  component: PropTypes.func.isRequired,
  isAuthorized: PropTypes.bool.isRequired,
};

const getSurveyIdFromPath = (path) => {
  const seg = path.split('/')[1];
  switch (seg) {
    case 'self-assessment':
      return 'selfAssessment';
    case 'typefinder':
      return 'typeFinder';
    default:
      return seg;
  }
};

const mapStateToProps = (state, ownProps) => ({
  isAuthorized: getAuthStatus(state, getSurveyIdFromPath(ownProps.path)),
});

const ProtectedRoute = connect(mapStateToProps)(RawProtectedRoute);

export default ProtectedRoute;
