import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import _ from 'underscore';

import { getNotifications } from '../../reducers';

import './style.css';

const RawSnackbar = ({ notifications }) => (
  <div id="snackbar" className={!_.isEmpty(notifications) ? 'show' : undefined}>
    {notifications &&
      Object.keys(notifications).map(k => <p key={k}>{notifications[k]}</p>)
    }
  </div>
);

/* eslint-disable react/forbid-prop-types */
RawSnackbar.propTypes = {
  notifications: PropTypes.object.isRequired,
};
/* eslinte-enable */

const mapStateToProps = state => ({ notifications: getNotifications(state) });

const Snackbar = connect(mapStateToProps)(RawSnackbar);

export default Snackbar;
