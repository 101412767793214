// Environment requirements first
import "raf/polyfill";
import "@babel/polyfill";

import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";

import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";

import configureStore from "./state/configureStore";

import App from "./App";
import * as serviceWorker from "./registerServiceWorker";

const store = configureStore();

Sentry.init({
  environment: process.env.NODE_ENV,
  dsn:
    "https://a59887c2448e4d648daad0d1f1c51ee0@o530783.ingest.sentry.io/5656946",
  integrations: [new Integrations.BrowserTracing()],
  tracesSampleRate: 0.05,
});

ReactDOM.render(
  <Provider store={store}>
    <App />
  </Provider>,
  document.getElementById("root")
);

// By default we make sure that no service worker is registered. If you would
// like to enable service worker uncomment the call to serviceWorker.register()
// and comment out the call to serviceWorker.unregister().
// See http://bit.ly/2vJdu84 for more information.

// registerServiceWorker();
serviceWorker.unregister();
