import axios from 'axios';

/* eslint-disable import/prefer-default-export */
const requestFileDownload = () => ({
  type: 'REQUEST_FILE_DOWNLOAD',
});

const receiveFileDownload = () => ({
  type: 'RECEIVE_FILE_DOWNLOAD',
});

const fileDownloadFailed = message => ({
  type: 'FILE_DOWNLOAD_FAILED',
  message,
});

export function downloadFile(pgId) {
  return (dispatch) => {
    const api = `/api/user/report/${pgId}`;
    dispatch(requestFileDownload());
    axios.get(api, {
      headers: {
        accept: 'application/pdf',
      },
      responseType: 'blob',
    })
      .then((response) => {
        dispatch(receiveFileDownload());
        const filename = 'Final Report.pdf';
        const blob = new Blob([response.data], { type: 'application/pdf' });
        if (typeof window.navigator.msSaveBlob !== 'undefined') {
          window.navigator.msSaveBlob(blob, filename);
        } else {
          const url = window.URL.createObjectURL(blob);
          const link = document.createElement('a');
          link.style.display = 'none';
          link.href = url;
          link.setAttribute('download', filename);

          if (typeof link.download === 'undefined') {
            link.setAttribute('target', '_blank');
          }

          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
          window.URL.revokeObjectURL(url);
        }
      })
      .catch((err) => {
        dispatch(fileDownloadFailed(err.message));
      });
  };
}
