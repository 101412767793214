import React from 'react';
import { Grid, Row, Col } from 'react-bootstrap';

import Page from '../components/Page';

const NotFoundPage = () => (
  <Page
    title="Sorry, we are lost"
    description="A page that displays when the user clicks on a link that goes nowhere."
  >
    <Grid style={{ height: '90%' }}>
      <Row componentClass="section">
        <Col sm={12}>
          <h1>Not Found</h1>
        </Col>
      </Row>
    </Grid>
  </Page>
);

export default NotFoundPage;
