import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Alert } from 'react-bootstrap';
import { connect } from 'react-redux';

import { getNotice } from '../../reducers';
import { clearNotice } from '../../actions';

// This class is similar to DismissableAlert except that it retrieves
// its state from Redux
class Notice extends Component {
  constructor(props) {
    super(props);
    this.handleDismiss = this.handleDismiss.bind(this);
  }

  handleDismiss() {
    const { topic, clearNotice } = this.props; // eslint-disable-line no-shadow
    clearNotice(topic);
  }

  render() {
    const { notice, clearNotice, ...rest } = this.props;
    if (notice) {
      return (
        <Alert {...rest} bsStyle={notice.style} onDismiss={this.handleDismiss}>
          {notice.message}
        </Alert>
      );
    }
    return null;
  }
}

Notice.propTypes = {
  topic: PropTypes.string.isRequired,
  notice: PropTypes.shape({
    message: PropTypes.string,
    style: PropTypes.string,
  }),
  clearNotice: PropTypes.func.isRequired,
};

Notice.defaultProps = {
  notice: undefined,
};

const mapStateToProps = (state, ownProps) => ({
  notice: getNotice(state, ownProps.topic),
});

export default connect(mapStateToProps, { clearNotice })(Notice);
