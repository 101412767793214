import { updateItemInArray } from './utils';

const initialState = { notices: [] };

const notice = (noticeState = initialState, action) => {
  switch (action.type) {
    case 'SET_NOTICE':
      if (noticeState.notices.find(n => n.topic === action.topic)) {
        return ({
          notices: updateItemInArray(
            noticeState.notices,
            n => n.topic === action.topic,
            i => Object.assign({}, i, { message: action.message, style: action.style }),
          ),
        });
      }
      return ({
        notices: [
          ...noticeState.notices,
          {
            topic: action.topic,
            message: action.message,
            style: action.style,
          },
        ],
      });
    case 'CLEAR_NOTICE':
      return ({
        notices: noticeState.notices.filter(n => n.topic !== action.topic),
      });
    default:
      return noticeState;
  }
};

export default notice;

export const getNotice = (noticeState, topic) => noticeState.notices.find(n => n.topic === topic);
