import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Grid, Row, Col, Well, Form, FormGroup, FormControl, Button, Alert } from 'react-bootstrap';
import { LinkContainer } from 'react-router-bootstrap';
import { connect } from 'react-redux';

import { fetchUserSummaryState } from '../../actions';
import { getLoadStatus, getPgId } from '../../reducers';

class RawIdEntryDash extends Component {
  constructor(props) {
    super(props);
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.isValid = this.isValid.bind(this);
    this.state = { value: props.pgId || '' };
  }
  handleChange(event) {
    this.setState({ value: event.target.value });
  }
  handleSubmit(event) {
    event.preventDefault();
    if (this.isValid()) {
      this.props.fetchUserSummaryState(this.state.value);
    }
  }
  isValid() {
    return this.state.value.match(/^[0-9A-F]{8}-[0-9A-F]{4}-4[0-9A-F]{3}-[89AB][0-9A-F]{3}-[0-9A-F]{12}$/i)
      ? 'success'
      : 'error';
  }
  render() {
    /* eslint-disable react/jsx-no-target-blank */
    return (
      <div id="dash">
        <Grid>
          <Row>
            <Col sm={12}>
              <Alert>
                <p>
                  <strong>If you are not yet a member of this study,</strong> and you would like to participate, please
                  click &ldquo;Yes I&#39;d Like to Participate&rdquo; below to complete the consent process. You will be
                  returned to this page when that process has been completed.
                </p>
                <LinkContainer to="/consent">
                  <Button bsStyle="primary" className="mt-4">Yes I&#39;d Like to Participate</Button>
                </LinkContainer>
              </Alert>
              <Well>
                <h3>Your Study ID</h3>
                <p>
                  <strong>Please enter your Study ID in order to continue.</strong> We require you to enter a Study
                  ID so that your information remains private.
                </p>
                {this.props.loadStatus === 'failed' &&
                  <Alert bsStyle="danger">
                    <strong>The ID you entered was not found.</strong> Please check the entry and try again.
                  </Alert>
                }
                <Form onSubmit={this.handleSubmit}>
                  <FormGroup
                    controlId="study-id"
                    validationState={this.isValid()}
                  >
                    <FormControl
                      value={this.state.value}
                      onChange={this.handleChange}
                    />
                    <FormControl.Feedback />
                  </FormGroup>
                  <Button bsStyle="primary" type="submit" disabled={this.isValid() !== 'success'}>Submit</Button>
                  {/* <DismissableAlert
                    buttonStyle="link"
                    buttonText="Forgot your Study ID?"
                    buttonTextSize="small"
                    showInitial={false}
                    showButton
                    className="mt-5"
                  >
                    <strong>Forgot your Study ID?</strong> Please sign-in to your{' '}
                    <a href="https://user.gencove.com" target="_blank">Gencove account</a> and click on the
                    Personality Genie App from the Gencove dashboard. If on mobile, connected apps should be found
                    under &ldquo;Manage Apps&rdquo;.
                  </DismissableAlert> */}
                </Form>
              </Well>
            </Col>
          </Row>
        </Grid>
      </div>
    );
    /* eslint-enable */
  }
}

RawIdEntryDash.propTypes = {
  fetchUserSummaryState: PropTypes.func.isRequired,
  pgId: PropTypes.string,
  loadStatus: PropTypes.string.isRequired,
};

RawIdEntryDash.defaultProps = {
  pgId: undefined,
};

const mapStateToProps = state => ({
  loadStatus: getLoadStatus(state),
  pgId: getPgId(state),
});

const IdEntryDash = connect(mapStateToProps, { fetchUserSummaryState })(RawIdEntryDash);

export default IdEntryDash;
