import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Grid, Row, Col, Image, Alert, ButtonToolbar, Button, FormGroup, Well } from 'react-bootstrap';

import { getPgId, getSurvey } from '../reducers';
import { showNotificationWithTimeout, setSurvey, setSurveyStatus } from '../actions';

import Page from '../components/Page';

import logo from '../images/logos/mbti-logo.svg';
import stop from '../images/stop.svg';
import cpp from '../images/cpp-copy-snapshot.png';

class RawMbtiPage extends Component {
  constructor(props) {
    super(props);
    this.launchMbti = this.launchMbti.bind(this);
    this.handleCompletion = this.handleCompletion.bind(this);
    this.copyId = this.copyId.bind(this);
  }
  launchMbti() {
    this.props.setSurveyStatus('mbti', 'started');
    window.open(
      'https://elevate.cpp.com/Respondent/ReturningUser?tokenId=85837b52-e010-454a-be73-a7566d3891c0',
      '_blank',
    );
  }
  handleCompletion() {
    const { pgId, survey } = this.props;
    this.props.setSurvey(
      pgId,
      { ...survey, status: 'completed' },
    );
  }
  copyId() {
    this.inputRef.select();
    if (!document.execCommand('copy')) {
      console.log('Copy to clipboard was not successful'); // keep
    }
  }
  render() {
    return (
      <Page
        title="Myers-Briggs Type Indicator®"
        description="Describes participant actions to access the official MBTI provided by CPP."
      >
        <Grid>
          <Row componentClass="section">
            <Col sm={12} md={8} mdOffset={2}>
              <Image responsive src={logo} style={{ marginLeft: 'auto', marginRight: 'auto', height: '207px' }} />
              <h1 className="text-center mb-4">
                Myers-Briggs Type Indicator® (MBTI)
              </h1>

              <Well className="mt-5">
                You will be required to register on the CPP&#39;s Elevate Platform site in order to complete the MBTI
                for this study. It is important that you carefully follow the instructions below to ensure that we are
                able to make your results available to you once we receive them.{' '}
                <strong>
                  Results of your MBTI survey will be available approximately one week after it is completed.
                </strong>
              </Well>

              <h2>Instructions</h2>

              <Alert className="my-4" bsStyle="warning">
                <p className="lead">
                  Copy and paste the following Study ID email address field into the MBTI
                  registration form as shown below.
                </p>
                <form>
                  <FormGroup className="mb-0">
                    <input
                      type="text"
                      className="pgid form-control"
                      value={`${this.props.pgId}@personalitygenie.com`}
                      readOnly
                      ref={(ref) => { this.inputRef = ref; }}
                    />
                    <ButtonToolbar className="my-3" style={{ display: 'flex', justifyContent: 'center' }}>
                      <Button
                        bsStyle="primary"
                        className="text-center"
                        onClick={this.copyId}
                      >
                        Copy Study ID Email Address
                      </Button>
                    </ButtonToolbar>
                  </FormGroup>
                </form>
                <Image responsive src={cpp} width="80%" style={{ margin: '20px auto', border: '1px solid #555' }} />
              </Alert>
              <ul>
                <li className="text-muted">
                  Enter any information in the remainder of the registration fields. These fields are not used for the
                  study.
                </li>
                <li className="text-muted">Complete the MBTI survey.</li>
                <li className="text-muted">
                  When you have completed the MBTI, please return here to mark the MBTI as completed and continue with
                  the other surveys.
                </li>
              </ul>
              {this.props.survey.status === 'started' &&
                <Alert bsStyle="warning" className="mt-5">
                  <strong>
                    Do not click the &ldquo;MBTI Completed&rdquo; button below until you have completed the MBTI survey.
                  </strong>{' '}
                  You will not be able to access your Study email address once you mark the survey complete.
                  <ButtonToolbar className="mt-2" style={{ display: 'flex', justifyContent: 'center' }}>
                    <Button
                      bsStyle="primary"
                      onClick={this.handleCompletion}
                    >
                      MBTI Completed
                    </Button>
                  </ButtonToolbar>
                </Alert>
              }
              {this.props.survey.status !== 'started' &&
                <Alert bsStyle="warning" className="mt-5">
                  <Image
                    responsive
                    src={stop}
                    style={{
                      marginRight: '20px',
                      height: '100px',
                      float: 'left',
                    }}
                  />
                  <strong>
                    Make sure that you enter the email address shown above into the email address field on the
                    MBTI registration form.
                  </strong>{' '}
                  You will not receive your results if you enter a different email address, and Personality Genie will
                  not be able to use your results for this study.
                  <ButtonToolbar className="mt-3" style={{ display: 'flex', justifyContent: 'center' }}>
                    <Button
                      bsStyle="primary"
                      onClick={this.launchMbti}
                    >
                      I am Ready
                    </Button>
                  </ButtonToolbar>
                </Alert>
              }
            </Col>
          </Row>
        </Grid>
      </Page>
    );
  }
}

RawMbtiPage.propTypes = {
  pgId: PropTypes.string.isRequired,
  survey: PropTypes.any.isRequired, // eslint-disable-line react/forbid-prop-types
  setSurvey: PropTypes.func.isRequired,
  setSurveyStatus: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  pgId: getPgId(state),
  survey: getSurvey(state, 'mbti'),
});

const MbtiPage = connect(
  mapStateToProps,
  { showNotificationWithTimeout, setSurvey, setSurveyStatus },
)(RawMbtiPage);

export default MbtiPage;
