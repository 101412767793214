/* eslint-disable react/no-danger, react/prefer-stateless-function, class-methods-use-this */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { ListGroupItem, Button } from 'react-bootstrap';

import typeSummary from './type-summary';
import FbShare from '../FbShare';

class FinalScoreSegment extends Component {
  constructor(props) {
    super(props);
    this.download = this.download.bind(this);
  }
  download() {
    window.location.href =
      `${window.location.protocol}//${window.location.host}/api/user/report/${this.props.pgId}`;
  }
  render() {
    const { finalScore, nextSurvey } = this.props;
    const inconclusive = finalScore && finalScore === 'Inconclusive';

    if (nextSurvey) {
      return null;
    }

    return (
      <ListGroupItem
        bsStyle="success"
      >
        <h3>Final Score</h3>
        <div>
          <p className="clearfix">
            <strong>Score:</strong>{' '}
            <strong className="pull-right text-right">
              {finalScore || 'Pending'}
            </strong>
          </p>

          {finalScore && finalScore !== 'pending' ?
            (
              <div style={{ clear: 'both' }}>
                {!inconclusive &&
                  <h4>What is an {finalScore}?</h4>
                }
                <div
                  style={{ clear: 'both' }}
                  dangerouslySetInnerHTML={{ __html: typeSummary[finalScore] }}
                />
                {!inconclusive &&
                  <div>
                    <p>
                      <small>
                        For more information see:{' '}
                        <a href={`https://www.truity.com/personality-type/${finalScore}`} rel="noopener noreferrer" target="_blank">
                          {`https://www.truity.com/personality-type/${finalScore}`}
                        </a>.
                      </small>
                    </p>
                    <div className="text-right">
                      <FbShare code={finalScore} />
                      <Button
                        bsStyle="link"
                        style={{ padding: 0, paddingLeft: '10px' }}
                        onClick={this.download}
                      >
                        {finalScore} report
                      </Button>
                    </div>
                  </div>
                }
              </div>
            ) : (
              <div style={{ clear: 'both' }}>
                <small>
                  Once all your results are in, and{' '}
                  <strong>
                    your data has been successfully uploaded,
                  </strong>{' '}
                  you will see your final score and links to additional resources here.
                </small>
              </div>
            )
          }
        </div>
      </ListGroupItem>
    );
  }
}

FinalScoreSegment.propTypes = {
  pgId: PropTypes.string.isRequired,
  finalScore: PropTypes.string,
  nextSurvey: PropTypes.string,
};

FinalScoreSegment.defaultProps = {
  finalScore: undefined,
  nextSurvey: null,
};

export default FinalScoreSegment;
