import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Grid, Row, Col, Well, FormGroup, Radio, Button } from 'react-bootstrap';

import { getPgId, getSurvey } from '../../reducers';
import { fetchUserNardiScores, setNardiFP, updateSurvey } from '../../actions';
import Page from '../../components/Page';

const functionalPairs = [
  {
    code: 'SFP',
    title: 'The Experiential Improviser',
    description:
    'Flows with a sense of the pace, rhythm, and process, with quick and easy responses to situations. Is '
    + 'a hands-on, experiential learner. Often has a personal style. Is good at asking questions, offering '
    + 'suggestions, presenting ideas, creating products, and making helpful comments. Notices and gets in synch with '
    + 'nonverbal information and shows spontaneous and genuine expression. Is in touch with sound, color, texture, and '
    + 'motion and able to compose by quickly pulling things together. Is often seen as entertaining. Trusts impulses. '
    + 'Enjoys the simple pleasures. Is good at recruiting loyalty and support.',
  },
  {
    code: 'STP',
    title: 'The Practical Expeditor',
    description: 'Gets a strong sense of functionality with minimum effort. Easily maneuvers around rules and '
    + 'obstacles or uses them to one’s own advantage. Has a mind like a camera with knowledge and understanding of the '
    + 'surrounding environment. Likes things to just fall together and start working. IS a natural with tools, whether '
    + 'literal tools or applying abstract techniques, often catching on quickly. Enjoys demonstrations of generosity. '
    + 'Is skilled at useful problem solving, trying out various angles, and blending and adapting techniques to fit a '
    + 'situation. Looks for and uses leverage points to manage.',
  },
  {
    code: 'STJ',
    title: 'The Managerial Organizer',
    description: 'Has strong organizational skills. Thinks in terms of steps, rules, and roles to follow to meet '
    + 'goals. Trusts measurement and standard operating procedures. Uses figures and numbers, makes charts and lists, '
    + 'and is good at arranging tasks or ideas in a sequential manner. Plans and checks off steps to be responsible. '
    + 'Feels a sense of loyalty. Has an eye for order, overseeing and supervising, directing others and being '
    + 'directed. Can painstakingly work through problems to assure correctness. Banks on hindsight, history, and '
    + 'wealth of experience. Uses analogies to solve novel problems.',
  },
  {
    code: 'SFJ',
    title: 'The Conscientious Facilitator',
    description: 'Is sociable and attentive to cultural history and traditions. Helps make it easier for people to '
    + 'work together. Wants a nice, safe, secure, and controlled environment to relax and enjoy life. Often has '
    + 'fine-tuned discriminating senses. Is good at making comparisons between current situations and past '
    + 'experiences. Enjoys membership in the family or group, attending to the character of their relationships. Is a '
    + 'fast learner and thorough at many social tasks. Is the natural host or hostess, easily communicating honesty, '
    + 'support, and suggestions. Needs and provides comfort and convenience.',
  },
  {
    code: 'NTJ',
    title: 'The Visionary Coordinator',
    description: 'Is conclusion, concept, and vision driven. Is continually automatically re-planning for mastery, '
    + 'understanding, and control, accounting for numerous factors at once. Trusts logic and empirical reasoning. '
    + 'Quickly reorganizes and implements, looking for the future consequences of actions and the logical conclusions '
    + 'of an idea. Has a drive to know what is innate or true for real in a scientific sense. Can think up plausible '
    + 'explanations for almost anything. Sees many levels and the percent chance of outcomes. Applies disparate ideas '
    + 'using abstract models. Pushes for progress and achievement in all potential forms.',
  },
  {
    code: 'NTP',
    title: 'The Inquisitive Engineer',
    description: 'Uses intellect with elegance. Is information, design, and process oriented. Applies abstract '
    + 'criteria to fit situations into categories. Has a zest for experimenting, hypothesizing, inventing, and '
    + 'exploring all aspects of life. Is comfortable with abstraction and interrelated threads of ideas. Enjoys seeing '
    + 'and developing strategies and patterns as they vary and change over time. Derives one idea from another, '
    + 'considers multiple factors, notices errors, and connects inconsistencies distant in space and time. Looks for '
    + 'leverage points. Is a natural at extemporaneous problem solving.',
  },
  {
    code: 'NFJ',
    title: 'The Intuitive Guide',
    description: 'Makes the ideal a goal. Is a master of articulating personal values through group and one-on-one '
    + 'communication. Listens for emotions and symbolic content, critiques for value, and relates preferences and '
    + 'relationships. Is sensitive to complex interpersonal connections, ethical boundaries, and the logic of one’s '
    + 'values. Trusts foresight. Often is aware of a collective future or envisions a path for growth. Projects and '
    + 'introjects—needs to know the future impact of decisions on people. Has ideals that are directed to reconciling, '
    + 'connecting, and developing people, their creative potential, and ideas.',
  },
  {
    code: 'NFP',
    title: 'The Inspirational Mediator',
    description: 'Is perceptive of intonation, emotion, and the multiple meanings and implications behind words, '
    + 'especially what is not said. Enjoys the magical moment. Respond to a rich and dynamic, interesting, and '
    + 'inspiring voice. Often picks up on and mirrors others\' behavior. Imagines, weaves, and relates stories. '
    + 'Understands through metaphor and creates by combining the threads of various possibilities. Wants harmony in '
    + 'values and meaningful serendipity and surprise. Has a sense of pathos and irony. Notices incongruity, identity, '
    + 'and ethical contradiction. Often ends up acting as a catalyst.',
  },
];

// if (!pgId ||
//     gencoveConsent !== true ||
//     dataReady !== true ||
//     !isPart(survey, 'part-one')) {
//   return <Redirect to="/survey" />;
// }
//

class RawNardiPartThreeRunner extends Component {
  constructor(props) {
    super(props);
    this.handleChange = this.handleChange.bind(this);
    this.nextDisabled = this.nextDisabled.bind(this);
    this.next = this.next.bind(this);
  }

  handleChange(event) {
    const { target } = event;
    const { id } = target;
    this.props.setNardiFP(id);
  }

  nextDisabled() {
    return this.props.survey.selectedFP === undefined;
  }

  next() {
    const {
      survey: { selectedFP }, pgId,
    } = this.props;
    this.props.updateSurvey(
      pgId,
      'nardi',
      { $set: { 'nardi.selectedFP': selectedFP, 'nardi.status': 'part-three' } },
      '/nardi/part-four',
    );
  }

  render() {
    const codes = this.props.survey.functionalPairs.map(f => f.code);
    const toDisplay = functionalPairs.filter(fp => codes.indexOf(fp.code) !== -1);
    if (toDisplay.length !== 2) {
      throw Error('Not able to locate two functional pairs');
    }
    return (
      <Page
        title="Nardi Personality Indicator"
        description={'A self-discovery questionnaire that helps determine your best-fit type, '
        + 'created by Dr. Dario Nardi.'}
      >
        <Grid>
          <Row componentClass="section">
            <Col sm={12}>
              <div className="survey-question">
                <h1 className="my-5 text-center">Nardi Personality Indicator</h1>
                <h2>Finding a Best-Fit: Part II</h2>
                <p className="lead text-muted">
                  Please read the two personality snapshots below and select one as a best fit. Consider each
                  &ldquo;as a whole.&rdquo; That is, try on both and evaluate them in terms of overall comfort and
                  accuracy. Then indicate which one fits you the best.
                </p>
                <Well bsSize="large">
                  <div className="survey-options">
                    <FormGroup controlId="fpChoice" >
                      {
                        toDisplay.map(({ code, title, description }) => (
                          <Radio
                            key={code}
                            name="answer"
                            id={code}
                            onClick={this.handleChange}
                          >
                            <h4>{title}</h4>
                            <p className="text-muted">{description}</p>
                          </Radio>
                        ))
                      }
                    </FormGroup>
                  </div>
                </Well>
                <Button
                  bsStyle="primary"
                  disabled={this.nextDisabled()}
                  onClick={this.next}
                >
                  Next
                </Button>
              </div>
            </Col>
          </Row>
          <Row>
            <Col sm={12}>
              <p className="copy text-muted">
                Temperament and type snapshots: Copyright &copy; Linda Berens, 1995, 1998, and Radiance House
              </p>
            </Col>
          </Row>
        </Grid>
      </Page>
    );
  }
}

RawNardiPartThreeRunner.propTypes = {
  pgId: PropTypes.string.isRequired,
  setNardiFP: PropTypes.func.isRequired,
  updateSurvey: PropTypes.func.isRequired,
  survey: PropTypes.shape({
    selectedFP: PropTypes.string,
    functionalPairs: PropTypes.arrayOf(PropTypes.shape({
      code: PropTypes.string,
      score: PropTypes.number,
    })),
  }),
};

RawNardiPartThreeRunner.defaultProps = {
  survey: undefined,
};

const mapStateToProps = state => ({
  pgId: getPgId(state),
  survey: getSurvey(state, 'nardi'),
});

const NardiPartThreeRunner = connect(
  mapStateToProps,
  { fetchUserNardiScores, setNardiFP, updateSurvey },
)(RawNardiPartThreeRunner);

export default NardiPartThreeRunner;
