import React from 'react';
import { Grid, Row, Col } from 'react-bootstrap';

const LoadingDash = () => (
  <div id="dash">
    <Grid className="pg-dash loading">
      <Row componentClass="section">
        <Col sm={12}>
          <h1 className="text-center">Loading...</h1>
          <p className="text-center"><i className="fa fa-spinner fa-spin fa-3x fa-fw" /></p>
        </Col>
      </Row>
    </Grid>
  </div>
);

export default LoadingDash;
