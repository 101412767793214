import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { Grid, Row, Col, ButtonToolbar, Button, Well, OverlayTrigger, Tooltip } from 'react-bootstrap';

import { getPgId, getSurvey } from '../../reducers';

import MultipleChoice from './MultipleChoice';
import SingleChoice from './SingleChoice';
import TextField from './TextField';
import Review from './Review';
import { setSurvey, updateCurrentQuestion } from '../../actions';

import './style.css';

const filterQuestion = (survey, question, answers) => {
  if (question && question.filter) {
    return ({
      ...question,
      options: question.filter(answers, question.options),
    });
  }
  return question;
};

const makeTooltip = text => (<Tooltip id="tooltip">{text}</Tooltip>);

class RawSurveyRunner extends Component {
  constructor(props) {
    super(props);
    this.prev = this.prev.bind(this);
    this.next = this.next.bind(this);
    this.prevDisabled = this.prevDisabled.bind(this);
    this.nextDisabled = this.nextDisabled.bind(this);
    this.questionValid = this.questionValid.bind(this);
    this.surveyValid = this.surveyValid.bind(this);
    this.state = {
      // current: (this.props.survey.status === 'reviewing' && this.props.questions) ? this.props.questions.length : 0,
      questionValid: false,
    };
  }

  prev() {
    const { index } = this.props.survey;
    const { surveyId } = this.props;
    if (index > 0) {
      this.setState({ questionValid: false });
      this.props.updateCurrentQuestion(surveyId, index - 1, 'pending');
    }
  }

  prevDisabled() {
    return this.props.survey.index === 0;
  }

  next() {
    const {
      survey, pgId, surveyId, questions, nextPath, nextStatus, onComplete, beforeSave,
    } = this.props;
    const { index } = survey;
    if (survey.status === 'reviewing') {
      if (this.surveyValid()) {
        this.props.setSurvey(
          pgId,
          { ...survey, status: nextStatus || 'completed' },
          nextPath,
          onComplete,
          beforeSave,
        );
      }
    } else if (index < questions.length) {
      if (this.state.questionValid) {
        this.setState({ questionValid: false });
        this.props.updateCurrentQuestion(
          surveyId,
          index + 1,
          (index === questions.length - 1) ? 'reviewing' : 'pending',
        );
      }
    }
  }

  nextDisabled() {
    const { questions, survey: { index } } = this.props;
    const { questionValid } = this.state;

    return (index <= questions.length - 1 && !questionValid) || (index === questions.length && !this.surveyValid());
  }

  questionValid(valid) {
    this.setState({ questionValid: valid });
  }

  surveyValid() {
    const { questions, survey } = this.props;
    if (!survey || !survey.answers) {
      return false;
    }
    if (survey.answers.length !== questions.length) {
      return false;
    }
    for (let i = 0; i < questions.length; i += 1) {
      const { id, validator } = questions[i];
      const answer = survey.answers.find(item => item.id === id);
      if (!answer) {
        return false;
      }
      if (validator && !validator(answer)) {
        return false;
      }
    }
    return true;
  }

  render() {
    const {
      title, subtitle, questions, surveyId, survey,
    } = this.props;
    const { status, index = 0 } = survey;
    // Prevent an unnecessary render when the page is about to change following a save...
    if (['pending', 'reviewing', 'saving'].indexOf(status) === -1) {
      return <div />;
    }

    let element = null;
    let nextTooltip = null;
    const prevTooltip = makeTooltip('Review previous question');
    if (status === 'reviewing') {
      element = (<Review title={title} questions={questions} survey={survey} />);
      nextTooltip = makeTooltip('Click Next after reviewing');
    } else if (status === 'saving') {
      element = (
        <div id="dash">
          <Grid className="pg-dash loading">
            <Row componentClass="section">
              <Col sm={12}>
                <h1 className="text-center">Saving...</h1>
                <p className="text-center"><i className="fa fa-spinner fa-spin fa-3x fa-fw" /></p>
              </Col>
            </Row>
          </Grid>
        </div>
      );
    } else {
      // Check the index to ensure that it is within bounds
      // const currentIndex = checkIndex(index, questions.length - 1);
      const q = filterQuestion(survey, questions[index], survey.answers);
      let question = null;
      switch (q.questionType) {
        case 'single':
          question = (
            <SingleChoice surveyId={surveyId} question={q} valid={this.questionValid} onSelect={this.next} />
          );
          nextTooltip = makeTooltip('Must make a selection');
          break;
        case 'multi':
          question = (<MultipleChoice surveyId={surveyId} question={q} valid={this.questionValid} />);
          nextTooltip = makeTooltip('Must make a selection');
          break;
        case 'text':
          question = (<TextField surveyId={surveyId} question={q} valid={this.questionValid} />);
          nextTooltip = makeTooltip('Must enter a valid answer');
          break;
        default:
          throw Error('Unknown question type in ', q);
      }
      element = (
        <div>
          <p>You are on question {index + 1} of {questions.length}.</p>
          <Well bsSize="large">
            <h4 className="question">{q.text}</h4>
            <div className="survey-options">
              {question}
            </div>
          </Well>
        </div>
      );
    }

    return (
      <Grid>
        <Row componentClass="section">
          <Col sm={12}>
            <div className="survey-question">
              <h1 className="my-5 text-center">{title}</h1>
              {subtitle && <h2>{subtitle}</h2>}
              {element}
              <ButtonToolbar>
                {this.prevDisabled()
                  ? (
                    <Button
                      bsStyle="primary"
                      disabled={this.prevDisabled()}
                      onClick={this.prev}
                    >
                      Previous
                    </Button>
                  ) : (
                    <OverlayTrigger placement="top" overlay={prevTooltip}>
                      <Button
                        bsStyle="primary"
                        disabled={this.prevDisabled()}
                        onClick={this.prev}
                      >
                        Previous
                      </Button>
                    </OverlayTrigger>
                  )
                }
                <OverlayTrigger placement="top" overlay={nextTooltip}>
                  <Button
                    bsStyle="primary"
                    disabled={this.nextDisabled()}
                    onClick={this.next}
                  >
                    {status === 'reviewing' ? 'Save' : 'Next'}
                  </Button>
                </OverlayTrigger>
              </ButtonToolbar>
            </div>
          </Col>
        </Row>
      </Grid>
    );
  }
}

RawSurveyRunner.propTypes = {
  surveyId: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string,
  nextPath: PropTypes.string,
  nextStatus: PropTypes.string,
  questions: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string,
    text: PropTypes.string,
    validator: PropTypes.func,
    options: PropTypes.arrayOf(PropTypes.shape({
      key: PropTypes.string,
      prompt: PropTypes.string,
      extValue: PropTypes.bool,
    })),
  })).isRequired,
  pgId: PropTypes.string.isRequired,
  onComplete: PropTypes.func,
  beforeSave: PropTypes.func,
  setSurvey: PropTypes.func.isRequired,
  updateCurrentQuestion: PropTypes.func.isRequired,
  survey: PropTypes.any.isRequired, // eslint-disable-line react/forbid-prop-types
};

RawSurveyRunner.defaultProps = {
  subtitle: undefined,
  nextPath: undefined,
  nextStatus: undefined,
  onComplete: () => true,
  beforeSave: survey => survey,
};

const mapStateToProps = (state, ownProps) => ({
  pgId: getPgId(state),
  survey: getSurvey(state, ownProps.surveyId),
});

const SurveyRunner = withRouter(connect(
  mapStateToProps,
  {
    setSurvey,
    updateCurrentQuestion,
  },
)(RawSurveyRunner));

export default SurveyRunner;
