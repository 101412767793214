import React from 'react';
import { Grid, Row, Col } from 'react-bootstrap';

const LoadingFailedDash = loadMessage => (
  <div id="dash">
    <Grid className="pg-dash failed">
      <Row>
        <Col sm={12}>
          <h1>Loading failed</h1>
          <p>
            Unfortunately we were not able to load your information at this time. Our system is reporting the
            following message:
          </p>
          <p>{loadMessage.message}</p>
        </Col>
      </Row>
    </Grid>
  </div>
);

export default LoadingFailedDash;
