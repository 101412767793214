import { combineReducers } from 'redux';
import _ from 'underscore';

import { updateItemInArray, updateObject } from './utils';

const updateMultiValue = (oldValue = [], value, extValue, uncheck) => {
  const filtered = oldValue.filter(item => (item !== value && item.value !== value));
  if (uncheck) {
    return filtered;
  }
  if (extValue) {
    return filtered.concat({ value, extValue });
  }
  return filtered.concat(value);
};

const updateAnswer = (item, action) => {
  const {
    questionType, questionId, value, extValue, uncheck,
  } = action;
  const { extValue: ev, ...oldValue } = item || {}; // chuck the extended value, it will get added back if approparite
  switch (questionType) {
    case 'text':
    case 'single':
      return updateObject(_.omit(oldValue, 'extValue'), {
        id: questionId,
        value,
        ...extValue && { extValue },
      });
    case 'multi':
      return updateObject(item, {
        id: questionId,
        value: updateMultiValue((item || []).value, value, extValue, uncheck),
      });
    default:
      throw Error(`Unknown question type: ${questionType}`);
  }
};

const logAnswer = (answers = [], action) => {
  const { questionId } = action;
  const result = updateItemInArray(
    answers,
    item => item.id === questionId,
    item => updateAnswer(item, action),
  )
    .filter(item => (item.value !== undefined && item.value.length !== 0)); // remove undefined answers
  return result;
};

const selfAssessment = (selfAssessmentState = { index: 0, id: 'selfAssessment', status: 'pending' }, action) => {
  switch (action.type) {
    case 'LOG_ANSWER':
      if (action.surveyId === 'selfAssessment') {
        return updateObject(selfAssessmentState, { answers: logAnswer(selfAssessmentState.answers, action) });
      }
      return selfAssessmentState;
    case 'RECEIVE_USER_STATE': {
      const update = action.selfAssessment || (action.survey && action.survey.find(s => s.id === 'selfAssessment'));
      if (update) {
        update.status = update.status === 'in-progress' ? 'pending' : update.status;
        if (update.status === 'completed') {
          const { typeCode } = update;
          return {
            index: 0,
            id: 'selfAssessment',
            status: 'completed',
            ...typeCode && { typeCode },
          };
        }
        return { index: 0, ...updateObject(selfAssessmentState, update) }; // restore missing indices;
      }
      return { index: 0, ...selfAssessmentState }; // restore missing indices
    }
    case 'SET_SURVEY_STATUS':
      if (action.surveyId === 'selfAssessment') {
        return updateObject(selfAssessmentState, { status: action.status });
      }
      return selfAssessmentState;
    case 'UPDATE_CURRENT_QUESTION':
      if (action.surveyId === 'selfAssessment') {
        return updateObject(selfAssessmentState, { index: action.index, status: action.status });
      }
      return selfAssessmentState;
    case 'CONFIRM_REJECT_SURVEY':
      return updateObject(selfAssessmentState, { status: 'rejected' });
    case 'CLEAR_SURVEY_STATE':
      return selfAssessment(undefined, {});
    default:
      return selfAssessmentState;
  }
};

const nardi = (nardiState = { index: 0, id: 'nardi', status: 'pending' }, action) => {
  switch (action.type) {
    case 'LOG_ANSWER':
      if (action.surveyId === 'nardi') {
        return updateObject(nardiState, { answers: logAnswer(nardiState.answers, action) });
      }
      return nardiState;
    case 'RECEIVE_NARDI_SCORES':
      return updateObject(nardiState, {
        typeCodes: action.typeCodes,
        functionalPairs: action.functionalPairs,
        temperaments: action.temperaments,
      });
    case 'RECEIVE_NARDI_FINAL_SCORES':
      return updateObject(nardiState, {
        typeCode: action.typeCode,
        consistency: action.consistency,
      });
    case 'RECEIVE_NARDI_REPORT_DATA':
      return updateObject(nardiState, action.reportData);
    case 'RECEIVE_USER_STATE': {
      const update = action.nardi || (action.survey && action.survey.find(s => s.id === 'nardi'));
      if (update) {
        update.status = update.status === 'in-progress' ? 'pending' : update.status;
        if (update.status === 'completed') {
          const { typeCode, consistency } = update;
          return {
            index: 0,
            id: 'nardi',
            status: 'completed',
            ...typeCode && { typeCode },
            ...consistency && { consistency },
          };
        }
        return { index: 0, ...updateObject(nardiState, update) }; // restore missing indices;
      }
      return { index: 0, ...nardiState }; // restore missing indices
    }
    case 'SET_NARDI_FP':
      return updateObject(nardiState, { selectedFP: action.code });
    case 'SET_NARDI_TEMPERAMENT':
      return updateObject(nardiState, { selectedTemperament: action.code });
    case 'SET_NARDI_TYPECODE':
      return updateObject(nardiState, { selectedTypecode: action.code });
    case 'SET_SURVEY_STATUS':
      if (action.surveyId === 'nardi') {
        return updateObject(nardiState, { status: action.status });
      }
      return nardiState;
    case 'UPDATE_CURRENT_QUESTION':
      if (action.surveyId === 'nardi') {
        return updateObject(nardiState, { index: action.index, status: action.status });
      }
      return nardiState;
    case 'CONFIRM_REJECT_SURVEY':
      return updateObject(nardiState, { status: 'rejected' });
    case 'CLEAR_SURVEY_STATE':
      return nardi(undefined, {});
    default:
      return nardiState;
  }
};

const typeFinder = (typeFinderState = { id: 'typeFinder', status: 'pending' }, action) => {
  switch (action.type) {
    case 'RECEIVE_USER_STATE': {
      const update = action.typeFinder || (action.survey && action.survey.find(s => s.id === 'typeFinder'));
      if (update.status === 'completed') {
        const { typeCode } = update;
        return {
          index: 0,
          id: 'typeFinder',
          status: 'completed',
          ...typeCode && { typeCode },
        };
      }
      return { index: 0, ...updateObject(typeFinderState, update) }; // restore missing indices;
    }
    case 'RECEIVE_UPDATE_TYPEFINDER_RESULT':
      return updateObject(typeFinderState, { status: 'completed', typeCode: action.typeCode });
    case 'CLEAR_SURVEY_STATUS':
      return typeFinder(undefined, {});
    default:
      return typeFinderState;
  }
}

// const mbti = (mbtiState = { index: 0, id: 'mbti', status: 'pending' }, action) => {
//   switch (action.type) {
//     case 'RECEIVE_USER_STATE': {
//       const update = action.mbti || (action.survey && action.survey.find(s => s.id === 'mbti'));
//       if (update) {
//         update.status = update.status === 'in-progress' ? 'pending' : update.status;
//         if (update.status === 'completed') {
//           const { typeCode } = update;
//           return {
//             index: 0,
//             id: 'mbti',
//             status: 'completed',
//             ...typeCode && { typeCode },
//           };
//         }
//         return { index: 0, ...updateObject(mbtiState, update) }; // restore missing indices;
//       }
//       return { index: 0, ...mbtiState }; // restore missing indices
//     }
//     case 'SET_SURVEY_STATUS':
//       if (action.surveyId === 'mbti') {
//         return updateObject(mbtiState, { status: action.status });
//       }
//       return mbtiState;
//     case 'CLEAR_SURVEY_STATE':
//       return mbti(undefined, {});
//     default:
//       return mbtiState;
//   }
// };

const finalScore = (finalScoreState = {}, action) => {
  switch (action.type) {
    case 'RECEIVE_FINAL_SCORE':
      return { typeCode: action.finalScore };
    case 'RECEIVE_USER_STATE': {
      const typeCode = action.finalScore;
      return { ...typeCode && { typeCode } };
    }
    case 'CLEAR_SURVEY_STATE':
      return finalScore(undefined, {});
    default:
      return finalScoreState;
  }
};

const surveys = combineReducers({
  selfAssessment,
  nardi,
  // mbti,
  typeFinder,
  finalScore,
});

export default surveys;

export const getAllDone = (surveyState) => {
  const {
    selfAssessment: sa, nardi: n, /* mbti: m, */ typeFinder: t,
  } = surveyState;
  return (
    (sa.status === 'completed' || sa.status === 'rejected')
    && (n.status === 'completed' || n.status === 'rejected')
    // && m.status === 'completed'
    && t.status === 'completed'
  );
};

export const getSurvey = (surveyState, surveyId) => surveyState[surveyId] || {};

export const getSurveys = surveyState => [
  surveyState.selfAssessment, surveyState.nardi, surveyState.mbti, surveyState.typeFinder,
];

export const getSurveyAnswer = (surveyState, surveyId, questionId) => {
  const survey = getSurvey(surveyState, surveyId);
  const { answers = [] } = survey;
  return answers.find(q => q.id === questionId) || {};
};

// The survey score is viewable if the survey has been completed AND the typeCode has been calculated.
// NOTE This is one of two branches that must be calculated to determine viewability. The other depends on access.
export const getSurveyScoreViewable = (surveyState, surveyId) => {
  const survey = getSurvey(surveyState, surveyId);
  const { status } = survey;
  return (status === 'completed');
};

export const getNextSurvey = (surveyState) => {
  const {
    selfAssessment: selfAssessmentSurvey, nardi: nardiSurvey, /* mbti: mbtiSurvey, */ typeFinder: typeFinderSurvey,
  } = surveyState;

  // if (mbtiSurvey.status !== 'completed') {
  //   return 'mbti';
  // }
  if (typeFinderSurvey.status !== 'completed') {
    return 'typeFinder';
  }
  if (nardiSurvey.status !== 'completed' && nardiSurvey.status !== 'rejected') {
    return 'nardi';
  }
  if (selfAssessmentSurvey.status !== 'completed' && selfAssessmentSurvey.status !== 'rejected') {
    return 'selfAssessment';
  }
  return null; // There is no next survey
};

// Is the user allowed to view the supplied survey? They are if it is the next survey in line.
export const getAuthStatus = (surveyState, surveyId) =>
  getNextSurvey(surveyState) === surveyId;

// FINAL SCORE CALCULATION

export const getFinalScore = surveyState => surveyState.finalScore.typeCode;
