import {
  createStore as reduxCreateStore,
  compose,
  applyMiddleware,
} from 'redux';

// Simple wrapper to enable the React DevTools extensions for debugging.
/* eslint-disable no-underscore-dangle */
const composeEnhancers = (typeof window !== 'undefined' && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) || compose;

const createStore = (
  reducer,
  initialState,
  enhancers,
) => reduxCreateStore(
  reducer,
  initialState,
  composeEnhancers(applyMiddleware(...enhancers)),
);
/* eslint-enable */

export default createStore;
