import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router';
import { animateScroll as scroll } from 'react-scroll';

class HashScroller extends Component {
  componentDidMount() {
    this.scroll();
  }

  componentDidUpdate() {
    this.scroll();
  }

  scroll() {
    const { location: { hash }, offset } = this.props;
    if (hash && hash.length > 0) {
      const el = document.getElementById(hash.substring(1));
      if (el) {
        const rect = el.getBoundingClientRect();
        setTimeout(() => {
          scroll.scrollTo(rect.top + window.pageYOffset + -offset);
        }, 100);
      }
    }
  }

  render() {
    return <div>{this.props.children}</div>;
  }
}

HashScroller.propTypes = {
  children: PropTypes.node.isRequired,
  location: PropTypes.shape({
    hash: PropTypes.string,
  }).isRequired,
  offset: PropTypes.number,
};

HashScroller.defaultProps = {
  offset: 0,
};

export default withRouter(HashScroller);
