import throttle from 'lodash/throttle';
import thunk from 'redux-thunk';

import createStore from './createStore'; // Must use the wrapper function instead of Redux directly

import pgApp from '../reducers';
import { loadState, saveState } from './localStorage';

/* eslint-disable no-console */
const addLoggingToDispatch = (store) => {
  const rawDispatch = store.dispatch;
  if (!console.group) {
    return rawDispatch;
  }

  return (action) => {
    console.groupCollapsed(`Action: ${action.type}`);
    console.log('%c previous state', 'color: gray', store.getState()); // Keep
    console.log('%c action', 'color: blue', action); // Keep
    const result = rawDispatch(action);
    console.log('%c next state', 'color: green', store.getState()); // Keep
    console.groupEnd(action.type);
    return result;
  };
};
/* eslint-enable */

const configureStore = () => {
  const persistedState = {
    ...loadState(),
    notifications: {},
  };

  const store = createStore(
    pgApp,
    persistedState,
    [thunk],
  );

  if (process.env.NODE_ENV !== 'production') {
    store.dispatch = addLoggingToDispatch(store);
  }

  store.subscribe(throttle(() => {
    saveState(store.getState());
  }, 1000));

  return store;
};

export default configureStore;
