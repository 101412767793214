import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { FormGroup, Radio } from 'react-bootstrap';
import { connect } from 'react-redux';

import { getSurveyAnswer } from '../../reducers';

import { logAnswer } from '../../actions';
import FieldGroup from '../FieldGroup';
import { isValidSingle } from './validators';

class RawSingleChoice extends Component {
  constructor(props) {
    super(props);
    const { question, surveyId } = props;
    if (!question) {
      throw Error('Must specify a question');
    }
    if (!surveyId) {
      throw Error('Must specify a surveyId');
    }
    this.handleChange = this.handleChange.bind(this);
    this.getValidationState = this.getValidationState.bind(this);
    this.isChecked = this.isChecked.bind(this);
    this.validate = this.validate.bind(this);
    this.getExtValue = this.getExtValue.bind(this);
  }

  componentDidMount() {
    const { question, answer } = this.props;
    this.validate(question, answer);
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.answer !== nextProps.answer) {
      const { question, answer } = nextProps;
      this.validate(question, answer);
    }
  }

  getValidationState() {
    const { question, answer } = this.props;
    return isValidSingle(question, answer) ? 'success' : 'error';
  }

  getExtValue(key) {
    const val = this.props.answer.find(o => o.value === key);
    return val ? val.extValue : '';
  }

  handleChange(event) {
    const { target } = event;
    const { value, id } = target;
    const { surveyId, question, onSelect } = this.props;
    if (id.endsWith('-val')) {
      // We are looking at a change to the extended value of a field
      // Retrieve the associated key that we stored in the id attribute
      const key = id.split('-')[0];
      this.props.logAnswer(surveyId, 'single', question.id, key, value);
    } else {
      this.props.logAnswer(surveyId, 'single', question.id, id);
    }
    if (onSelect) {
      // Delay the call to SurveyRunner.next to allow UI to update first
      setTimeout(() => { onSelect(); }, 250);
    }
  }

  validate(question, value) {
    const { valid } = this.props;
    if (valid) {
      valid(isValidSingle(question, value));
    }
  }

  isChecked(value) {
    return this.props.answer === value || this.props.answer.value === value; // Could have an extended answer;
  }

  render() {
    const { question } = this.props;
    // const { id, text } = question;
    return (
      <FormGroup
        controlId={question.id}
        validationState={this.getValidationState()}
      >
        {
          question.options.map(({ key, prompt, extValue }) => (
            <Radio
              key={key}
              name="answer"
              id={key}
              checked={this.isChecked(key)}
              onClick={this.handleChange}
            >
              {prompt}
              {(extValue && this.isChecked(key)) &&
                <FieldGroup
                  id={`${key}-val`}
                  label="Please explain"
                  type="text"
                  name={`${key}-val`}
                  onChange={this.handleChange}
                  value={this.getExtValue(key)}
                />
              }
            </Radio>
          ))
        }
      </FormGroup>
    );
  }
}

RawSingleChoice.propTypes = {
  answer: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.shape({
      value: PropTypes.string,
      extValue: PropTypes.string,
    }),
  ]).isRequired,
  valid: PropTypes.func,
  surveyId: PropTypes.string.isRequired,
  logAnswer: PropTypes.func.isRequired,
  question: PropTypes.shape({
    id: PropTypes.string,
    text: PropTypes.string,
    options: PropTypes.arrayOf(PropTypes.shape({
      key: PropTypes.string,
      prompt: PropTypes.string,
      extValue: PropTypes.bool,
    })),
  }).isRequired,
  onSelect: PropTypes.func,
};

RawSingleChoice.defaultProps = {
  valid: undefined,
  onSelect: undefined,
};

const mapStateToProps = (state, ownProps) => ({
  answer: getSurveyAnswer(state, ownProps.surveyId, ownProps.question.id),
});

const mapDispatchToProps = {
  logAnswer,
};

const SingleChoice = connect(
  mapStateToProps,
  mapDispatchToProps,
  // mergeProps,
)(RawSingleChoice);

export default SingleChoice;
