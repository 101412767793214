const accessReducer = (accessState = {}, action) => {
  switch (action.type) {
    case 'REQUEST_USER_STATE':
      return {
        pgId: action.pgId,
      };
    case 'RECEIVE_USER_STATE':
      return {
        pgId: action.pgId,
        consent: action.consent,
        dataReady: action.dataReady,
      };
    default:
      return accessState;
  }
};

export default accessReducer;

export const getPgId = accessState => accessState.pgId;

export const getConsent = accessState => accessState.consent;

export const getDataReady = accessState => accessState.dataReady || false;

export const getSnpFileUploaded = accessState => accessState.dataReady || false;

// User is authorized to answer surveys as long as they have valid PG and Gencove IDs and have provided consent.
export const getAuthStatus = (accessState) => {
  const {
    pgId, consent,
  } = accessState;
  return pgId
    && /^[0-9A-F]{8}-[0-9A-F]{4}-4[0-9A-F]{3}-[89AB][0-9A-F]{3}-[0-9A-F]{12}$/i.test(pgId)
    && consent === true;
};

export const getSurveyScoreViewable = (accessState, surveyId) => {
  if (!getAuthStatus(accessState)) {
    return false;
  }
  if (surveyId === 'mbti' && !accessState.dataReady) {
    return false;
  }
  return true;
};
