import React, { Component } from 'react';
import { Redirect } from 'react-router';
import { connect } from 'react-redux';
import { Button } from 'react-bootstrap';

/* eslint-disable object-curly-newline */
import {
  fetchUserSummaryState, resetSurveyStatus, fetchUserNardiReportData, downloadFile, clearSurveyState,
} from '../../actions';
import {
  getLoadStatus, getLoadMessage, getSurveys, getNextSurvey, getSurvey,
  getFinalScore, getDataReady, getSurveyScoreViewable, getConsent,
} from '../../reducers';
/* eslint-enable */

import Page from '../../components/Page';
import LoadedDash from './LoadedDash';
import LoadingDash from './LoadingDash';
import LoadingFailedDash from './LoadingFailedDash';
import IdEntryDash from './IdEntryDash';

import './style.css';

/* eslint-disable react/prefer-stateless-function, react/prop-types, no-console, react/no-multi-comp */
class RawSurveyPage extends Component {
  constructor(props) {
    super(props);
    this.resetSurveys = this.resetSurveys.bind(this);
  }
  componentDidMount() {
    if (this.props.pgId === this.props.match.params.pgId) {
      // We have an ID on the browser URL... could be a new ID.
      this.props.clearSurveyState();
    }
    if (this.props.pgId) {
      this.props.fetchUserSummaryState(this.props.pgId);
    }
  }
  resetSurveys() {
    this.props.resetSurveyStatus(this.props.pgId);
  }
  allDone() {
    const { survey } = this.props;
    if (survey.length === 3) { // All three surveys are present
      for (let i = 0; i < survey.length; i += 1) {
        if (survey[i].status !== 'completed') {
          return false; // At least one is incomplete
        }
      }
      return true;
    }
    return false; // Don't have all three yet
  }
  render() {
    // This page requires a pgId to load. If not supplied, display a form to allow the user to enter it manually.
    let dash;
    let pageTitle;
    let pageDescription;

    if (!this.props.pgId || this.props.loadMessage.slice(-3) === '404') {
      dash = <IdEntryDash />;
      pageTitle = 'Please enter your study ID';
      pageDescription = 'This page allows users access to their participant dashboard via their unique ID.';
    } else {
      switch (this.props.loadStatus) {
        case 'loaded':
          dash = <LoadedDash {...this.props} />;
          break;
        case 'loading':
          dash = <LoadingDash {...this.props} />;
          break;
        case 'save-failed':
        case 'failed':
          dash = <LoadingFailedDash message={this.props.loadMessage} />;
          break;
        case 'saving':
        case 'saved': // We are returning to the dashboard having just saved a survey...
        case '': // Default -- meaning we are not loading yet
          dash = <div />; // Return an empty div for the split second it takes to update state locally after dispatch
          break;
        default:
          return <Redirect to="/" />; // We don't have a pgID in the URL or in local storage. Time to bail...
      }
    }
    return (
      <Page
        title={pageTitle || 'Participant Dashboard'}
        description={pageDescription || 'Survey and result presentation'}
      >
        <h1 className="my-5 text-center">Participant Dashboard</h1>
        {dash}
        {process.env.REACT_APP_RESET === '1' &&
          <Button bsStyle="info"onClick={this.resetSurveys}>
            Reset Surveys
          </Button>
        }
      </Page>
    );
  }
}

const mapStateToProps = (state, ownProps) => ({
  // Allow the URL-supplied id to overwrite what we have locally
  pgId: ownProps.match.params.pgId || state.user.access.pgId,
  consent: getConsent(state),
  dataReady: getDataReady(state),

  loadStatus: getLoadStatus(state),
  loadMessage: getLoadMessage(state),

  survey: getSurveys(state),
  nextSurvey: getNextSurvey(state),

  selfAssessment: getSurvey(state, 'selfAssessment'),
  selfScoreViewable: getSurveyScoreViewable(state, 'selfAssessment'),

  nardi: getSurvey(state, 'nardi'),
  nardiScoreViewable: getSurveyScoreViewable(state, 'nardi'),

  // mbti: getSurvey(state, 'mbti'),
  // mbtiScoreViewable: getSurveyScoreViewable(state, 'mbti'),

  typeFinder: getSurvey(state, 'typeFinder'),
  typeFinderScoreViewable: getSurveyScoreViewable(state, 'typeFinder'),

  finalScore: getFinalScore(state),
});

const SurveyPage = connect(
  mapStateToProps,
  {
    fetchUserSummaryState, resetSurveyStatus, fetchUserNardiReportData, downloadFile, clearSurveyState,
  },
)(RawSurveyPage);

export default SurveyPage;
