// Disable target warning because we already patch the window.open function to ensure safety
/* eslint-disable react/jsx-no-target-blank */
import React from 'react';
import { Grid, Row, Col, Image } from 'react-bootstrap';
import { Link } from 'react-router-dom';

import Page from '../components/Page';

import dc from '../images/dc.jpg';

const AboutPage = () => (
  <Page
    title="Who are we?"
    description="Description of the person behind this study and why she cares about personality type"
  >
    <Grid>
      <Row componentClass="section" className="au-row">
        <Col xs={12} sm={4} className="au-col">
          <Image src={dc} responsive />
        </Col>
        <Col xs={12} sm={8} className="au-col">
          <h3 className="mt-3">
            Dr. Denise Cook <small>Ph.D., M.Sc.</small><br />
            Research Scholar{' '}
            <span style={{ fontWeight: '300', marginLeft: '5px' }}>Ronin Institute</span><br />
            Founder and Owner{' '}
            <span style={{ fontWeight: '300', marginLeft: '5px' }}><Link to="/">Personality Genie</Link></span>
          </h3>
          <a href="https://www.facebook.com/personalitygenie/" target="_blank">
            <i className="fa fa-facebook-square fa-2x" />
          </a>
          <a href="https://twitter.com/personalitygeni" target="_blank">
            <i className="fa fa-twitter-square fa-2x" style={{ marginLeft: '8px' }} />
          </a>
          <a href="https://www.linkedin.com/in/dencook" target="_blank">
            <i className="fa fa-linkedin-square fa-2x" style={{ marginLeft: '8px' }} />
          </a>
        </Col>
      </Row>
      <Row componentClass="section">
        <Col sm={12}>
          <p>
            I graduated from McGill University with a PhD in Neuroscience in 2012 but with no desire to continue down
            the traditional academic path. After a long period of introspection and reading, especially in the fields of
            personality and social psychology, I came to the conclusion that although I &ldquo;knew myself&rdquo;
            better, I still had no idea what I wanted to do for the rest of my life. And I think a lot of us are
            wandering out there thinking the same thing.
          </p>

          <p>
            What I do know about myself:
          </p>

          <ul>
            <li>
              I love new challenges and steep learning curves.
            </li>
            <li>
              I love being independent.
            </li>
            <li>
              I love asking questions about nature and discovering the answers, either through experiment or reading.
              My current obsession is the genetic basis of individual differences (hence this study).
            </li>
            <li>
              I love sharing this knowledge with others that share similar interests.
            </li>
            <li>
              I self-identify as an INTP on the Myers-Briggs Type Indicator.
            </li>
          </ul>

          <p>
            Based on all the above, I originally planned to launch an app that would report people&#39;s personality
            traits based on their DNA. However, after months of working on a prototype and reading the relevant
            literature I came to the conclusion that none of the variants found so far could predict a person’s
            personality traits better than a horoscope can. So I decided to change direction and instead lead my own
            research project, gathering data from individuals with genotyping data in order to study the genetics of
            personality type.
          </p>

          <p>
            Why use Personality Type and not the more traditional Big 5 Personality Traits?
          </p>

          <ul>
            <li>
              Did I mention I like to be different and buck trends?
            </li>
            <li>
              The theory behind personality type just “makes sense” with my understanding of neuroscience, psychology,
              evolution, genetics, anthropology, ecology, ethology, decision-making research, etc. It would take a lot
              for me to explain my intuition to you, so you’ll just have to take this on faith, or contact me for more
              details if you are interested (warning: you might get back links to lots and lots of disparate papers from
              lots of different fields that I believe are all connected).
            </li>
          </ul>

          <h3>Personality Type and Me</h3>

          <p>
            The most powerful thing about learning about personality type for me was finally being able to feel at peace
            with myself, my preferences and all my quirks. You see, while I knew what makes me, ME, a lot of times
            people (and more broadly, “society”) made me feel that what I thought were my strengths were actually
            hindrances to “fitting in” and being successful—especially as a woman who prefers a good scientific paper or
            debate over pretty much anything else that women are supposed to enjoy. I often felt that being me wasn’t
            good enough. So in a way, I was relieved to discover that there were other people very much like me out
            there! I felt validated and understood for the first time, and as a result experienced a huge increase in
            self-esteem and confidence. I now know that I have all the tools necessary to define my own life goals and
            set my own path, even if it happens to be a path less trodden.
          </p>

          <p>
            My main objective with this research project is to bring scientific validity, reliability and credibility to
            the study of personality types and hopefully catalyze much needed academic research on this topic. It is my
            belief that a better understanding of personality would help solve many scientific enigmas, as well as
            helping people make better life decisions.
          </p>

        </Col>
      </Row>
    </Grid>
  </Page>

);

export default AboutPage;
