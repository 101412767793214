import React from "react";
import { Switch, Route, useLocation } from "react-router-dom";
import { motion, AnimatePresence } from "framer-motion";

import Header from "./components/Header";
import Snackbar from "./components/Snackbar";
import Footer from "./components/Footer";
import ScrollToTop from "./components/ScrollToTop";
import ProtectedRoute from "./components/ProtectedRoute";

import IndexPage from "./pages/home";
import ConsentPage from "./pages/consent/consent";
import SurveyPage from "./pages/survey";
import SelfAssessmentPage from "./pages/self-assessment";
import SelfAssessmentRunner from "./pages/self-assessment/runner";
import NardiPage from "./pages/nardi";
import NardiPartOneRunner from "./pages/nardi/NardiPartOneRunner";
import NardiPartTwoRunner from "./pages/nardi/NardiPartTwoRunner";
import NardiPartThreeRunner from "./pages/nardi/NardiPartThreeRunner";
import NardiPartFourRunner from "./pages/nardi/NardiPartFourRunner";
import MbtiPage from "./pages/mbti";
import ThankYouPage from "./pages/thank-you";
import NotFoundPage from "./pages/notfound";
import AboutPage from "./pages/about";
import TermsAndConditionsPage from "./pages/tc";
import PrivacyPage from "./pages/privacy";
import MbtiUploadPage from "./pages/mbti-upload";
import NardiReportPage from "./pages/nardi/report";
import TestHost from "./pages/test-host";
import TestTypeFinder from "./pages/test-typefinder";
import ResultPage from "./pages/result";
import ReadyCSV from "./pages/ready-csv";
import HowItWorksPage from "./pages/how-it-works";

const AppRouter = () => {
  const location = useLocation();

  return (
    <ScrollToTop>
      <AnimatePresence exitBeforeEnter>
        <div className="site">
          <Header />
          <Switch location={location} key={location.pathname}>
            <Route path="/survey/:pgId?" component={SurveyPage} />
            <Route path="/consent" component={ConsentPage} />
            <Route path="/how-it-works" component={HowItWorksPage} />
            <Route path="/test" component={TestHost} />
            <Route path="/thank-you" component={ThankYouPage} />
            <Route path="/terms" component={TermsAndConditionsPage} />
            <Route path="/privacy" component={PrivacyPage} />
            <Route path="/about" component={AboutPage} />
            <Route path="/mbti-load" component={MbtiUploadPage} />
            <Route path="/nardi-report" component={NardiReportPage} />
            <Route path="/return" component={ResultPage} />
            <ProtectedRoute path="/typefinder" component={TestTypeFinder} />
            <ProtectedRoute
              path="/self-assessment/survey"
              component={SelfAssessmentRunner}
            />
            <ProtectedRoute
              path="/self-assessment"
              component={SelfAssessmentPage}
            />
            <ProtectedRoute
              path="/nardi/part-one"
              component={NardiPartOneRunner}
            />
            <ProtectedRoute
              path="/nardi/part-two"
              component={NardiPartTwoRunner}
            />
            <ProtectedRoute
              path="/nardi/part-three"
              component={NardiPartThreeRunner}
            />
            <ProtectedRoute
              path="/nardi/part-four"
              component={NardiPartFourRunner}
            />
            <ProtectedRoute path="/nardi" component={NardiPage} />
            <ProtectedRoute path="/mbti" component={MbtiPage} />
            <Route path="/ready-csv" component={ReadyCSV} />
            <Route path="/" exact component={IndexPage} />
            <Route component={NotFoundPage} />
          </Switch>
          <Footer />
          <Snackbar />
        </div>
      </AnimatePresence>
    </ScrollToTop>
  );
};

export default AppRouter;
