import { Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router';

class ScrollToTop extends Component {
  shouldComponentUpdate(newProps) {
    return this.props.location !== newProps.location;
  }
  componentDidUpdate(prevProps) {
    if ((this.props.location !== prevProps.location) && (this.props.location.hash.length === 0)) {
      window.scrollTo(0, 0);
    }
  }

  render() {
    return this.props.children;
  }
}

/* eslint-disable react/forbid-prop-types */
ScrollToTop.propTypes = {
  location: PropTypes.any.isRequired,
  children: PropTypes.any.isRequired,
};
/* eslint-enable */

export default withRouter(ScrollToTop);
