import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Grid, Row, Col, Well, ListGroup, Image, Button } from 'react-bootstrap';

import SurveySegment from '../../components/SurveySegment';
import FinalScoreSegment from '../../components/FinalScoreSegment';

import lamp from '../../images/background.png';

/* eslint-disable jsx-a11y/accessible-emoji, react/no-danger */
class LoadedDash extends Component {
  constructor(props) {
    super(props);
    this.loadNardiReport = this.loadNardiReport.bind(this);
    this.getPrompt = this.getPrompt.bind(this);
  }
  getPrompt() {
    const { nextSurvey } = this.props;
    if (nextSurvey) {
      return (
        <p>
          <strong>You are ready to complete the surveys.</strong> Please start with the TypeFinder Personality Test
          survey and complete each survey in the list.
        </p>
      );
    }
    // if (dataReady) {
    //   if (finalScore) {
    //     return (
    //       <p>
    //         <strong>Thank you for your participation in this research study!</strong>{' '}
    //         Please check back every few months for progress reports and results!
    //       </p>
    //     );
    //   }
    //   return (
    //     <p>
    //       <strong>Thank you for your participation in this research study!</strong>{' '}
    //       Please check back next week for your final MBTI results and type reports.
    //     </p>
    //   );
    // }
    /* eslint-disable react/jsx-no-target-blank */
    // return (
    //   <p>
    //     Your final results will be available as soon as your upload to Gencove has been processed successfully. To
    //     check on the current status of your upload, please visit the{' '}
    //     <a href="https://user.gencove.com" target="_blank">Gencove site</a>.
    //   </p>
    // );
    /* eslint-enable */
    return null;
  }
  /* eslint-disable class-methods-use-this */
  loadNardiReport(event) {
    event.preventDefault();
    this.props.fetchUserNardiReportData(this.props.pgId);
  }
  // launchMbti() {
  //   window.open(
  //     'https://login.cpp.com',
  //     '_blank',
  //   );
  // }
  /* eslint-enable */
  render() {
    const {
      pgId, consent, nextSurvey, selfAssessment, nardi, typeFinder, typeFinderScoreViewable,
      // mbti, mbtiScoreViewable,
      nardiScoreViewable, selfScoreViewable, finalScore, downloadFile,
    } = this.props;

    return (
      <div id="dash">
        <Grid className="pg-dash loaded t-layout">
          <Row>
            <Col sm={12} md={8} mdOffset={2}>
              <Well>
                <h2>Your Study ID</h2>
                <p>
                  <strong>This is your study ID.</strong> Please record the ID and have it available when you visit the
                  Personality Genie site again in the event that it is needed.<br />
                </p>
                <p className="pgid">{pgId}</p>
              </Well>
            </Col>
          </Row>
          <Row>
            <Col sm={12} md={8} mdOffset={2}>
              {consent ?
                (
                  <Well className="full">
                    <h2>The Surveys</h2>
                    {this.getPrompt()}
                    <ListGroup>
                      {/* <SurveySegment
                        title="Myers-Briggs Type Indicator® (MBTI)"
                        nextSurvey={nextSurvey}
                        survey={mbti}
                        scoresViewable={mbtiScoreViewable}
                        topic="mbti"
                      >
                        {!mbti.typeCode &&
                          (
                            <div>
                              <small>
                                A pending score indicates that we have not yet received your results. Results are loaded
                                on Fridays. Check back after the coming Friday to verify that your scores have been
                                loaded.<br /><br />
                                <strong>
                                  Not seeing your score?
                                </strong><br />
                                If you have a pending score,{' '}
                                <strong>and it has been more than two weeks since you completed your
                                  MBTI survey,
                                </strong> please verify that you used the correct email address
                                when you initially registered. If not, please update your email address under account
                                management on the Elevate platform. The correct email address to use is:<br />
                                {`${pgId}@personalitygenie.com`}<br />
                              </small>
                              <ButtonToolbar className="my-3" style={{ display: 'flex', justifyContent: 'center' }}>
                                <Button
                                  bsStyle="primary"
                                  onClick={this.launchMbti}
                                >
                                  Update email address
                                </Button>
                              </ButtonToolbar>
                            </div>
                          )
                        }
                      </SurveySegment> */}
                      <SurveySegment
                        title="The TypeFinder Personality Test"
                        nextSurvey={nextSurvey}
                        survey={typeFinder}
                        scoresViewable={typeFinderScoreViewable}
                        topic="typefinder"
                      >
                        <small>
                          Your score reflects the results of completing the TypeFinder survey.
                        </small>
                      </SurveySegment>
                      <SurveySegment
                        title="Nardi Personality Indicator"
                        nextSurvey={nextSurvey}
                        survey={nardi}
                        scoresViewable={nardiScoreViewable}
                        topic="nardi"
                      >
                        <small>
                          The consistency score provides an index to determine the level of consistency between the
                          choices you made while answering the Nardi Personality indicator.
                        </small>
                        <div className="text-right">
                          <Button
                            bsStyle="link"
                            onClick={this.loadNardiReport}
                            style={{ padding: 0 }}
                          >
                            Load Report
                          </Button>
                        </div>
                      </SurveySegment>
                      <SurveySegment
                        title="Self Assessment"
                        nextSurvey={nextSurvey}
                        survey={selfAssessment}
                        scoresViewable={selfScoreViewable}
                        topic="self-assessment"
                      >
                        <small style={{ clear: 'both' }}>
                          The score shown here is the type you entered on the survey.
                        </small>
                      </SurveySegment>
                      {/* {dataReady && */}
                        <FinalScoreSegment
                          finalScore={finalScore}
                          nextSurvey={nextSurvey}
                          pgId={pgId}
                          downloadFile={downloadFile}
                        />
                      {/* } */}
                    </ListGroup>
                    <Image src={lamp} width="250" height="auto" className="mt-3" />
                  </Well>
                ) : (
                  <Well className="full">
                    <h3>Not quite ready...</h3>
                    <p className="lead">You still have a few items outstanding.</p>
                    <p>Please make sure you have completed the following steps before continuing:</p>
                    <ul>
                      <li>
                        You must be an active member of the Personality Genie study. To participate in
                        this study you must <a href="/consent">provide consent</a>.
                      </li>
                      <li>
                        If you deauthorized Personality Genie on Open Humans, you will need to complete
                        the <a href="/consent">consent process</a> again.
                      </li>
                    </ul>
                  </Well>
                )
              }
            </Col>
          </Row>
        </Grid>
      </div>
    );
  }
}

/* eslint-enable */

LoadedDash.propTypes = {
  pgId: PropTypes.string.isRequired,
  consent: PropTypes.bool.isRequired,
  dataReady: PropTypes.bool.isRequired,

  nextSurvey: PropTypes.string,

  // Self assessent survey

  selfAssessment: PropTypes.any.isRequired, // eslint-disable-line react/forbid-prop-types
  selfScoreViewable: PropTypes.bool.isRequired,

  // Nardi survey

  nardi: PropTypes.any.isRequired, // eslint-disable-line react/forbid-prop-types
  nardiScoreViewable: PropTypes.bool.isRequired,

  fetchUserNardiReportData: PropTypes.func.isRequired,

  // MBTI Survey

  // mbti: PropTypes.any.isRequired, // eslint-disable-line react/forbid-prop-types
  // mbtiScoreViewable: PropTypes.bool.isRequired,

  // TypeFinder Survey

  typeFinder: PropTypes.any.isRequired, // eslint-disable-line react/forbid-prop-types
  typeFinderScoreViewable: PropTypes.bool.isRequired,

  // Final Score

  finalScore: PropTypes.string,
  downloadFile: PropTypes.func.isRequired,
};

LoadedDash.defaultProps = {
  finalScore: undefined,
  nextSurvey: undefined,
};

export default LoadedDash;
