import React, { Component } from 'react';

import "./typefinder.css";

class TypeFinderFrame extends Component {
  componentDidMount() {
    const script = document.createElement('script');
    script.src = 'https://d3gs66gcyc7ay8.cloudfront.net/typeFinderwidget.js';
    script.async = true;
    document.body.appendChild(script);
  }

  // Prevents React from monkeying with the inner structure, which will cause
  // errors to be displayed.
  shouldComponentUpdate() { return false; }

  render() {
    return (
      <div className="highlight">
        <div className="framewrapper">
          <div
            className="truefinder-inline-widget"
            data-url={
              'https://api.talentinsights.com/v1/TruityGetAssessment/'
              + 'dfWrjQcs9KvwXfBM5Iw8JWSosP123447Syd2wm1234476RfPg2A7I='
            }
          />
        </div>
      </div>
    );
    }
}

export default TypeFinderFrame;