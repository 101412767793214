import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Grid, Row, Col, Form, FormGroup, ControlLabel, FormControl, Button } from 'react-bootstrap';
import axios from 'axios';

import Notice from '../components/Notice';
import { setNotice, clearNotice } from '../actions';

const isJsonString = (str) => {
  try {
    JSON.parse(str)
    return true
  } catch (error) { }

  return false;
}

class ReadyCSV extends Component {
  constructor(props) {
    super(props);
    this.state = { value: '' };
  }

  componentDidMount () {
    const { clearNotice } = this.props;
    clearNotice('ready-csv')
  }

  handleSubmit = (event) => {
    event.preventDefault();
    const token = this.state.value;
    const { setNotice, clearNotice } = this.props;
    setNotice('ready-csv', 'Generating...')

    if (this.isValid() !== 'error') {
      console.log('submitting...');
      axios.get(`/api/ohready/${token}`, {
        responseType: 'arraybuffer',
      })
        .then((resp) => {
          console.log('response received');
          const url = window.URL.createObjectURL(new Blob([resp.data]));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', 'roster.csv'); //or any other extension
          document.body.appendChild(link);
          link.click();

          clearNotice('ready-csv')
        })
        .catch((err) => {
          const response = err.response ? new TextDecoder('utf-8').decode(err.response.data) : err

          setNotice('ready-csv', isJsonString(response) ? JSON.parse(response).error : response, 'danger')
        });
    }
  };

  handleChange = (event) => {
    this.setState({ value: event.target.value });
  };

  test = () => {

  }

  isValid = () => this.state.value === '' ? 'error' : 'success';

  render() {
    return (
      <Grid>
        <Row componentClass="section">
          <Col sm={12}>
            <Notice topic="ready-csv" />
            <Form onSubmit={this.handleSubmit}>
              <FormGroup
                controlId="mk"
                validationState={this.isValid()}
              >
                <ControlLabel>Master Key</ControlLabel>
                <FormControl
                  value={this.state.value}
                  onChange={this.handleChange}
                />
              </FormGroup>
              <Button
                bsStyle="primary"
                type="submit"
                disabled={this.isValid() !== 'success'}
              >
                Submit
              </Button>
            </Form>
          </Col>
        </Row>
        <Row>
          <Col>
            <Button bsStyle="secondary" onClick={this.test}>Test</Button>
          </Col>
        </Row>
      </Grid>
    );
  }
}

export default connect(null, { setNotice, clearNotice })(ReadyCSV);