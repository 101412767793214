import React from "react";
import { Link, Route, Switch } from "react-router-dom";
import { Grid, Navbar, Nav, NavItem, Button } from "react-bootstrap";
import { LinkContainer } from "react-router-bootstrap";

import "./style.css";

const navItem = (path, key, text) => (
  <LinkContainer to={path}>
    <NavItem key={key}>{text}</NavItem>
  </LinkContainer>
);

class Header extends React.Component {
  constructor(props) {
    super(props);
    this.handleScroll = this.handleScroll.bind(this);
    this.calcColor = this.calcColor.bind(this);
    this.state = { alpha: 0, isLogged: false };
  }

  componentDidMount() {
    window.addEventListener("scroll", this.handleScroll);
    window.addEventListener("resize", this.handleScroll);
    this.checkLogged();
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.handleScroll);
    window.removeEventListener("resize", this.handleScroll);
  }

  checkLogged() {
    let pgId;
    try {
      pgId = JSON.parse(localStorage.getItem("state")).user.access.pgId;
    } catch {}
    this.setState({ isLogged: !!pgId });
  }

  handleScroll() {
    const w = window.innerWidth;
    const offset = 50;
    const min = 0.0;
    const range = 1 - min;
    const scrollTop = window.pageYOffset || document.documentElement.scrollTop;

    // Account for the width... if the navbar has collapsed, make it opaque
    if (w < 768 || scrollTop > offset) {
      this.setState({ alpha: 1 });
    } else {
      this.setState({ alpha: min + (range * scrollTop) / offset });
    }
  }

  calcColor() {
    const alpha = window.innerWidth < 768 ? 1 : this.state.alpha;
    return {
      backgroundColor: `rgba(256, 256, 256, ${alpha})`,
    };
  }

  render() {
    return (
      <Navbar
        fixedTop
        collapseOnSelect
        fluid
        className={this.state.alpha === 1 ? "scrolled" : undefined}
        style={this.calcColor()}
      >
        <Grid>
          <Navbar.Header>
            <Navbar.Brand>
              <Link to="/">Personality Genie</Link>
            </Navbar.Brand>
            <Navbar.Toggle />
          </Navbar.Header>
          <Navbar.Collapse>
            <Nav
              pullRight
              style={{
                display: "flex",
                alignItems: "baseline",
              }}
            >
              <Switch>
                <Route
                  path="/consent"
                  component={() => {
                    return (
                      <>
                        <LinkContainer
                          to="/how-it-works"
                          className="having-trouble"
                        >
                          <NavItem>
                            <span>🤔</span> I'm having trouble
                          </NavItem>
                        </LinkContainer>
                      </>
                    );
                  }}
                />
                <Route
                  path="/how-it-works"
                  component={() => {
                    return (
                      <>
                        <LinkContainer to="/consent">
                          <NavItem>Return to consent ➔</NavItem>
                        </LinkContainer>
                      </>
                    );
                  }}
                />
                <Route
                  path="/"
                  exact
                  component={() => {
                    return (
                      <>
                        <LinkContainer to={"/#howitworks"}>
                          <NavItem>{"How it works"}</NavItem>
                        </LinkContainer>
                        {this.state.isLogged ? (
                          <LinkContainer
                            to={"/survey"}
                            style={{
                              background: "white",
                              color: "#ac7c3c",
                              padding: "5px 20px",
                              borderRadius: "8px",
                              border: "3px solid",
                              boxShadow: "none",
                            }}
                          >
                            <Button bsStyle="primary" bsSize="small">
                              Go to Dashboard
                            </Button>
                          </LinkContainer>
                        ) : (
                          <LinkContainer to={"/survey"}>
                            <NavItem>{"Dashboard"}</NavItem>
                          </LinkContainer>
                        )}
                      </>
                    );
                  }}
                />
                <Route
                  path="/survey"
                  component={() => {
                    return <></>;
                  }}
                />
                <Route
                  path="*"
                  component={() => {
                    return (
                      <>
                        {this.state.isLogged ? (
                          <LinkContainer to={"/survey"}>
                            <NavItem>{"Go to Dashboard"}</NavItem>
                          </LinkContainer>
                        ) : null}
                      </>
                    );
                  }}
                />
              </Switch>
            </Nav>
          </Navbar.Collapse>
        </Grid>
      </Navbar>
    );
  }
}

export default Header;
