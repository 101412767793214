import React from 'react';
import { ButtonToolbar, Button, Grid, Row, Col, Well, Radio, Image } from 'react-bootstrap';
import { LinkContainer } from 'react-router-bootstrap';

import Page from '../../components/Page';

import logo from '../../images/logos/RadianceHouseLogo-360dpi.png';
import './style.css';

const NardiPage = () => (
  <Page
    title="Nardi Personality Indicator"
    description="A self-discovery questionnaire that helps determine your best-fit type, created by Dr. Dario Nardi."
  >
    <Grid>
      <Row componentClass="section">
        <Col sm={12}>
          <Image responsive src={logo} style={{ marginLeft: 'auto', marginRight: 'auto', height: '207px' }} />
          <h1 className="text-center mb-4">
            Nardi Personality Indicator<br />
            <small>Presented by Radiance House&mdash;Dario Nardi</small>
          </h1>

          <h2>Welcome!</h2>
          <p>
            This self-discovery experience is divided into three parts. Completing the assessment takes
            about 12 minutes. Ideally, quiet any distractions around you. If you are familiar with other
            personality assessments, please set aside what you know about those for now.
          </p>
          <h2>
            Part I - Your Preferences
          </h2>
          <p>
            Please read and answer carefully each of the twenty-five questions below. Many questions ask
            what you prefer, like this:
          </p>
          <Well bsSize="large">
            <h4>Which hand do you prefer to use?</h4>
            <div className="survey-options">
              <Radio
                key={1}
                name="answer"
              >
                Right hand
              </Radio>
              <Radio
                key={2}
                name="answer"
              >
                Left hand
              </Radio>
            </div>
          </Well>
          <p>
            Focus on what tends to be true, what is typical and most comfortable, rather than what you
            wish. When torn between options, trust your initial gut reaction or what is true in most
            situations.
          </p>
          <ButtonToolbar style={{ display: 'flex', justifyContent: 'center' }}>
            <LinkContainer to="/nardi/part-one">
              <Button bsStyle="success">I am ready</Button>
            </LinkContainer>
          </ButtonToolbar>
        </Col>
      </Row>
    </Grid>
  </Page>
);

export default NardiPage;
