import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { FormGroup, Checkbox } from 'react-bootstrap';
import { connect } from 'react-redux';

import { getSurveyAnswer } from '../../reducers';

import { logAnswer } from '../../actions';
import FieldGroup from '../FieldGroup';
import { isValidMulti } from './validators';

class RawMultipleChoice extends Component {
  constructor(props) {
    super(props);
    const { question, surveyId } = props;
    if (!question) {
      throw Error('Must specify a question');
    }
    if (!surveyId) {
      throw Error('Must specify a surveyId');
    }
    this.handleChange = this.handleChange.bind(this);
    this.getValidationState = this.getValidationState.bind(this);
    this.getExtValue = this.getExtValue.bind(this);
    this.isChecked = this.isChecked.bind(this);
    this.getExtValue = this.getExtValue.bind(this);
  }

  componentDidMount() {
    const { question, answer } = this.props;
    this.validate(question, answer.value);
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.answer !== nextProps.answer) {
      const { question, answer } = nextProps;
      this.validate(question, answer.value);
    }
  }

  getValidationState() {
    const { question, answer } = this.props;
    return isValidMulti(question, answer.value) ? 'success' : 'error';
  }

  getExtValue(key) {
    const val = this.props.answer.value.find(o => o.value === key);
    return val ? val.extValue : '';
  }

  handleChange(event) {
    const { target } = event;
    const { value, id } = target;
    const { surveyId, question } = this.props;
    const { checked } = target;

    if (id.endsWith('-val')) {
      // We are looking at a change to the extended value of a field
      // Retrieve the associated key that we stored in the id attribute
      const key = id.split('-')[0];
      this.props.logAnswer(surveyId, 'multi', question.id, key, value);
    } else {
      this.props.logAnswer(surveyId, 'multi', question.id, id, undefined, !checked);
    }
  }

  validate(question, value) {
    const { valid } = this.props;
    if (valid) {
      valid(isValidMulti(question, value));
    }
  }

  isChecked(v) {
    const { answer: { value } } = this.props;

    if (value) {
      for (let i = 0; i < value.length; i += 1) {
        const item = value[i];
        if (item === v || item.value === v) {
          return true;
        }
      }
    }
    return false;
  }

  render() {
    const { question } = this.props;
    // const { id, text } = question;
    return (
      <FormGroup
        controlId={question.id}
        validationState={this.getValidationState()}
      >
        {
          question.options.map(({ key, prompt, extValue }) => (
            <Checkbox
              key={key}
              name="answer"
              id={key}
              checked={this.isChecked(key)}
              onChange={this.handleChange}
            >
              {prompt}
              {(extValue && this.isChecked(key)) &&
                <FieldGroup
                  id={`${key}-val`}
                  label="Please explain"
                  type="text"
                  name={`${key}-val`}
                  onChange={this.handleChange}
                  value={this.getExtValue(key)}
                />
              }
            </Checkbox>
          ))
        }
      </FormGroup>
    );
  }
}

RawMultipleChoice.propTypes = {
  answer: PropTypes.shape({
    id: PropTypes.string,
    value: PropTypes.arrayOf(PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.shape({
        value: PropTypes.string,
        extValue: PropTypes.string,
      }),
    ])),
  }),
  valid: PropTypes.func,
  surveyId: PropTypes.string.isRequired,
  logAnswer: PropTypes.func.isRequired,
  question: PropTypes.shape({
    id: PropTypes.string,
    text: PropTypes.string,
    options: PropTypes.arrayOf(PropTypes.shape({
      key: PropTypes.string,
      prompt: PropTypes.string,
      extValue: PropTypes.bool,
    })),
  }).isRequired,
};

RawMultipleChoice.defaultProps = {
  answer: { value: [] },
  valid: undefined,
};

const mapStateToProps = (state, ownProps) => ({
  answer: getSurveyAnswer(state, ownProps.surveyId, ownProps.question.id),
});

const MultipleChoice = connect(
  mapStateToProps,
  { logAnswer },
  // mergeProps,
)(RawMultipleChoice);

export default MultipleChoice;
