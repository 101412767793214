import React, { useState, useReducer, useEffect, useCallback } from 'react';
import { Row, Col, ButtonToolbar, Button, Image, Table, Grid } from 'react-bootstrap';
import { LinkContainer } from 'react-router-bootstrap';

import Page from '../../components/Page';
import logo from '../../images/logos/pg-logo-combined.svg';

import './style.css';

const { REACT_APP_OH_CLIENT_ID } = process.env;

const authLink = `https://www.openhumans.org/direct-sharing/projects/oauth2/authorize/?client_id=${REACT_APP_OH_CLIENT_ID}&response_type=code`

const ConsentPage = () => {
  const [waitingForAuth, setWaitingForAuth] = useState(false);
  const [_, setPgTick] = useState(new Date())

  useEffect(() => {
    const interval = setInterval(() => setPgTick(new Date()), 1000)
    return () => clearInterval(interval)
  }, [])

  const getPgId = useCallback(() => {
    let pgId = null;
    try { pgId = JSON.parse(localStorage.getItem('state')).user.access.pgId } catch { }
    return pgId
  }, [])

  const isLogged = getPgId();

  return <Page
    title="Consent Form"
    description={'Description of the research study and security measures taken to safeguard your '
      + 'genetic information.'}
  >
    <Grid>
      <Image responsive src={logo} style={{ marginLeft: 'auto', marginRight: 'auto' }} />
      <h1 className="text-center">Consent form</h1>
      <p className="lead text-center">Permission to Take Part in a Human Research Study</p>
      <Row>
        <Col md={8}>
          <Table responsive bordered={false} condensed>
            <tbody>
              <tr>
                <th>Study Title:</th>
                <td>Genetics of Personality Type</td>
              </tr>
              <tr>
                <th>Study #:</th>
                <td>001</td>
              </tr>
              <tr>
                <th>Investigator:</th>
                <td>
                  Denise Cook, PhD<br />
                          Personality Genie<br />
                          70 Bongard Ave., Nepean, ON K2E 7Z9
                        </td>
              </tr>
              <tr>
                <th>Telephone Number:</th>
                <td>(613) 809-0520</td>
              </tr>
              <tr>
                <th>After Office Hours:</th>
                <td>(613) 809-0520</td>
              </tr>
              <tr>
                <th>Approved:</th>
                <td>March 22, 2019</td>
              </tr>
            </tbody>
          </Table>
        </Col>
      </Row>

      <div>
        <strong>Why am I being asked to take part in this research study?</strong><br />
        <p>
          We are interested in studying how personality type corresponds to specific gene
          variants, in a study that requires a large number of participants. We are therefore
          looking for participants who already have genotyping data and are willing to share
          their data with us. You must be 18 years or older to participate.
                </p>
      </div>

      <div>
        <strong>What is genetic research?</strong><br />
        <p>
          Genetic research is research using human genetic material, genetic information, or
          genetic testing. Genetic testing is an analysis of human genes (or “DNA”) that detects
          genotypes, mutations, or chromosomal changes. This may involve the testing of a single
          gene or multiple genes, or the entire “genome” (all the genes of one individual). DNA is
          in your cells, and makes you different from everyone else. DNA controls things like the
          color of your hair or eyes.
                </p>
      </div>

      <div>
        <strong>Who can I talk to?</strong>
        <p>
          If you have questions, concerns, or complaints, or think the research has hurt you, please
          email the research team at denise.cook@ronininstitute.org.
                </p>
        <p>
          Quorum Review reviewed this study. Quorum Review is a group of people who review research
          studies to protect the rights and welfare of research participants. Review by Quorum Review
          does not mean that the study is without risks. If you have questions about your rights as a
          research participant, if you are not able to resolve your concerns with the investigator or
          research team, if you have a complaint, or if you have general questions about what it means to
          be in a research study, you can call Quorum Review or visit the Quorum Review website at
          www.quorumreview.com.
                </p>
        <blockquote>
          Quorum Review is located in Seattle, Washington.<br />
                  Office hours are 8:00 AM to 5:00 PM Pacific Time, Monday through Friday.<br />
                  Ask to speak with a Research Participant Liaison at 888-776-9115 (toll free).
                </blockquote>
      </div>

      <div>
        <strong>Why is this research being done?</strong>
        <p>
          Personality typing measures the ways in which humans differ in their preferences for perceiving,
          interpreting and making decisions about their environment. It sorts people into 16 personality
          types, each having unique strengths and weaknesses. These differences are thought by the
          people doing this study to be influenced by our genetic makeup.
                </p>
        <p>
          We are interested in locating the genomic regions that correspond to personality type, which
          could eventually help researchers locate the genes influencing personality. This research may
          help us better understand how specific genes give rise to particular behaviors. As a result of
          this study, we may be able to predict personality type from genotyping data, a result that could
          greatly impact personality research and other fields, but may present significant future ethical
          challenges.
                </p>
      </div>

      <div>
        <strong>How long will the research last?</strong>
        <p>
          Your participation in the online questionnaire portion of this research will last approximately 1
          hour. The analysis portion of this study is likely to take several years.
                </p>
      </div>

      <div>
        <strong>How many people will be studied?</strong>
        <p>
          We require about 200 people of each personality type to find meaningful results. Up to 6,000
          people in total might participate in the study.
                </p>
      </div>

      <div>
        <strong>What happens if I say “Yes, I want to be in this research”?</strong>
        <p>
          This research study will access your genotyping data via a not-for-profit platform called Open
          Humans (www.openhumans.org), that is empowering individuals to explore their personal data,
          as well as providing opportunities for sharing data with various research projects and
          applications.
                </p>
        <p>
          If you consent to this research study, a screen will appear asking you to log-in or create an
          account on the Open Humans platform. Once logged-in a screen will appear asking if you
          authorize the Genetics of Personality Type Research Study to gain access to your genotyping
          data. By pressing authorize project you grant this research study access to your genotyping
          data. If you have not already done so, this screen also allows you to upload your genotyping
          data to the Open Humans platform using one of three uploaders (23andMe, AncestryDNA,
          FTDNA). Support for other sources of genetic data could be added at a later point (i.e., exome
          sequencing, whole genome sequencing, etc).
                </p>
        <p>
          You will then be directed back to the study website to complete the TypeFinder Personality Test
          and the Nardi Personality Indicator, two questionnaires that will aid in determining your best-fit
          personality type. We expect most people to complete both questionnaires within 1 hour. You
          will then be asked to provide your own choice for best-fit type.
                </p>
        <p>
          You will be provided with feedback about your best-fit personality type and be invited to follow
          the progress of this study online, including any resulting publications. At the completion of the
          study, you will be asked if you want to post your personality type results to your social media
          profile(s) with a link to our study website. This is optional, however, and is not required for study
          participation.
                </p>
      </div>

      <div>
        <strong>Will it cost anything to be in this study?</strong>
        <p>
          While uploading and sharing your genotyping data with this research study is required to gain
          access to the personality questionnaires, we do not have the funds necessary at this time to
          reimburse the cost of the genotyping for participants who do not already have their data.
                </p>
        <p>
          Your usual health care benefits will not be altered due to your participation in this study.
                </p>
      </div>

      <div>
        <strong>What happens if I do not want to be in this research?</strong>
        <p>
          Participation is voluntary and it will not be held against you.
                </p>
      </div>

      <div>
        <strong>What happens if I say “Yes”, but I change my mind later?</strong>
        <p>
          You can leave the research at any time and it will not be held against you. Any of your data
          already included in analyses cannot be withdrawn or deleted, but your data will not be included
          in future analyses from the time your consent is withdrawn. If you decide to leave the research,
          please contact the investigator at denise.cook@ronininstitute.org.
                </p>
      </div>

      <div>
        <strong>What is my alternative to being in this study?</strong>
        <p>
          The genotyping kit and questionnaires used in this study are available outside the study. Your
          alternative is not to be in the study.
                </p>
      </div>

      <div>
        <strong>Is there any way being in this study could be bad for me?</strong>
        <p>
          There is little risk of discomfort from completing the questionnaires. There are, however,
          information risks to providing us with your genotyping data.
                </p>

        <p>
          Since genotyping data is uniquely identifiable, we will use current best-practices for securely
          dealing with genetic information. We will be accessing and making a copy of your genotyping
          data by interfacing with Open Humans’ database using their secure API (Application
          Programming Interface). Transmitted data will be encrypted using SSL (Secure Sockets Layer).
          Transmitted data will include a unique project member ID provided by Open Humans, but will
          not include your email address, username or any other identifiers collected by Open Humans.
          Your data will be stored and analyzed using cloud-based services provided to the study
          investigator and research team by DNANexus, a company that complies with various US and
          International regulations and standards surrounding the use of genomic data in research
          (www.dnanexus.com). We will not under any circumstances attempt to re-identify you using
          your genetic data.
                </p>
        <p>
          Despite our efforts, we cannot guarantee that your data will never be released in the event of a
          security breach. We estimate that the risk for such a breach is low but not zero. A breach of
          genomic data could, in the future, lead to re-identification and leak of traits linked to your genetic
          data. This information could be accessed by insurance companies, doctors and others. Since
          parts of your genome are shared amongst all family members, a data breach may impact the
          private information of your family as well. Your genetic information can potentially be used in
          ways that can cause you or your family distress, for example, by revealing that you (or a blood
          relative) carry a genetic disease.
                </p>
        <p>
          Be aware that if you post your personality type results to any of your social media accounts or
          profiles with a link to the study website, this will reveal your participation in our study and may
          impact the confidentiality of your data.
                </p>
        <p>
          If the investigator or research team learns any new information that might change your mind
          about continuing in the study, the investigator or research team will tell you about it.
                </p>
        <p>
          There is a small chance that researchers could find something that might be important to your
          health. If this happens, we may contact you to find out if you would like to learn more. If you
          are contacted with results, you should talk to your regular doctor about what the test results may
          mean to you.
                </p>
        <p>
          Ask the investigator or research team by emailing denise.cook@ronininstitute.org if you have
          questions about the risks that you read about in this consent form.
                </p>
      </div>

      <div>
        <strong>Will being in this study help me in any way?</strong>
        <p>
          While there are no direct benefits to you from participating in this research study, you may
          benefit by gaining knowledge about your personality type. Specifically you may gain a better
          understanding of your strengths and weaknesses, decision-making style and communication
          style.
                </p>
      </div>

      <div>
        <strong>Will I be paid for being in the study?</strong>
        <p>
          You will not be paid for participating in this research.
                </p>
      </div>

      <div>
        <strong>What happens to the information collected for the research?</strong>
        <p>
          Upon receiving results of your questionnaires, any possible identifiers will be deleted. You will
          be identified only by the unique project member ID provided by Open Humans. Raw data will
          be managed privately, accessed only by study staff and other authorized individuals.
                </p>
        <p>
          Be aware that your study records (which include your consent form and other information) will
          be shared as needed for the study. For example, Health Canada, the United States Food and
          Drug Administration (FDA), and Quorum Review may look at your study and medical records.
                </p>
        <p>
          As part of your participation in the study, you will receive an individual personality type from your
          questionnaire results. You might also receive individualized results of the study analysis of your
          questionnaires and genotyping data. The results may be put in your regular medical records.
                </p>
        <p>
          You should talk to the investigator about what the genetic test results may mean to you.
          Scientists today disagree about what some genetic test results mean. You should consider
          talking to a genetics counselor and your regular doctor about your DNA and how other factors
          affect your health as well.
                </p>
        <p>
          The results of the research study may be published, but your name will not be used.
                </p>
        <p>
          Once analyzed and published, your data will be stored indefinitely for auditing purposes.
                </p>
      </div>

      <div>
        <strong>Can I be removed from the research without giving my OK?</strong>
        <p>
          The person in charge of the research study can remove you without your approval. Possible
          reasons for removal include inability to determine best-fit type, problems with your raw
          genotyping data and other technical issues.
                </p>
      </div>

      <div>
        <strong>What else do I need to know?</strong>
        <p>
          The research in this study may lead to new products, research tools, or inventions that have
          financial value. Participants will not be able to share in the profits from future commercialization
          of products developed from this study.
                </p>
      </div>

      <div>
        <strong>How will my information be used and shared for this study?</strong>
        <p>
          This section explains how your information will be used and shared if you agree to be in this
          study. The study investigator and research team will collect, use, and share information about
          you in order to conduct the research described in this form. This information will include:
                </p>
        <ul>
          <li>your personality test results</li>
          <li>your genotyping data that the investigator and research team receive from Open Humans</li>
          <li>
            a unique project member ID provided to the investigator and research team by Open Humans<br />
            <em>
              Your genotyping data received from Open Humans will be linked to you individually
              only by this unique identifier. Your genotyping data received by the research team
              will not be labeled with your name or other directly identifying information.
                    </em>
          </li>
        </ul>
        <p>
          Health Canada; the FDA; other government agencies in Canada, the U.S., and other countries;
          and Quorum Review may also use your information to review the quality and safety of the
          research.
                </p>
        <p>
          You can withdraw your permission to use and share your information at any time by giving
          reasonable notice to the study investigator. If you withdraw your permission, you will not be
          able to continue in this study. Please note that if you also want to remove your data on file with
          Open Humans then you will need to contact Open Humans separately. Even if you take away
          your permission, the study investigator and research team will still be able to use and share
          your information that they have already collected.
                </p>
      </div>

      <div>
        <h4>Electronic Consent:</h4>
        <p>
          Please select your choice below. You may print a copy of this consent form for your records.
                </p>
        <p className="lead">
          By clicking on the “Agree” button below, I indicate that:
                </p>
        <ul>
          <li>I have read the above information.</li>
          <li>
            I have been able to ask questions about this study, and my questions have been
            answered.
                  </li>
          <li>I voluntarily agree to participate.</li>
          <li>I am 18 years of age or older.</li>
          <li>I agree to allow the collection, use, and sharing of my information as described above.</li>
        </ul>
        <p>
          <em>By clicking on the “Agree” agree button below, I do not give up any of my legal rights.</em>
        </p>
      </div>
      <div style={{
        position: 'sticky',
        bottom: '0px',
        zIndex: '999',
        background: 'white',
        padding: '1em 0',
        display: 'flex',
        justifyContent: 'space-between'
      }}>
        <Button
          id="print-button"
          style={{
            textTransform: "none",
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            flexShrink: 1
          }} onClick={window.print}>
          <svg width="1em" height="1em" viewBox="0 0 16 16" className="bi bi-printer" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
            <path d="M11 2H5a1 1 0 0 0-1 1v2H3V3a2 2 0 0 1 2-2h6a2 2 0 0 1 2 2v2h-1V3a1 1 0 0 0-1-1zm3 4H2a1 1 0 0 0-1 1v3a1 1 0 0 0 1 1h1v1H2a2 2 0 0 1-2-2V7a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v3a2 2 0 0 1-2 2h-1v-1h1a1 1 0 0 0 1-1V7a1 1 0 0 0-1-1z" />
            <path fillRule="evenodd" d="M11 9H5a1 1 0 0 0-1 1v3a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1v-3a1 1 0 0 0-1-1zM5 8a2 2 0 0 0-2 2v3a2 2 0 0 0 2 2h6a2 2 0 0 0 2-2v-3a2 2 0 0 0-2-2H5z" />
            <path d="M3 7.5a.5.5 0 1 1-1 0 .5.5 0 0 1 1 0z" />
          </svg>{' Print '}
        </Button>

        {waitingForAuth
          ? (isLogged
            ? <a href="/survey" className="btn btn-link">Continue to Dashboard ➔</a>
            : <div style={{
              display: 'flex',
              alignItems: 'center'
            }}>
              <Button href={authLink}
                bsStyle="link"
                style={{ textTransform: 'none' }}
                href={authLink}
                target="_blank">
                Waiting for OpenHumans Auth
              </Button>
              <span className="loader" />
            </div>)
          : null}

        {!waitingForAuth
          ? (isLogged
            ? <a href="/survey" className="btn btn-link">Already Logged In ➔</a>
            : <ButtonToolbar style={{ flexShrink: 0 }}>
              <LinkContainer to="/">
                <Button bsStyle="link">Disagree</Button>
              </LinkContainer>
              <Button
                variant="link"
                bsStyle="success"
                style={{ textTransform: 'none' }}
                href={authLink}
                target="_blank"
                onClick={() => {
                  setWaitingForAuth(true)
                }}
              >
                Agree & Authorize Project
          </Button>
            </ButtonToolbar>)
          : null}
      </div>
    </Grid>
  </Page>
}

export default ConsentPage
