import React from 'react';
import { Grid, Row, Col, Image } from 'react-bootstrap';

import Page from '../components/Page';

import logo from '../images/logos/pg-logo-combined.svg';

const TermsAndConditionsPage = () => (
  <Page
    title="Terms and Conditions"
    description="Description of the terms you are agreeing to by using this web application."
  >
    <Grid>
      <Row componentClass="section">
        <Col sm={12}>
          <Image responsive src={logo} style={{ marginLeft: 'auto', marginRight: 'auto' }} />
          <h1 className="text-center mb-4">Terms and Conditions</h1>

          <p>
            These terms and conditions (&ldquo;Terms&rdquo;, &ldquo;Agreement&rdquo;) are an agreement between
            Personality Genie (&ldquo;Personality Genie&rdquo;, &ldquo;us&rdquo;, &ldquo;we&rdquo; or
            &ldquo;our&rdquo;) and you (&ldquo;User&rdquo;, &ldquo;you&rdquo; or &ldquo;your&rdquo;). This Agreement
            sets forth the general terms and conditions of your use of
            the <a href="https://www.personalitygenie.com">www.personalitygenie.com</a> website
            and any of its products or services (collectively, &ldquo;Web App&rdquo; or &ldquo;Services&rdquo;).
          </p>

          <h2>Age requirement</h2>
          <p>
            You must be at least 18 years of age to use this Web App. By using this Web app and by agreeing to this
            Agreement you warrant and represent that you are at least 18 years of age.
          </p>

          <h2>Links to other websites</h2>
          <p>
            Although this Web App may be linked to other websites, we are not, directly or indirectly, implying any
            approval, association, sponsorship, endorsement, or affiliation with any linked website, unless
            specifically stated herein. We are not responsible for examining or evaluating, and we do not warrant the
            offerings of, any businesses or individuals or the content of their websites. We do not assume any
            responsibility or liability for the actions, products, services and content of any other third parties.
            You should carefully review the legal statements and other conditions of use of any website which you
            access through a link from this Web App. Your linking to any other off-site websites is at your own risk.
          </p>

          <h2>Prohibited uses</h2>
          <p>
            In addition to other terms as set forth in the Agreement, you are prohibited from using the Web App or its
            Content: (a) for any unlawful purpose; (b) to solicit others to perform or participate in any unlawful
            acts; (c) to violate any international, federal, provincial or state regulations, rules, laws, or local
            ordinances; (d) to infringe upon or violate our intellectual property rights or the intellectual property
            rights of others; (e) to harass, abuse, insult, harm, defame, slander, disparage, intimidate, or
            discriminate based on gender, sexual orientation, religion, ethnicity, race, age, national origin, or
            disability; (f) to submit false or misleading information; (g) to upload or transmit viruses or any other
            type of malicious code that will or may be used in any way that will affect the functionality or operation
            of the Web App or of any related website, other websites, or the Internet; (h) to collect or track the
            personal information of others; (i) to spam, phish, pharm, pretext, spider, crawl, or scrape; (j) for any
            obscene or immoral purpose; or (k) to interfere with or circumvent the security features of the Web App or
            any related website, other websites, or the Internet. We reserve the right to terminate your use of the
            Web App or any related website for violating any of the prohibited uses.
          </p>

          <h2>Intellectual property rights</h2>
          <p>
            This Agreement does not transfer from Personality Genie to you any Personality Genie or third-party
            intellectual property, and all right, title, and interest in and to such property will remain (as between
            the parties) solely with Personality Genie. All trademarks, service marks, graphics and logos used in
            connection with our Web App or Services, are trademarks or registered trademarks of Personality Genie or
            Personality Genie licensors. Other trademarks, service marks, graphics and logos used in connection with
            our Web App or Services may be the trademarks of other third parties. Your use of our Web App and Services
            grants you no right or license to reproduce or otherwise use any Personality Genie or third-party
            trademarks.
          </p>

          <h2>Disclaimer of Warranties</h2>
          <p>
            We do not guarantee, represent or warrant that your use of our Web App will be uninterrupted, timely, secure
            or error-free. We do not warrant that the results that may be obtained from the use of the Web App will be
            accurate or reliable. You agree that from time to time we may remove the Web App for indefinite periods of
            time or cancel the app at any time, without notice to you. You expressly agree that your use of, or
            inability to use, the service is at your sole risk. The Web App and all products and services delivered to
            you through the app are (except as expressly stated by us) provided &#39;as is&#39; and &#39;as
            available&#39; for your use, without any representation, warranties or conditions of any kind, either
            express or implied, including all implied warranties or conditions of merchantability, merchantable quality,
            fitness for a particular purpose, durability, title, and non-infringement.
          </p>
          <p>
            We do not guarantee that the Web app is free of viruses or other elements that could damage your mobile
            device or computer, or that it is compatible with any hardware or software versions or applications,
            including any specific versions of your device, its operating system or browsers.
          </p>

          <h2>Limitation of liability</h2>
          <p>
            To the fullest extent permitted by applicable law, in no event will Personality Genie, its affiliates,
            officers, directors, employees, agents, suppliers or licensors be liable to any person for (a): any
            indirect, incidental, special, punitive, cover or consequential damages (including, without limitation,
            damages for lost profits, revenue, sales, goodwill, use or content, impact on business, business
            interruption, loss of anticipated savings, loss of business opportunity) however caused, under any theory
            of liability, including, without limitation, contract, tort, warranty, breach of statutory duty,
            negligence or otherwise, even if Personality Genie has been advised as to the possibility of such damages
            or could have foreseen such damages. To the maximum extent permitted by applicable law, the aggregate
            liability of Personality Genie and its affiliates, officers, employees, agents, suppliers and licensors,
            relating to the services will be limited to an amount greater of one dollar or any amounts actually paid
            in cash by you to Personality Genie for the prior one month period prior to the first event or occurrence
            giving rise to such liability. The limitations and exclusions also apply if this remedy does not fully
            compensate you for any losses or fails of its essential purpose.
          </p>

          <h2>Indemnification</h2>
          <p>
            You agree to indemnify and hold Personality Genie and its affiliates, directors, officers, employees, and
            agents harmless from and against any liabilities, losses, damages or costs, including reasonable
            attorneys&#39; fees, incurred in connection with or arising from any third-party allegations, claims,
            actions, disputes, or demands asserted against any of them as a result of or relating to your Content, your
            use of the Web App or Services or any willful misconduct on your part.
          </p>

          <h2>Conflict of Terms</h2>
          <p>
            In the event of any conflict between the terms of this Agreement and the Consent Form, the terms of the
            Consent Form will control.
          </p>

          <h2>Severability</h2>
          <p>
            All rights and restrictions contained in this Agreement may be exercised and shall be applicable and
            binding only to the extent that they do not violate any applicable laws and are intended to be limited to
            the extent necessary so that they will not render this Agreement illegal, invalid or unenforceable. If any
            provision or portion of any provision of this Agreement shall be held to be illegal, invalid or
            unenforceable by a court of competent jurisdiction, it is the intention of the parties that the remaining
            provisions or portions thereof shall constitute their agreement with respect to the subject matter hereof,
            and all such remaining provisions or portions thereof shall remain in full force and effect.
          </p>

          <h2>Dispute resolution</h2>
          <p>
            The formation, interpretation and performance of this Agreement and any disputes arising out of it shall
            be governed by the substantive and procedural laws of Ontario, Canada without regard to its rules on
            conflicts or choice of law and, to the extent applicable, the laws of Canada. The exclusive jurisdiction
            and venue for actions related to the subject matter hereof shall be the state and federal courts located
            in Ontario, Canada, and you hereby submit to the personal jurisdiction of such courts. You hereby waive
            any right to a jury trial in any proceeding arising out of or related to this Agreement. The United
            Nations Convention on Contracts for the International Sale of Goods does not apply to this Agreement.
          </p>

          <h2>Changes and amendments</h2>
          <p>
            We reserve the right to modify this Agreement or its policies relating to the Web App or Services at any
            time, effective upon posting of an updated version of this Agreement on the Website. When we do we will
            revise the updated date at the bottom of this page. Continued use of the Website after any such changes
            shall constitute your consent to such changes. Policy was created with WebsitePolicies.com
          </p>

          <h2>Acceptance of these terms</h2>
          <p>
            You acknowledge that you have read this Agreement and agree to all its terms and conditions. By using the
            Web App or its Services you agree to be bound by this Agreement. If you do not agree to abide by the terms
            of this Agreement, you are not authorized to use or access the Web App and its Services.
          </p>

          <h2>Contacting us</h2>
          <p>If you have any questions about this Agreement, please contact us.</p>
          <p style={{ fontStyle: 'italic' }}>This document was last updated on February 7, 2018</p>
        </Col>
      </Row>
    </Grid>
  </Page>
);

export default TermsAndConditionsPage;
