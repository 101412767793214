export const updateObject = (oldObject, newValues) => Object.assign({}, oldObject, newValues);

export const updateItemInArray = (array = [], match, update) => {
  if (array.find(item => match(item))) {
    return array
      .map((item) => {
        if (!match(item)) {
          return item;
        }
        const result = update(item);
        return result;
      });
  }
  const u = update(undefined); // Signal the construction of a brand new item...
  if (u) {
    return array.concat(u);
  }
  return array;
};

export const createReducer = (initialState, handlers) => (state = initialState, action) => {
  if (handlers.hasOwnProperty(action.type)) {
    return handlers[action.type](state, action);
  }
  return state;
};
