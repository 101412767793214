import { combineReducers } from 'redux';

import survey from './survey';
import access from './access';

const userReducer = combineReducers({
  survey,
  access,
});

export default userReducer;
