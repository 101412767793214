export const isValidSingle = (question, answer) => {
  if (!answer) {
    return false;
  }

  const validValues = question.options.map(o => o.key);
  const extValues = question.options.filter(o => o.extValue).map(o => o.key);

  const key = answer.value || answer;
  const { extValue } = answer;

  if (validValues.indexOf(key) === -1) {
    return false;
  }
  if (extValues.indexOf(key) !== -1 && !(extValue && extValue.length > 0)) {
    return false;
  }
  return true;
};

export const isValidMulti = (question, answer) => { // Receiving an array here...
  if (!answer || answer.length === 0) {
    return false;
  }

  const validValues = question.options.map(o => o.key);
  const extValues = question.options.filter(o => o.extValue).map(o => o.key);

  for (let i = 0; i < answer.length; i += 1) {
    const key = answer[i].value || answer[i];
    const { extValue } = answer[i];

    if (validValues.indexOf(key) === -1) {
      return false;
    }
    if (extValues.indexOf(key) !== -1 && !(extValue && extValue.length > 0)) {
      return false;
    }
  }
  return true;
};

export const isValidText = (question, answer) => {
  if (!answer || answer.length === 0) {
    return false;
  }
  if (question.values && question.values.indexOf(answer.toUpperCase()) === -1) {
    return false;
  }
  return true;
};
