import React from 'react';
import { Row, Grid } from 'react-bootstrap';

import Page from '../components/Page';

const HowItWorksPage = () => {
  return <Page
    title="How it works"
    description="Full description of website flow for those who have troubles with the consent flow"
  >
    <Grid>
      <Row componentClass="section">
        <div style={{
          height: '35rem',
          margin: '0 auto', width: '95%', minWidth: '100px'
        }}>
          <iframe style={{
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            border: 0
          }}
            src="https://www.youtube.com/embed/qEi3ck4XA0o"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowfullscreen />
        </div>
      </Row>
    </Grid>
  </Page>
}

export default HowItWorksPage;