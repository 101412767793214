import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { ListGroupItem } from 'react-bootstrap';
import { LinkContainer } from 'react-router-bootstrap';
import ReactGA from 'react-ga';

const surveySelectedEvent = (label) => ReactGA.event({
  category: 'User',
  action: 'Selected survey',
  label,
});

class LinkedListGroupItem extends Component {
  constructor(props) {
    super(props);
    this.checkClick = this.checkClick.bind(this);
  }

  checkClick(event) {
    if (this.lgi.props.disabled) {
      event.preventDefault(); // Stop propogating if disabled
    }
    console.log('logging survey selection', this.props.title);
    surveySelectedEvent(this.props.title);
}

  render() {
    const {
      to, bsStyle, disabled, header, children, active,
    } = this.props;
    if (to) {
      return (
        <LinkContainer to={to} onClick={this.checkClick}>
          <ListGroupItem
            bsStyle={bsStyle}
            disabled={disabled}
            ref={(el) => { this.lgi = el; }}
            header={header}
            active={active}
          >
            {children}
          </ListGroupItem>
        </LinkContainer>
      );
    }
    return (
      <ListGroupItem
        bsStyle={bsStyle}
        header={header}
      >
        {children}
      </ListGroupItem>
    );
  }
}

LinkedListGroupItem.propTypes = {
  to: PropTypes.string,
  disabled: PropTypes.bool,
  bsStyle: PropTypes.string,
  header: PropTypes.string,
  children: PropTypes.any.isRequired, // eslint-disable-line react/forbid-prop-types
  active: PropTypes.bool,
};

LinkedListGroupItem.defaultProps = {
  to: undefined,
  disabled: false,
  bsStyle: undefined,
  header: undefined,
  active: false,
};

export default LinkedListGroupItem;
