import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Grid, Row, Col, Image, Alert, ButtonToolbar, Button, FormGroup } from 'react-bootstrap';

import { getPgId, getSurvey } from '../reducers';
import { showNotificationWithTimeout, setSurvey, setSurveyStatus, updateTypeFinderResult } from '../actions';

import Page from '../components/Page';
import TypeFinderFrame from '../components/TypeFinderFrame';

import logo from '../images/logos/ti-truity-logo-smaller.png';
import stop from '../images/stop.svg';
import cpp2 from '../images/typefinder-form.png';

const COPY_PROMPT = 'Copy Study ID Email Address';
const COPIED_PROMPT = 'Copied';

class RawTypeFinderPage extends Component {
  constructor(props) {
    super(props);
    // this.launchTypeFinder = this.launchTypeFinder.bind(this);
    this.handleCompletion = this.handleCompletion.bind(this);
    this.copyId = this.copyId.bind(this);
    this.inputRef = React.createRef();
    this.state = { prompt: COPY_PROMPT };
  }

  handleCompletion(event) {
    event.preventDefault();
    const { pgId, updateTypeFinderResult } = this.props; // eslint-disable-line no-shadow
    updateTypeFinderResult(pgId);
  }

  copyId() {
    console.log('Attempting to copy to clipboard');

    const el = this.inputRef.current;

    if (navigator.userAgent.match(/ipad|ipod|iphone/i)) {
      el.contenteditable = true;
      el.readonly = false;

      const range = document.createRange();
      range.selectNodeContents(el);

      const selection = window.getSelection();
      selection.removeAllRanges();
      selection.addRange(range);
      el.setSelectionRange(0, 999999);

      el.contenteditable = false;
      el.readonly = true;
    } else {
      el.select();
    }

    if (!document.execCommand('copy')) {
      console.log('Copy to clipboard was not successful'); // keep
    } else {
      console.log('Copy succeeded');
      this.setState(({ prompt: COPIED_PROMPT }));
      setTimeout(() => {
        this.setState({ prompt: COPY_PROMPT });
      }, 2000);
    }
  }

  render() {
    const { prompt } = this.state;
    const STUDY_EMAIL = `${this.props.pgId}@personalitygenie.com`;
    return (
      <Page
        title="The TypeFinder Personality Test"
        description="Describes participant actions to access the TypeFinder Personality Test provided by Truity/Talent Insights."
      >
        <Grid>
          <Row componentClass="section">
            <Col sm={12} md={8} mdOffset={2}>
              <Image
                responsive
                src={logo}
                style={{
                  marginLeft: 'auto',
                  marginRight: 'auto',
                  marginBottom: '100px',
                  marginTop: '50px',
                  maxHeight: '207px'
                }}
              />
              <h1 className="text-center my-5">
                The TypeFinder® Personality Test
              </h1>

              <h2>Please follow the instructions below:</h2>

              <Alert className="my-4" bsStyle="warning">
                <ol>
                  <li className="text-muted  mb-2">
                    Copy and paste the following Study ID email address field into the TypeFinder
                    registration form as shown below.
                    <form>
                      <FormGroup className="mb-0">
                        <input
                          type="text"
                          className="pgid form-control"
                          value={STUDY_EMAIL}
                          ref={this.inputRef}
                          readOnly
                        />
                        <ButtonToolbar className="my-3" style={{ display: 'flex', justifyContent: 'center' }}>
                          <Button
                            bsStyle="primary"
                            className="text-center"
                            onClick={this.copyId}
                          >
                            {prompt}
                          </Button>
                        </ButtonToolbar>
                      </FormGroup>
                    </form>
                    <Image responsive src={cpp2} width="80%" style={{ margin: '25px auto' }} />
                  </li>
                  <li className="text-muted mb-2">
                    Enter any information in the Name field. This field is not used for the
                    study.
                  </li>
                  <li className="text-muted mb-2">Complete the TypeFinder survey.</li>
                  <li className="text-muted">
                    When you have completed AND downloaded/saved your TypeFinder results, please click completed
                    and continue with the other surveys. You will not be able to return to this page after you
                    hit completed.
                  </li>
                </ol>
              </Alert>
            </Col>
          </Row>
          <Row componentClass="section">
            <Col>
              <Alert bsStyle="warning" className="mt-5">
                <Image
                  responsive
                  src={stop}
                  style={{
                    marginRight: '20px',
                    height: '100px',
                    float: 'left',
                  }}
                />
                <h2>PLEASE NOTE</h2>
                <p>
                  After you complete the TypeFinder survey below and download your report,
                  {' '}
                  <strong>
                    you must return here and press the &ldquo;Submit Results&rdquo; button
                    to submit your results to Personality Genie.
                  </strong>
                </p>
                <p className="text-center">
                  Incomplete surveys, and surveys with incorrect study IDs will not be stored.
                </p>
                <ButtonToolbar className="mt-2" style={{ display: 'flex', justifyContent: 'center' }}>
                  <Button
                    bsStyle="primary"
                    className="my-4"
                    onClick={event => this.handleCompletion(event)}
                  >
                    Submit Results
                    </Button>
                </ButtonToolbar>
              </Alert>
              <p className="text-center">
                <i className="fa fa-arrow-up" ariaHidden="true" style={{ color: 'lightsalmon'}} />
                {' '}
                There may be a slight delay before the survey form appears below...
                {' '}
                <i className="fa fa-arrow-down" ariaHidden="true" style={{ color: 'lightsalmon'}} />
              </p>
              <TypeFinderFrame />
              {/* <div style={{ minWidth: '320px', height: '580px' }}>
                <div
                  className="truefinder-inline-widget"
                  data-url={
                    'https://api.talentinsights.com/v1/TruityGetAssessment/'
                    + 'dfWrjQcs9KvwXfBM5Iw8JWSosP123447Syd2wm1234476RfPg2A7I='
                  }
                />
              </div> */}
            </Col>
          </Row>
        </Grid>
      </Page>
    );
  }
}

RawTypeFinderPage.propTypes = {
  pgId: PropTypes.string.isRequired,
  setSurveyStatus: PropTypes.func.isRequired,
  updateTypeFinderResult: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  pgId: getPgId(state),
  survey: getSurvey(state, 'typeFinder'),
});

const TypeFinderPage = connect(
  mapStateToProps,
  {
    showNotificationWithTimeout, setSurvey, setSurveyStatus, updateTypeFinderResult,
  },
)(RawTypeFinderPage);

export default TypeFinderPage;
