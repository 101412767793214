import _ from 'underscore';

const notificationsReducer = (notificationState = {}, action) => {
  switch (action.type) {
    case 'SHOW_NOTIFICATION':
      return {
        ...notificationState,
        [action.id]: action.text,
      };
    case 'HIDE_NOTIFICATION':
      return _.omit(notificationState, action.id);
    case 'REMOVE_NOTIFICATIONS':
      return {};
    default:
      return notificationState;
  }
};

export default notificationsReducer;

// This is a dummy method in that it returns what it is passed. It does however follow the selector pattern
// for redux.
export const getNotifications = notificationState => notificationState;
