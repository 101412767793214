import React, { Component } from 'react';
import PropTypes from 'prop-types';
import queryString from 'query-string';
import axios from 'axios';

import { browserHistory } from '../history';
import { Grid, Row, Col } from 'react-bootstrap';

class ResultPage extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  componentDidMount() {
    const { code } = queryString.parse(this.props.location.search);

    if (code) {
      axios.post('/api/consent', { code })
        .then(
          res => {
            browserHistory.push(`/survey/${ res.data.pgId }`);
          },
          err => this.setState({ err }),
        );
    } else {
      console.log('no code', this.props.location.search);
    }
  }

  render() {
    if (this.state.err) {
      return (
        <div>
          <h1>An error occurred</h1>
          <a href="/consent">Back</a>
          <pre>{JSON.stringify(this.state, null, 2)}</pre>
        </div>
      );
    }
    return (
      <div id="dash">
        <Grid className="pg-dash loading">
          <Row componentClass="section">
            <Col sm={12}>
              <h1 className="text-center">Loading...</h1>
              <p className="text-center"><i className="fa fa-spinner fa-spin fa-3x fa-fw" /></p>
            </Col>
          </Row>
        </Grid>
      </div>
    );
  }
}

ResultPage.propTypes = {
  location: PropTypes.shape({
    search: PropTypes.string,
  }).isRequired,
};


export default ResultPage;
